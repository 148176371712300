import { Component, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'auth-welcome-description',
  templateUrl: './welcome-description.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class AuthWelcomeDescriptionComponent {
  constructor() { }
}
