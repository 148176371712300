import * as moment from 'moment';
import { Job } from "../interfaces";
import { DownTime } from '../interfaces/down-time';

export const getJobConflictsDowntime = (downtime: DownTime[], job: Job): DownTime[] => {
  const jobConflictsDowntime = downtime.filter(
    x =>
      (moment(x.startDate).utc().toDate() <
        moment(job.requestedStartTime).utc().toDate() &&
        moment(x.endDate).utc().toDate() >
        moment(job.requestedStartTime).utc().toDate()) ||
      (moment(x.startDate).utc().toDate() <
        moment(job.requestedEndTime).utc().toDate() &&
        moment(x.endDate).utc().toDate() >
        moment(job.requestedEndTime).utc().toDate()) ||
      (moment(x.startDate).utc().toDate() ===
        moment(job.requestedStartTime).utc().toDate() &&
        moment(x.endDate).utc().toDate() >=
        moment(job.requestedEndTime).utc().toDate()) ||
      (moment(x.startDate).utc().toDate() >=
        moment(job.requestedStartTime).utc().toDate() &&
        moment(x.endDate).utc().toDate() <=
        moment(job.requestedEndTime).utc().toDate())
  );

  return jobConflictsDowntime;
}
