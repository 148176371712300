import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  updateRequestJobOnSelection = new BehaviorSubject<any>(null);
  updateToolbarOnRequestJob = new BehaviorSubject<any>(null);
  getScrollTime = new BehaviorSubject<any>(null);
  constructor() {}

  autoUpdateRequestJob = (selectedData: any): void => {
    this.updateRequestJobOnSelection.next(selectedData);
  };

  setScrollTime = (time: any): void => {
    this.getScrollTime.next(time);
  };
}
