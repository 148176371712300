import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetTimeOffDialog } from 'app/core/interfaces';

@Component({
  selector: 'app-timeoff-assets-dialog',
  templateUrl: './timeoff-assets-dialog.component.html',
  styleUrls: ['./timeoff-assets-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class TimeOffAssetsEditDialogComponent {

  constructor(
    public matDialogRef: MatDialogRef<TimeOffAssetsEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssetTimeOffDialog,
  ) { }
}
