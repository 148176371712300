import { Component, Input } from '@angular/core';
import { JobComment } from 'app/core/interfaces';
import moment from 'moment';

@Component({
    selector: 'app-job-notes',
    templateUrl: './job-notes.component.html',
    styleUrls: ['./job-notes.component.scss']
})
export class JobNotesComponent {
    @Input() comments: JobComment[];

    formatDate = (date): string => moment(date).format('lll');
}
