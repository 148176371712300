import { RecurrenceStoreData } from 'app/core/interfaces';
import { BaseIdName } from 'app/core/interfaces/base';
import { Project } from '../../interfaces/project';
import { ToolbarSettings } from '../../interfaces/toolbar-settings';
import * as CommonActions from '../actions/common.actions';

export interface IState {
  filterScheduler: any;
  inProgress: boolean;
  isMobileView: boolean;
  pageTitle: string;
  project: Project;
  prevProjectId: string;
  spinnersCount: number;
  toolbar: ToolbarSettings;
  isNightShift: boolean;
  customizedUnloadingMethods: BaseIdName[];
  recurrenceData: RecurrenceStoreData;
}

const initialState: IState = {
  filterScheduler: null,
  inProgress: false,
  isMobileView: false,
  pageTitle: '',
  project: null,
  prevProjectId: '',
  spinnersCount: 0,
  toolbar: {
    pageHeading: false,
    showBackButton: false,
    showScanBackButton: false,
  } as ToolbarSettings,
  isNightShift: false,
  customizedUnloadingMethods: [],
  recurrenceData: null,
};

export const reducer = (
  state = initialState,
  action: CommonActions.CommonActions
): IState => {
  switch (action.type) {
    case CommonActions.CLEAR_SPINNER:
      return {
        ...state,
        spinnersCount: 0,
        inProgress: false,
      };

    case CommonActions.SET_PROJECT:
      return {
        ...state,
        project: {
          ...action.payload,
        },
      };

    case CommonActions.START_SPINNER:
      return {
        ...state,
        spinnersCount: state.spinnersCount + 1,
        inProgress: true,
      };

    case CommonActions.STOP_SPINNER:
      const spinnersCount = Math.max(0, state.spinnersCount - 1);
      return {
        ...state,
        spinnersCount,
        inProgress: spinnersCount > 0,
      };

    case CommonActions.SET_IS_MOBILE_VIEW:
      return {
        ...state,
        isMobileView: action.payload,
      };

      break;

    case CommonActions.SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.payload,
      };

    case CommonActions.SHOW_TOOLBAR:
      return {
        ...state,
        toolbar: { ...action.payload },
      };

    case CommonActions.HIDE_TOOLBAR:
      return {
        ...state,
        toolbar: { ...action.payload },
      };

    case CommonActions.FILTER_SCHEDULER:
      return {
        ...state,
        filterScheduler: { ...action.payload },
      };

    case CommonActions.SET_PREV_PROJECT_ID:
      return {
        ...state,
        prevProjectId: action.payload
      };

    case CommonActions.SET_IS_NIGHT_SHIFT:
      return {
        ...state,
        isNightShift: action.payload,
      };

    case CommonActions.SET_CUSTOMIZED_UNLOADING_METHODS:
      return {
        ...state,
        customizedUnloadingMethods: action.payload,
      };

    case CommonActions.SET_RECURRENCE_DATA:
      return {
        ...state,
        recurrenceData: action.payload,
      };

    default:
      return state;
  }
};
