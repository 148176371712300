import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MileStone, MilestonesViewModel } from 'app/core/interfaces';
import { Observable } from 'rxjs';
import { BaseODataService } from './base-odata.service';

@Injectable({
  providedIn: 'root',
})

export class MilestoneODataService extends BaseODataService<MileStone> {
  constructor(protected http: HttpClient) {
    super(http, 'milestones');
  }

  getAssetsWithArea(projectId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/get-assets-areas/${projectId}`);
  }

  addAssetsWithArea(body: MilestonesViewModel): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/add-assets-areas`, body);
  }
}
