import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { AssignedActivity, AssignedAreaAsset, AssignedPackages, AssignedUser, Contractor, IODataParams } from 'app/core/interfaces';
import { AuthService } from 'app/core/services/custom-services/auth.service';
import { GridOperationsService } from 'app/core/services/grid-operations.service';
import { ContractorsGridEditService } from 'app/core/services/grid/contractors-grid-edit.service';
import { AssetODataService } from 'app/core/services/odata/asset-odata.service';
import { BoqItemODataService } from 'app/core/services/odata/boqitem-odata.service';
import { JobTasksODataService } from 'app/core/services/odata/job-tasks-odata.service';
import { MilestoneODataService } from 'app/core/services/odata/milestone-odata.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectProject } from 'app/core/store/selectors/common.selectors';
import { getPackagesNames, showExistRecordSnackbar } from 'app/core/utils/utils';
import { Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-create-contractor-drawer',
  templateUrl: './create-contractor-drawer.component.html',
  styleUrls: ['./create-contractor-drawer.component.scss'],
})

export class CreateContractorDrawerComponent implements OnInit, OnDestroy {
  @Input() contractor: Contractor;
  @Output() isContractorDrawerOpened = new EventEmitter<boolean>();

  projectId: string;
  boqPackages: AssignedPackages[] = [];
  users: AssignedUser[] = [];
  areas: AssignedAreaAsset[] = [];
  assets: AssignedAreaAsset[] = [];
  activities: AssignedActivity[] = [];
  public editContractorForm: FormGroup;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _store: Store<AppState>,
    private _snackbar: MatSnackBar,
    private _authService: AuthService,
    private _milestoneService: MilestoneODataService,
    private _assetsService: AssetODataService,
    private _boqService: BoqItemODataService,
    private _activityService: JobTasksODataService,
    private _gridOperationsService: GridOperationsService,
    private _contractorEditService: ContractorsGridEditService,
  ) {
    this._store
      .select(selectProject)
      .pipe(filter((project) => !!project && !!project.id), takeUntil(this._unsubscribeAll))
      .subscribe((project) => {
        this.projectId = project.id;
        this.getUsers();
        this.getAreas();
        this.getAssets();
        this.getPackages();
        this.getActivities();
      });
  }

  ngOnInit(): void {
    this.editContractorForm = this.createFormGroup(this.contractor);
    this._gridOperationsService.checkDuplicate
      .pipe(filter(x => x === true), takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        showExistRecordSnackbar(this._snackbar);
      });
  }

  createFormGroup(item: any): FormGroup {
    return new FormGroup({
      name: new FormControl(item?.name || '', Validators.required),
      color: new FormControl(item?.color || '', Validators.required),
      users: new FormControl(item?.users || []),
      areas: new FormControl(item?.areas || []),
      assets: new FormControl(item?.assets || []),
      packages: new FormControl(item?.packages || []),
      activities: new FormControl(item?.activities || []),
    });
  }

  getUsers(): void {
    this._authService.getUsers(this.projectId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        this.users = response.map(x => ({
          id: x.id,
          name: x.name,
          principalName: x.principalName,
        }));
      });
  }

  getAreas(): void {
    const params: IODataParams = {
      filter: `(projectId eq '${this.projectId}' and isDeleted ne true)`,
      selects: ['id', 'name'],
      orderBy: ['name asc'],
    };

    this._milestoneService.get(params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.areas = res.value.map(x => ({
          id: x.id,
          name: x.name,
          type: "",
        }));
      });
  }

  getAssets(): void {
    const params: IODataParams = {
      filter: `(projectId eq '${this.projectId}' and isDeleted ne true) and isActive eq true`,
      selects: ['id', 'name', 'type'],
      orderBy: ['name asc'],
    };

    this._assetsService.get(params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.assets = res.value.map(x => ({
          id: x.id,
          name: x.name,
          type: x.type,
        }));
      });
  }

  getPackages(): void {
    this._boqService.getAllBoqs(this.projectId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.boqPackages = getPackagesNames(res);
      });
  }

  getActivities(): void {
    const params: IODataParams = {
      filter: `(projectId eq '${this.projectId}' and isDeleted ne true)`,
      selects: ['id', 'name'],
      orderBy: ['name asc'],
    };

    this._activityService.get(params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.activities = res.value.map(x => ({
          id: x.id,
          name: x.name,
        }));
      });
  }

  submit(): void {
    const data: Contractor = {
      ...this.editContractorForm.value,
    };

    if (this.contractor) {
      data.id = this.contractor.id;
      data.isDeleted = this.contractor.isDeleted;
      data.projectId = this.contractor.projectId;

      this._contractorEditService.update(data);
    } else {
      this._contractorEditService.create(data);
    }

    this.closeDrawer();
  }

  isFormControlInvalid(controlName: string): boolean {
    const control = this.editContractorForm.get(controlName);
    return control.invalid && control.touched;
  }

  closeDrawer(): void {
    this.isContractorDrawerOpened.emit(false);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
