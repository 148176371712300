import {
  AfterViewInit,
  Component, EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PackagesListDialogComponent } from 'app/components/packages/packages-list-dialog/packages-list-dialog.component';
import { TimeOffAssetsEditDialogComponent } from 'app/components/settings/timeoff-assets/timeoff-assets-dialog/timeoff-assets-dialog.component';
import { dateTimeFormatExceptSec } from 'app/core/constants/date-time-formats';
import {
  IODataParams,
  Project, User
} from 'app/core/interfaces';
import { BaseIdName } from 'app/core/interfaces/base';
import { BoqItem, SelectedPackageItems } from 'app/core/interfaces/boq';
import { BoqsItemsViewModel } from 'app/core/interfaces/boqs-import-view-model';
import { AssetTypes } from 'app/core/interfaces/configuration';
import { SelectedAssetBookJob } from 'app/core/interfaces/selected-asset';
import { BoqItemODataService } from 'app/core/services/odata/boqitem-odata.service';
import { LevelODataService } from 'app/core/services/odata/level-odata.service';
import { RoomODataService } from 'app/core/services/odata/room-odata.service';
import { TruckTypeODataService } from 'app/core/services/odata/truck-type-odata.service';
import { ZoneODataService } from 'app/core/services/odata/zone-odata.service';
import { SchedulerService } from 'app/core/services/scheduler.service';
import {
  FilterScheduler, SetCustomizedUnloadingMethods
} from 'app/core/store/actions/common.actions';
import { AppState } from 'app/core/store/reducers/app.reducer';
import {
  selectUser
} from 'app/core/store/selectors/auth.selectors';
import { selectIsMobileView, selectProject, selectRecurrenceData, selectSpinner } from 'app/core/store/selectors/common.selectors';
import { disabledCalendarDates } from 'app/core/utils/date-time.utils';
import { getJobConflictsDowntime } from 'app/core/utils/job-conflicts-downtime.utils';
import { SNACKBAR_ACTION_DENIED, SNACKBAR_ACTION_ERROR, SNACKBAR_ACTION_WARNING, baseIODataParams, getAssetTypeIcon, getAssetTypeName, getHistory, showJobStatusSnackbar, showSnackbar } from 'app/core/utils/utils';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Asset, RecurrenceEvents } from '../../../core/interfaces/asset';
import { AssignedAreaAsset, AssignedPackages, Contractor } from '../../../core/interfaces/contractor';
import {
  Job
} from '../../../core/interfaces/job';
import { MileStone } from '../../../core/interfaces/mile-stone';
import { AuthService } from '../../../core/services/custom-services/auth.service';
import { AssetODataService } from '../../../core/services/odata/asset-odata.service';
import { ContractorODataService } from '../../../core/services/odata/contractor-odata.service';
import { JobODataService } from '../../../core/services/odata/job-odata.service';
import { JobTasksODataService } from '../../../core/services/odata/job-tasks-odata.service';
import { MilestoneODataService } from '../../../core/services/odata/milestone-odata.service';
import { UnloadingMethodsODataService } from '../../../core/services/odata/unloading-method-odata.service';
import { JobConfirmDialogComponent } from '../job-confirm-dialog/job-confirm-dialog.component';

interface SubmitJobAsset {
  job: Job;
  asset: SelectedAssetBookJob;
}

@Component({
  selector: 'app-request-job',
  templateUrl: './request-job.component.html',
  styleUrls: ['./request-job.component.scss'],
})

export class RequestJobComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() assetEvent = new EventEmitter<boolean>();
  isMobileView: boolean = false;
  showLoader: boolean = false;
  firstStartupLoader: boolean = true;
  currentUser: User;
  siteMap: string = '';
  project: Project;
  showPackageSection: boolean = false;
  isPackageRequired: boolean = false;
  showZoneField: boolean = false;
  showRoomField: boolean = false;
  contractors: Contractor[] = [];
  users: User[] = [];
  filteredUsers: User[] = [];
  mileStones: MileStone[] = [];
  fromMilestones: MileStone[] = [];
  fromBays: AssignedAreaAsset[] = [];
  toMilestones: MileStone[] = [];
  levels: BaseIdName[] = [];
  zones: BaseIdName[] = [];
  rooms: BaseIdName[] = [];
  jobTasks: BaseIdName[] = [];
  packages: AssignedPackages[] = [];
  packageItems: BoqItem[] = [];
  selectedPackageItems: SelectedPackageItems[] = [];
  assets: Asset[] = [];
  filteredAssets: Asset[] = [];
  selectedAssets: SelectedAssetBookJob[] = [];
  maxTrucks: number = 0;
  maxLifts: number = 0;
  unloadingMethods: BaseIdName[] = [];
  truckTypes: BaseIdName[] = [];
  isFromBayExternal: boolean = true;
  maxDate = new Date();
  jobTimeMinutesList: number[] = [];
  form = new FormGroup({
    projectId: new FormControl('', [Validators.required]),
    projectName: new FormControl({ value: '', disabled: true }, [Validators.required]),
    contractorId: new FormControl('', [Validators.required]),
    contractorName: new FormControl('', [Validators.required]),
    contractorColor: new FormControl('', [Validators.required]),
    requesterId: new FormControl(''),
    requesterName: new FormControl(''),
    requesterEmail: new FormControl(''),
    fromLocationType: new FormControl(''),
    fromMilestoneId: new FormControl(''),
    fromMilestoneName: new FormControl(''),
    fromBayId: new FormControl(''),
    fromBayName: new FormControl(''),
    toLocationType: new FormControl(''),
    milestoneId: new FormControl(''),
    milestoneName: new FormControl(''),
    levelId: new FormControl(''),
    levelName: new FormControl(''),
    zoneId: new FormControl(''),
    zoneName: new FormControl(''),
    roomId: new FormControl(''),
    roomName: new FormControl(''),
    taskIds: new FormControl([], [Validators.required]),
    tasks: new FormControl([], [Validators.required]),
    comments: new FormControl(''),
    packageName: new FormControl(''),
    isBayRequired: new FormControl(true),
    endTimeHrs: new FormControl(1),
    endTimeMins: new FormControl(0),
    requestedStartDateTime: new FormControl(new Date()),
  });
  schedulerFilterObject: any[] = [];
  schedulerFilterODataQuery: string;
  schedulerScrollTimeParams: any;
  recurrenceEvents: RecurrenceEvents[] = [];
  dialogRef: any;

  fromLocationTypes: BaseIdName[] = [
    { id: 'External', name: 'External' },
    { id: 'Site', name: 'Site' },
    { id: 'Warehouse', name: 'Warehouse' },
  ];

  toLocationTypes: BaseIdName[] = [
    { id: 'Site', name: 'Site' },
    { id: 'Warehouse', name: 'Warehouse' },
  ];

  assetTypes: AssetTypes[] = [
    {
      "name": "crane",
      "displayName": "Tower Cranes",
    },
    {
      "name": "mcrane",
      "displayName": "Mobile Cranes",
    },
    {
      "name": "bay",
      "displayName": "Offloading Areas",
    },
    {
      "name": "hoist",
      "displayName": "Material Lifts",
    },
    {
      "name": "mhandling",
      "displayName": "Material Handling",
    },
    {
      "name": "hot-seat",
      "displayName": "Hot Seat",
    }
  ];

  config: any = {
    view: 'timeGridDay',
    showTableHeader: true,
    showNextPrevControls: false,
  };

  public format = "dd/MM/yyyy hh:mm a";
  public readOnlyInput = true;
  public steps: any = {
    minute: 15,
  };

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private contractorService: ContractorODataService,
    private milestoneService: MilestoneODataService,
    private levelService: LevelODataService,
    private zoneService: ZoneODataService,
    private roomService: RoomODataService,
    private jobTaskService: JobTasksODataService,
    private unLoadingMethodsService: UnloadingMethodsODataService,
    private assetsService: AssetODataService,
    private jobService: JobODataService,
    private authService: AuthService,
    private router: Router,
    private store: Store<AppState>,
    private _snackBar: MatSnackBar,
    private _truckTypeODataService: TruckTypeODataService,
    private _schedulerService: SchedulerService,
    private _boqODataService: BoqItemODataService,
    private _matDialog: MatDialog,
  ) {
    this.store
      .select(selectUser)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user) => {
        this.currentUser = user;
      });

    this.store
      .select(selectSpinner)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((inProgress) => {
        this.showLoader = inProgress;

        if (!this.showLoader && this.contractors && this.firstStartupLoader && this.form.value.contractorId !== '') {
          this.fillContractorLookups(this.contractors.find(x => x.id === this.form.value.contractorId));
          this.firstStartupLoader = false;
        }
      });

    this.store
      .select(selectIsMobileView)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isMobileView) => {
        this.isMobileView = isMobileView;
      });

    this.store
      .select(selectProject)
      .pipe(filter((project) => !!project && !!project.id && !!project.companyName), takeUntil(this._unsubscribeAll))
      .subscribe((project) => {
        this.project = project;
        this.showPackageSection = project.showPackagesBookJob;
        this.isPackageRequired = project.isPackagesBookJobRequired;

        if (this.showPackageSection && this.isPackageRequired) {
          this.form.get('packageName').setValidators([Validators.required]);
        }

        this.form.patchValue({
          projectId: project.id,
          projectName: project.name,
        });

        if (project.siteMapFile) {
          this.siteMap = this.project.siteMapFile.url;
        }

        if (project.maxDate && project.maxDate !== 0) {
          this.maxDate.setDate(this.maxDate.getDate() + project.maxDate);
        } else {
          this.maxDate.setDate(this.maxDate.getDate() + 1000);
        }

        if (project.maxNoOfLifts && project.maxNoOfLifts !== 0) {
          this.maxLifts = project.maxNoOfLifts;
        }

        if (project.maxNoOfTrucks && project.maxNoOfTrucks !== 0) {
          this.maxTrucks = project.maxNoOfTrucks;
        }

        this.fillLookups();
      });

    this.store
      .select(selectRecurrenceData)
      .pipe(
        filter((rd) => !!rd && !!rd.formValues && !!rd.formValues.eventList && rd.formValues.eventList.length > 0),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe((rd) => {
        this.dialogRef.close(rd.formValues.eventList);
      });

    this.jobTimeMinutesList = Array.from({ length: 60 }, (_, index) => index).filter(num => num % 15 === 0);
  }

  fillLookups(): void {
    this.contractorService.get(baseIODataParams(this.project.id))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        var contractors = res.value;

        if (!this.isRoleAdmin()) {
          contractors = contractors.filter(x => x.users?.filter(user => user.id === this.currentUser.id).length > 0);
        }

        if (contractors.length > 0) {
          const contractor = contractors[0];
          this.form.patchValue({
            contractorId: contractor.id,
            contractorName: contractor.name,
            contractorColor: contractor.color,
          });
        }

        this.contractors = contractors;
      });

    if (!this.isRoleAdmin()) {
      this.form.patchValue({
        requesterId: this.currentUser.id,
        requesterName: this.currentUser.name,
        requesterEmail: this.currentUser.principalName,
      });
    } else {
      this.authService.getUsers(this.project.id)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          this.users = res.length === 0 ? [this.currentUser] : res;
        });
    }

    this.unLoadingMethodsService
      .get(baseIODataParams(this.project.id, true))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.unloadingMethods = res.value);

    this._truckTypeODataService
      .get(baseIODataParams(this.project.id, true))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.truckTypes = res.value);
  }

  ngOnInit(): void {
    this._schedulerService.updateRequestJobOnSelection
      .pipe(filter(x => !!x), takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        const selectedDate = new Date(response.startDate);
        const isInRange = disabledCalendarDates(selectedDate, this.maxDate, this.isRoleAdmin());

        if (!isInRange) {
          this.form.patchValue({
            requestedStartDateTime: new Date(response.startDateTime),
            endTimeHrs: response.endTimeInHours,
            endTimeMins: response.endTimeInMinutes,
          });
        } else {
          showSnackbar(this._snackBar, 'You cannot book a job on this selected date!', SNACKBAR_ACTION_DENIED);
        }
      });
  }

  ngAfterViewInit(): void {
    this.form.get('requesterId').setValidators([Validators.required]);
    this.form.get('requesterName').setValidators([Validators.required]);
    this.form.get('requesterEmail').setValidators([Validators.required]);
    this.form.get('fromLocationType').setValidators([Validators.required]);
    this.form.get('toLocationType').setValidators([Validators.required]);
    this.form.get('milestoneId').setValidators([Validators.required]);
    this.form.get('milestoneName').setValidators([Validators.required]);
    this.form.get('levelId').setValidators([Validators.required]);
    this.form.get('levelName').setValidators([Validators.required]);
    this.form.get('endTimeHrs').setValidators([Validators.required, Validators.min(0), Validators.max(23)]);
    this.form.get('requestedStartDateTime').setValidators([Validators.required]);
  }

  toggleInnerAssets(asset: Asset, index: number): void {
    if (!this.getSelectedAssets(asset.name)) {
      let fields = null, values = null, defaultValues = null;

      if (asset.type === 'bay') {
        fields = {
          truck: `truck${index}`,
          unloadingMethod: `unloadingMethod${index}`,
          truckType: `truckType${index}`,
        };

        values = {
          truck: '',
          unloadingMethod: [],
          truckType: null,
        };

        defaultValues = {
          maxTrucks: this.maxTrucks,
        };

        if (this.maxTrucks !== 0) {
          this.form.addControl(`truck${index}`, new FormControl('', [Validators.required, Validators.min(1), Validators.max(this.maxTrucks)]));
        } else {
          this.form.addControl(`truck${index}`, new FormControl('', [Validators.required, Validators.min(1)]));
        }

        this.form.addControl(`unloadingMethod${index}`, new FormControl(null, [Validators.required]));
        this.form.addControl(`truckType${index}`, new FormControl(null, [Validators.required]));
      } else if (asset.type === 'crane') {
        fields = {
          pickUp: `pickUp${index}`,
          dropOff: `dropOff${index}`,
          lift: `tclift${index}`,
          tonnage: `tonnage${index}`,
          tonnageUnit: `tonnageUnit${index}`,
        };

        values = {
          pickUp: null,
          dropOff: null,
          lift: '',
          tonnage: '',
          tonnageUnit: '',
        };

        defaultValues = {
          maxLifts: this.maxLifts,
          sectors: asset.sectors,
          imageFile: asset.imageFile,
        };

        if (this.maxLifts !== 0) {
          this.form.addControl(`tclift${index}`, new FormControl('', [Validators.required, Validators.min(1), Validators.max(this.maxLifts)]));
        } else {
          this.form.addControl(`tclift${index}`, new FormControl('', [Validators.required, Validators.min(1)]));
        }

        this.form.addControl(`pickUp${index}`, new FormControl(null, [Validators.required]));
        this.form.addControl(`dropOff${index}`, new FormControl(null, [Validators.required]));
        this.form.addControl(`tonnage${index}`, new FormControl(''));
        this.form.addControl(`tonnageUnit${index}`, new FormControl(''));
      } else if (asset.type === 'mcrane') {
        fields = {
          lift: `mclift${index}`,
        };

        values = {
          lift: '',
        };

        defaultValues = {
          maxLifts: this.maxLifts,
        };

        if (this.maxLifts !== 0) {
          this.form.addControl(`mclift${index}`, new FormControl('', [Validators.required, Validators.min(1), Validators.max(this.maxLifts)]));
        } else {
          this.form.addControl(`mclift${index}`, new FormControl('', [Validators.required, Validators.min(1)]));
        }
      } else if (asset.type === 'hoist') {
        fields = {
          maxWeight: `maxWeight${index}`,
          verticalTrip: `verticalTrip${index}`,
          sizeDimension: `sizeDimension${index}`,
        };

        values = {
          maxWeight: '',
          verticalTrip: '',
          sizeDimension: '',
        };

        defaultValues = {
          carClearWidth: asset.carClearWidth,
          carClearDepth: asset.carClearDepth,
          carClearHeight: asset.carClearHeight,
          doorOpeningWidth: asset.doorOpeningWidth,
          doorOpeningHeight: asset.doorOpeningHeight,
          weightLoad: asset.weightLoad,
          comments: asset.comments,
          serviceArea: asset.serviceArea,
          accessType: asset.accessType,
        };

        this.form.addControl(`maxWeight${index}`, new FormControl('', [Validators.required, Validators.min(1)]));
        this.form.addControl(`verticalTrip${index}`, new FormControl('', [Validators.required, Validators.min(1)]));
        this.form.addControl(`sizeDimension${index}`, new FormControl('', [Validators.required]));
      }

      this.selectedAssets.push({
        id: asset.id,
        name: asset.name,
        type: asset.type,
        assetName: getAssetTypeName(asset.type),
        downtime: asset.downtime,
        fields,
        values,
        defaultValues,
      });
    } else {
      if (asset.type === 'bay') {
        this.form.removeControl(`truck${index}`);
        this.form.removeControl(`unloadingMethod${index}`);
        this.form.removeControl(`truckType${index}`);
      } else if (asset.type === 'crane') {
        this.form.removeControl(`tclift${index}`);
        this.form.removeControl(`pickUp${index}`);
        this.form.removeControl(`dropOff${index}`);
        this.form.removeControl(`tonnage${index}`);
        this.form.removeControl(`tonnageUnit${index}`);
      } else if (asset.type === 'mcrane') {
        this.form.removeControl(`mclift${index}`);
      } else if (asset.type === 'hoist') {
        this.form.removeControl(`maxWeight${index}`);
        this.form.removeControl(`verticalTrip${index}`);
        this.form.removeControl(`sizeDimension${index}`);
      }

      _.remove(this.selectedAssets, x => x.id === asset.id);
      const hasBay = this.selectedAssets.some(item => item.type === 'bay');
      const hasOtherAssets = this.selectedAssets.some(item => item.type !== 'bay');
      if (hasBay && !hasOtherAssets) {
        this.store.dispatch(new SetCustomizedUnloadingMethods(this.unloadingMethods));
      }
    }

    this.prepareSchedulerFilterParams();

    if (this.selectedAssets.length > 0) {
      this.assetEvent.emit(true);
    } else {
      this.assetEvent.emit(false);
    }
  }

  prepareSchedulerFilterParams = (): void => {
    const selectedAssets = this.filteredAssets.filter(x => this.selectedAssets.map(a => a.id).indexOf(x.id) >= 0);

    if (selectedAssets.length > 0) {
      this.addSelectedSelectedAssetsAndDate('assets', selectedAssets);
    }

    this.addSelectedSelectedAssetsAndDate('requestedStartTime', this.form.value.requestedStartDateTime);

    const obj = {
      assets: selectedAssets,
      searchString: this.schedulerFilterODataQuery,
      searchObject: this.schedulerFilterObject,
    };

    const startDateTime = this.checkAndGetDateAsMoment(this.form.value.requestedStartDateTime);
    const startTime = moment(`${startDateTime.format(dateTimeFormatExceptSec)}`);
    const hours = this.form.value.endTimeHrs;
    const minutes = this.form.value.endTimeMins;

    this.schedulerScrollTimeParams = this.getStartAndEndTime(startTime, hours, minutes);
    this._schedulerService.setScrollTime(this.schedulerScrollTimeParams);
    this.store.dispatch(new FilterScheduler(obj));
  };

  addSelectedSelectedAssetsAndDate(field, value): void {
    this.schedulerFilterObject = [...this.schedulerFilterObject];
    _.remove(this.schedulerFilterObject, x => x.field === field);
    this.schedulerFilterObject.push({
      field: field,
      value: value,
    });

    this.schedulerFilterODataQuery = this.schedulerFilterObject
      .map((x) => {
        let queryFilter = '';
        if (x.field === 'requestedStartTime') {
          queryFilter = `( requestedStartTime le '${moment(x.value)
            .endOf('day')
            .utc()
            .format()}' and requestedEndTime ge '${moment(x.value)
              .startOf('day')
              .utc()
              .format()}' )`;
        } else {
          queryFilter = '(' + x.value.map(a => `assetId eq '${a.id}'`).join(' or ') + ')';
        }

        return queryFilter;
      })
      .join(' and ');
  }

  selectChangeHandler(data: any, type: string): void {
    switch (type) {
      case 'contractorSelection':
        this.assetEvent.emit(false);
        this.removeSelectedAssetsFormFields(this.selectedAssets);
        this.removeSelectedPackageItemsFormFields(this.selectedPackageItems);
        this.showZoneField = false;
        this.showRoomField = false;
        this.isFromBayExternal = true;
        this.fromMilestones = [];
        this.fromBays = [];
        this.toMilestones = [];
        this.levels = [];
        this.zones = [];
        this.rooms = [];
        this.packageItems = [];
        this.selectedPackageItems = [];
        this.selectedAssets = [];
        this.form.get('fromMilestoneId').clearValidators();
        this.form.get('fromMilestoneName').clearValidators();
        this.form.get('fromBayId').clearValidators();
        this.form.get('fromBayName').clearValidators();
        this.form.get('zoneId').clearValidators();
        this.form.get('zoneName').clearValidators();
        this.form.get('roomId').clearValidators();
        this.form.get('roomName').clearValidators();
        this.form.patchValue({
          contractorId: data.id,
          contractorName: data.name,
          contractorColor: data.color,
          fromLocationType: null,
          fromMilestoneId: null,
          fromMilestoneName: null,
          fromBayId: null,
          fromBayName: null,
          toLocationType: null,
          milestoneId: null,
          milestoneName: null,
          levelId: null,
          levelName: null,
          zoneId: null,
          zoneName: null,
          roomId: null,
          roomName: null,
          taskIds: null,
          tasks: null,
          packageName: null,
        });

        if (this.isRoleAdmin()) {
          this.form.patchValue({
            requesterId: null,
            requesterName: null,
            requesterEmail: null,
          });
        }

        this.fillContractorLookups(data);
        break;

      case 'userSelection':
        this.form.patchValue({
          requesterId: data.id,
          requesterName: data.name,
          requesterEmail: data.principalName,
        });

        break;

      case 'fromLocationTypeSelection':
        this.fromBays = [];
        this.form.patchValue({
          fromLocationType: data.name,
          fromMilestoneId: null,
          fromMilestoneName: null,
          fromBayId: null,
          fromBayName: null,
        });

        if (data.name === 'External') {
          this.isFromBayExternal = true;
          this.fromMilestones = [];
        } else if (data.name === 'Site') {
          this.isFromBayExternal = false;
          this.fromMilestones = this.mileStones.filter(x => x.storageLocation === 'JIT');
        } else {
          this.isFromBayExternal = false;
          this.fromMilestones = this.mileStones.filter(x => x.storageLocation === 'StOBL' || x.storageLocation === 'CCC');
        }

        if (this.isFromBayExternal) {
          this.form.get('fromMilestoneId').clearValidators();
          this.form.get('fromMilestoneName').clearValidators();
          this.form.get('fromBayId').clearValidators();
          this.form.get('fromBayName').clearValidators();
        } else {
          this.form.get('fromMilestoneId').setValidators(Validators.required);
          this.form.get('fromMilestoneName').setValidators(Validators.required);
          this.form.get('fromBayId').setValidators(Validators.required);
          this.form.get('fromBayName').setValidators(Validators.required);
        }

        break;

      case 'fromMilestoneSelection':
        this.form.patchValue({
          fromMilestoneId: data.id,
          fromMilestoneName: data.name,
          fromBayId: null,
          fromBayName: null,
        });

        const fromBaysData = this.fromMilestones.find(x => x.id === data.id);
        if (fromBaysData && fromBaysData.assets) {
          this.fromBays = fromBaysData.assets.filter(a => a.type === 'bay');
        } else {
          this.fromBays = [];
        }

        break;

      case 'fromBaySelection':
        this.form.patchValue({
          fromBayId: data.id,
          fromBayName: data.name,
        });

        break;

      case 'toLocationTypeSelection':
        this.levels = [];
        this.zones = [];
        this.rooms = [];

        this.form.patchValue({
          toLocationType: data.name,
          milestoneId: null,
          milestoneName: null,
          levelId: null,
          levelName: null,
          zoneId: null,
          zoneName: null,
          roomId: null,
          roomName: null,
        });

        if (data.name === 'Site') {
          this.toMilestones = this.mileStones.filter(x => x.storageLocation === 'JIT');
        } else {
          this.toMilestones = this.mileStones.filter(x => x.storageLocation === 'StOBL' || x.storageLocation === 'CCC');
        }

        break;

      case 'toMilestoneSelection':
        this.zones = [];
        this.rooms = [];

        this.form.patchValue({
          milestoneId: data.id,
          milestoneName: data.name,
          levelId: null,
          levelName: null,
          zoneId: null,
          zoneName: null,
          roomId: null,
          roomName: null,
        });

        const levelParams = baseIODataParams(this.project.id, true);
        levelParams.filter += ` and areaId eq '${data.id}'`;

        this.levelService.get(levelParams).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
          this.levels = res.value;
        });

        if (data.assets && data.assets.length > 0) {
          this.filteredAssets = this.assets.filter(a => data.assets.some(c => c.id === a.id));
          const oldSelectedAssets = this.selectedAssets;
          this.selectedAssets = this.selectedAssets.filter(sa => data.assets.some(c => c.id === sa.id));
          const fetchBayAssets = oldSelectedAssets.map(osa => {
            const index = this.selectedAssets.findIndex(x => x.id === osa.id);
            if (index < 0) {
              return osa;
            }
          });

          const resultArray = fetchBayAssets.filter(asset => asset !== undefined);
          this.removeSelectedAssetsFormFields(resultArray);
          this.prepareSchedulerFilterParams();

          if (this.selectedAssets.length === 0) {
            this.assetEvent.emit(false);
          }
        } else {
          this.filteredAssets = this.assets;
        }

        break;

      case 'levelSelection':
        this.form.patchValue({
          levelId: data.id,
          levelName: data.name,
          zoneId: null,
          zoneName: null,
          roomId: null,
          roomName: null,
        });

        const zoneRoomParams = baseIODataParams(this.project.id, true);
        zoneRoomParams.filter += ` and levelId eq '${data.id}'`;

        this.zoneService.get(zoneRoomParams).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
          this.zones = res.value;
          this.showZoneField = res.count > 0;

          if (this.showZoneField) {
            this.form.get('zoneId').setValidators(Validators.required);
            this.form.get('zoneName').setValidators(Validators.required);
          } else {
            this.form.get('zoneId').clearValidators();
            this.form.get('zoneName').clearValidators();
          }
        });

        this.roomService.get(zoneRoomParams).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
          this.rooms = res.value;
          this.showRoomField = res.count > 0;

          if (this.showRoomField) {
            this.form.get('roomId').setValidators(Validators.required);
            this.form.get('roomName').setValidators(Validators.required);
          } else {
            this.form.get('roomId').clearValidators();
            this.form.get('roomName').clearValidators();
          }
        });

        break;

      case 'zoneSelection':
        this.form.patchValue({
          zoneId: data.id,
          zoneName: data.name,
        });

        break;

      case 'roomSelection':
        this.form.patchValue({
          roomId: data.id,
          roomName: data.name,
        });

        break;

      case 'taskSelection':
        if (!data || data.length === 0) {
          this.form.patchValue({
            taskIds: null,
            tasks: null,
          });

          return;
        }

        const taskList = data.map((x) => {
          return {
            id: x,
            name: this.jobTasks.find(jt => jt.id === x).name,
          }
        });

        this.form.patchValue({
          tasks: taskList,
        });

        break;

      case 'packageSelection':
        this.form.patchValue({
          packageName: data.name,
        });

        this.packageSelectionChange(data.name);
        break;
    }
  }

  fillContractorLookups(selectedContractor: Contractor): void {
    this.filteredUsers = selectedContractor.users && selectedContractor.users?.length > 0
      ? this.users.filter(u => selectedContractor.users.some(c => c.id === u.id))
      : this.users;

    const milestoneParams = baseIODataParams(this.project.id);

    if (selectedContractor.areas && selectedContractor.areas?.length > 0) {
      milestoneParams.filter += ' and (' + selectedContractor.areas.map(a => `id eq '${a.id}'`).join(' or ') + ')';
    }

    this.milestoneService.get(milestoneParams).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.mileStones = res.value;
    });

    const jobTasksParams = baseIODataParams(this.project.id, true);

    if (selectedContractor.activities && selectedContractor.activities?.length > 0) {
      jobTasksParams.filter += ' and (' + selectedContractor.activities.map(a => `id eq '${a.id}'`).join(' or ') + ')';
    }

    this.jobTaskService.get(jobTasksParams).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.jobTasks = res.value;
    });

    this.packages = selectedContractor.packages && selectedContractor.packages?.length > 0
      ? selectedContractor.packages
      : [];

    const assetParams = baseIODataParams(this.project.id);
    assetParams.filter += ` and isActive eq true`;

    if (selectedContractor.assets && selectedContractor.assets?.length > 0) {
      assetParams.filter += ' and (' + selectedContractor.assets.map(a => `id eq '${a.id}'`).join(' or ') + ')';
    }

    this.assetsService.get(assetParams).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.assets = res.value;
      this.filteredAssets = res.value;
    });
  }

  packageSelectionChange(name: string): void {
    const params = baseIODataParams(this.project.id);
    params.filter += ` and packageTitle eq '${name}' and storageStatus eq 'approve'`;

    this._boqODataService.get(params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res.count === 0) {
          showSnackbar(this._snackBar, 'No Package Items Found! Please Contact Administrator for Addition', SNACKBAR_ACTION_ERROR);
          this.form.patchValue({
            packageName: null,
          });
        }

        this.selectedPackageItems.forEach((sp) => {
          this.removeSelectedPackageItem(sp.id);
        });

        this.packageItems = res.value;
      });
  }

  openPackageDialog(): void {
    const dialogRef = this._matDialog.open(PackagesListDialogComponent,
      {
        width: '800px',
        data: this.packageItems,
      }
    );

    dialogRef.afterClosed().subscribe((response: any[]) => {
      if (!response) {
        return;
      }

      response.forEach((v, i) => {
        const controlName = `item${i}`;
        const existingItemIndex = this.selectedPackageItems.findIndex(item => item.itemId === v.id);

        if (existingItemIndex === -1) {
          this.selectedPackageItems.push({
            id: controlName,
            itemId: v.id,
            name: v.name,
            quantity: 1,
            allowableQuantity: v.allowableQuantity,
            measuredUnit: v.measuredUnit ? v.measuredUnit : '',
          });

          this.form.addControl(controlName, new FormControl(1, [Validators.required, Validators.min(1), Validators.max(v.allowableQuantity)]));
          this.packageItems.find(pi => pi.id === v.id).isSelected = true;
        }
      });
    });
  }

  updatePackageItems(value: any, itemFormCode: string): void {
    const index = this.selectedPackageItems.findIndex(item => item.id === itemFormCode);
    if (index !== -1) {
      this.selectedPackageItems[index].quantity = value;
    }
  }

  removeSelectedPackageItem(id: string): void {
    const index = this.selectedPackageItems.findIndex(item => item.id === id);

    if (index !== -1) {
      const removedItem = this.selectedPackageItems.splice(index, 1)[0];
      this.form.removeControl(removedItem.id);
      this.packageItems.find(pi => pi.id === removedItem.itemId).isSelected = false;
    }
  }

  removeSelectedAssetsFormFields(selectedAssets: SelectedAssetBookJob[]): void {
    selectedAssets.forEach((sa) => {
      if (sa.type === 'crane') {
        this.form.removeControl(sa.fields['pickUp']);
        this.form.removeControl(sa.fields['dropOff']);
        this.form.removeControl(sa.fields['lift']);
        this.form.removeControl(sa.fields['tonnage']);
        this.form.removeControl(sa.fields['tonnageUnit']);
      } else if (sa.type === 'mcrane') {
        this.form.removeControl(sa.fields['lift']);
      } else if (sa.type === 'bay') {
        this.form.removeControl(sa.fields['truck']);
        this.form.removeControl(sa.fields['unloadingMethod']);
        this.form.removeControl(sa.fields['truckType']);
      } else if (sa.type === 'hoist') {
        this.form.removeControl(sa.fields['maxWeight']);
        this.form.removeControl(sa.fields['verticalTrip']);
        this.form.removeControl(sa.fields['sizeDimension']);
      }
    });
  }

  removeSelectedPackageItemsFormFields(selectedPackageItems: SelectedPackageItems[]): void {
    selectedPackageItems.forEach((spi) => {
      this.form.removeControl(spi.id);
    });
  }

  submit(): void {
    const val = this.isExceptionUnloadingMethod();
    if (val) {
      const dialogRef = this._matDialog.open(JobConfirmDialogComponent, {
        data: {
          title: 'Book Job',
          message: `Are you sure, you want to proceed without an Asset for Unloading?`,
          actionType: 'YesAndNo',
        },
        width: '500px',
      });

      dialogRef.afterClosed().subscribe((response: string) => {
        if (!response) {
          return;
        }

        this.submitJobs();
      });
    } else {
      this.submitJobs();
    }
  }

  submitJobs(): void {
    const jobPackageItems = [];
    let assetJobList: SubmitJobAsset[] = [];
    const isDoubleBookingEnabled = this.project.appSettings.find(
      x => x.name === 'Double Booking' && x.isChecked
    );

    const requestStartDate = moment(this.form.value.requestedStartDateTime, 'YYYY-MM-DD HH:mm').toDate();
    const requestEndDate = new Date(requestStartDate);
    requestEndDate.setHours(requestEndDate.getHours() + this.form.value.endTimeHrs);
    requestEndDate.setMinutes(requestEndDate.getMinutes() + this.form.value.endTimeMins);

    const commentValue = {
      email: this.currentUser.principalName,
      name: this.currentUser.name,
      text: this.form.value.comments,
      timestamp: new Date()
    };

    for (const selectedItem of this.selectedPackageItems) {
      jobPackageItems.push({
        id: selectedItem.itemId,
        itemName: selectedItem.name,
        quantity: selectedItem.quantity,
        measuredUnit: selectedItem.measuredUnit,
      });
    }

    for (const asset of this.selectedAssets) {
      const job = _.cloneDeep(this.form.value);
      job.comments = this.form.value.comments ? [commentValue] : [];
      job.package = jobPackageItems.length > 0 ? {
        name: job.packageName,
        items: jobPackageItems,
      } : null;

      job.assetId = asset.id;
      job.assetName = asset.name;
      job.assetType = asset.type;

      if (asset.type === 'crane') {
        job.pickup = asset.values['pickUp'];
        job.dropOff = asset.values['dropOff'];
        job.lifts = asset.values['lift'];
        job.tonnage = asset.values['tonnage'];
        job.tonnageUnit = asset.values['tonnageUnit'];
      } else if (asset.type === 'mcrane') {
        job.lifts = asset.values['lift'];
      } else if (asset.type === 'bay') {
        job.trucks = asset.values['truck'];
        job.truckTypeId = asset.values['truckType'].id;
        job.truckTypeName = asset.values['truckType'].name;
        job.unloadingMethods = asset.values['unloadingMethod'];
      } else if (asset.type === 'hoist') {
        job.maxWeight = asset.values['maxWeight'];
        job.verticalTrips = asset.values['verticalTrip'];
        job.sizeDimensions = asset.values['sizeDimension'];
      }

      job.status = 'requested';
      job.jobsHistory = [getHistory('Job Created', this.currentUser)];
      job.requestedStartTime = requestStartDate;
      job.requestedEndTime = requestEndDate;
      job.createdDate = moment(new Date()).toDate();

      assetJobList = [
        ...assetJobList,
        {
          asset: asset,
          job: job,
        }
      ];
    }

    if (assetJobList.length > 0) {
      if (isDoubleBookingEnabled) {
        this.checkDowntimeinJob(assetJobList);
      } else {
        this.checkAssetAlreadyBooked(requestStartDate, requestEndDate, assetJobList);
      }
    }
  }

  checkAssetAlreadyBooked(requestStartDate: Date, requestEndDate: Date, assets: SubmitJobAsset[]): void {
    let params = this.checkRequestValid(
      requestStartDate,
      requestEndDate,
      assets
    );

    this.jobService
      .get(params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        if (response.count > 0) {
          showSnackbar(this._snackBar, `The following selected asset is already booked: ${response.value.map(a => a.assetName).join(',')}`, SNACKBAR_ACTION_WARNING);
        } else {
          this.checkDowntimeinJob(assets);
        }
      });
  }

  checkDowntimeinJob(assets: SubmitJobAsset[]): void {
    const downTimeAssets = [];
    const jobList = [];

    assets.forEach((d) => {
      if (d.asset.downtime !== undefined && d.asset.downtime !== null) {
        const downtime = getJobConflictsDowntime(d.asset.downtime, d.job);

        if (downtime.length === 0) {
          jobList.push(d.job);
        } else {
          downTimeAssets.push(d.asset.name);
        }
      } else {
        jobList.push(d.job);
      }
    });

    if (downTimeAssets.length > 0) {
      showSnackbar(this._snackBar, `Following selected assets ${downTimeAssets.join(',')} have been downtime/in-active during selected periods`, SNACKBAR_ACTION_WARNING);
    } else if (jobList.length > 0) {
      this.saveJob(jobList);
    }
  }

  saveJob(jobs: Job[]): void {
    const allJobs: any = {
      jobs: jobs
    };

    this.jobService.post(allJobs)
      .subscribe(() => {
        if (allJobs.jobs[0].package !== null && allJobs.jobs[0].package.items.length > 0) {
          const boqsUpdateQuantitydata: BoqsItemsViewModel = {
            projectId: this.project.id,
            package: allJobs.jobs[0].package,
            history: getHistory('Package Requested', this.currentUser)
          };

          this._boqODataService.bulkQuantityUpdate(boqsUpdateQuantitydata)
            .subscribe(() => {
              showJobStatusSnackbar(this._snackBar, 'Created');
              this.router.navigateByUrl('/jobs');
            });
        } else {
          showJobStatusSnackbar(this._snackBar, 'Created');
          this.router.navigateByUrl('/jobs');
        }
      });
  }

  getStartAndEndTime = (startDateTime: any, hours: number, minutes: number): any => {
    let params: any = {};
    params.startTime = startDateTime.format();
    params.endTime = startDateTime
      .add('hours', hours)
      .add('minutes', minutes)
      .format();
    return params;
  };

  checkAndGetDateAsMoment = (date: any): any => moment.isMoment(date) ? date : moment(date);

  selectTime(event, type: string): void {
    const startDateTime = this.checkAndGetDateAsMoment(this.form.value.requestedStartDateTime);
    const startTime = moment(`${startDateTime.format(dateTimeFormatExceptSec)}`);
    let hours = this.form.value.endTimeHrs;
    let minutes = this.form.value.endTimeMins;
    let params: any = {};

    switch (type) {
      case 'start-time':
        params = this.getStartAndEndTime(startTime, hours, minutes);
        this.prepareSchedulerFilterParams();
        break;

      case 'end-hours':
        hours = event;
        params = this.getStartAndEndTime(startTime, hours, minutes);
        break;

      case 'end-mins':
        minutes = event;
        params = this.getStartAndEndTime(startTime, hours, minutes);
        break;
    }

    this.schedulerScrollTimeParams = params;
    this._schedulerService.setScrollTime(this.schedulerScrollTimeParams);
  }

  checkRequestValid = (
    startTime: Date,
    endTime: Date,
    assetJobList: SubmitJobAsset[]
  ): IODataParams => {
    const assetParams = assetJobList.map(a => `assetId eq '${a.asset.id}'`).join(' or ');
    let dateParams = ``;

    if (startTime && endTime) {
      dateParams = ` ( requestedStartTime lt '${moment(startTime)
        .utc()
        .format()}' and requestedEndTime gt '${moment(startTime)
          .utc()
          .format()}' )`;

      dateParams += ` or ( requestedStartTime lt '${moment(endTime)
        .utc()
        .format()}' and requestedEndTime gt '${moment(endTime)
          .utc()
          .format()}' )`;

      dateParams += ` or ( requestedStartTime eq '${moment(startTime)
        .utc()
        .format()}' and requestedEndTime eq '${moment(endTime)
          .utc()
          .format()}' )`;

      dateParams += ` or ( requestedStartTime ge '${moment(startTime)
        .utc()
        .format()}' and requestedEndTime le '${moment(endTime)
          .utc()
          .format()}' ) `;
    }

    const params = {
      filter: `(projectId eq '${this.project.id}') and (${assetParams}) and (status eq 'approved') and ( ${dateParams} )`,
    };

    return params;
  };

  onRequiredBayChange(value: boolean): void {
    if (value) {
      this.assetTypes.splice(2, 0, {
        "name": "bay",
        "displayName": "Offloading Areas",
      });
    } else {
      this.assetTypes = this.assetTypes.filter(x => x.name !== 'bay');
      const fetchBayAssets = this.selectedAssets.filter(sa => sa.type === 'bay');
      this.removeSelectedAssetsFormFields(fetchBayAssets);
      this.selectedAssets = this.selectedAssets.filter(sa => sa.type !== 'bay');
      this.prepareSchedulerFilterParams();

      if (this.selectedAssets.length === 0) {
        this.assetEvent.emit(false);
      }
    }
  }

  openRecurrenceDialog(): void {
    this.dialogRef = this._matDialog.open(TimeOffAssetsEditDialogComponent, {
      data: null,
      width: "700px",
    });

    this.dialogRef.afterClosed().subscribe((response: any) => {
      if (!response) {
        return;
      }

      this.recurrenceEvents = response;
      this.recurrenceEvents.forEach(x => {
        this.form.patchValue({
          requestedStartDateTime: new Date(x.start),
        });
        this.submitJobs();
      });
    });
  }

  renderImage = (assetType: string): string => getAssetTypeIcon(assetType);

  getSelectedAssets = (name: string): boolean => this.selectedAssets?.find(x => x.name === name) ? true : false;

  selectedAssetsForType = (type: string): boolean => this.selectedAssets.find(x => x.type === type) ? true : false;

  assetsForType = (type: string): Asset[] => this.filteredAssets.filter(x => x.type === type);

  assetExists = (type: string): boolean => this.assetsForType(type).length > 0;

  isRoleAdmin = (): boolean => (this.currentUser.roleName === 'Admin' || this.currentUser.roleName === 'Global Admin');

  isExceptionUnloadingMethod = (): boolean => this.selectedAssets.filter(x => x.type !== 'bay').length === 0;

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  public disabledDates = (date: Date): boolean => {
    return disabledCalendarDates(date, this.maxDate, this.isRoleAdmin());
  }
}
