import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  Contractor,
  IODataParams,
  JobTask,
  Level,
  Zone
} from 'app/core/interfaces';
import { Base } from 'app/core/interfaces/base';
import { AccessManagement } from 'app/core/interfaces/roles';
import { Room } from 'app/core/interfaces/room';
import { ContractorODataService } from 'app/core/services/odata/contractor-odata.service';
import { JobTasksODataService } from 'app/core/services/odata/job-tasks-odata.service';
import { LevelODataService } from 'app/core/services/odata/level-odata.service';
import { MilestoneODataService } from 'app/core/services/odata/milestone-odata.service';
import { RoomODataService } from 'app/core/services/odata/room-odata.service';
import { ZoneODataService } from 'app/core/services/odata/zone-odata.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectUser, selectUserSettings } from 'app/core/store/selectors';
import { selectProject } from 'app/core/store/selectors/common.selectors';
import { isRolePermissionAllowed } from 'app/core/utils/utils';
import { Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-job-search-dialog',
  templateUrl: './job-search-dialog.component.html',
  styleUrls: ['./job-search-dialog.component.scss'],
})

export class JobSearchDialogComponent implements OnDestroy {
  jobSearchForm: FormGroup;
  isSearched = false;
  mileStones: Base[] = [];
  levels: Level[] = [];
  zones: Zone[] = [];
  rooms: Room[] = [];
  contractors: Contractor[] = [];

  filteredLevels: Level[] = [];
  filteredZones: Zone[] = [];
  filteredRooms: Room[] = [];
  userAccess: AccessManagement;
  jobTasks: JobTask[] = [];

  statusList = [
    {
      id: 'requested',
      value: 'Requested',
    },
    {
      id: 'approved',
      value: 'Approved',
    },
    {
      id: 'completed',
      value: 'Completed',
    },
    {
      id: 'cancelled',
      value: 'Cancelled',
    },
  ];

  assetTypeList = [
    {
      id: 'crane',
      name: 'Tower Crane',
    },
    {
      id: 'mcrane',
      name: 'Mobile Crane',
    },
    {
      id: 'bay',
      name: 'Bay',
    },
    {
      id: 'hoist',
      name: 'Material Lift',
    },
  ];
  projectId: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private milestoneService: MilestoneODataService,
    private jobTaskService: JobTasksODataService,
    public matDialogRef: MatDialogRef<JobSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private store: Store<AppState>,
    private levelService: LevelODataService,
    private zoneService: ZoneODataService,
    private roomService: RoomODataService,
    private contractorODataService: ContractorODataService
  ) {
    this.store.select(selectProject)
      .pipe(filter(project => !!project && !!project.id), takeUntil(this._unsubscribeAll))
      .subscribe((project) => {
        this.projectId = project.id;
      });

    this.store
      .select(selectUserSettings)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: AccessManagement) => {
        if (response) {
          this.userAccess = response;
        }
      });

    this.jobSearchForm = this.createSearchForm();
    this.bindDropdowns();
    this.showClearSearch();
  }

  createSearchForm = (): FormGroup => {
    let fields = {};
    if (this._data) {
      fields = {
        area: new FormControl(this._data.area || {}),
        task: new FormControl(this._data.task || {}),
        status: new FormControl(this._data.status || {}),
        assetType: new FormControl(this._data.assetType || {}),
        level: new FormControl(this._data.level || {}),
        zone: new FormControl(this._data.zone || {}),
        room: new FormControl(this._data.room || {}),
        contractor: new FormControl(this._data.contractor || {}),
      };
    } else {
      fields = {
        area: new FormControl(),
        task: new FormControl(),
        status: new FormControl(),
        assetType: new FormControl(),
        level: new FormControl(),
        zone: new FormControl(),
        room: new FormControl(),
        contractor: new FormControl(),
      };
    }

    return new FormGroup(fields);
  };

  bindDropdowns(): void {
    const params: IODataParams = {
      filter: `(projectId eq '${this.projectId}' and isDeleted ne true)`,
      orderBy: ['name asc'],
    };

    this.milestoneService.get({
      ...params,
      selects: ['id', 'name']
    }).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.mileStones = res.value;
    });

    this.jobTaskService.get(params).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.jobTasks = res.value;
    });

    this.levelService.get(params).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.levels = res.value;

      if (this._data.area) {
        this.selectDropdown(this._data.area, 'area');
      }
    });

    this.zoneService.get(params).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.zones = res.value;

      if (this._data.level) {
        this.selectDropdown(this._data.level, 'level');
      }
    });

    this.roomService.get(params).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.rooms = res.value;

      if (this._data.level) {
        this.selectDropdown(this._data.level, 'level');
      }
    });

    this.contractorODataService.get(params).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      let contractors = res.value;

      this.store.select(selectUser).pipe(takeUntil(this._unsubscribeAll)).subscribe((currentUser) => {
        if (isRolePermissionAllowed('manage-job-access', this.userAccess)) {
          contractors = contractors.filter(
            x =>
              x.users?.filter(user => user.id === currentUser.id).length >
              0
          );
        }

        this.contractors = contractors;
      });
    });
  }

  showClearSearch() {
    if (
      this._data.area ||
      this._data.task ||
      this._data.status ||
      this._data.assetType ||
      this._data.level ||
      this._data.zone ||
      this._data.room ||
      this._data.contractor
    ) {
      this.isSearched = true;
    }
  }

  clearSearch() {
    this.jobSearchForm.reset();
  }

  selectDropdown(selection: any, type: string): void {
    switch (type) {
      case 'area':
        this.filteredLevels = this.levels.filter(
          x => x.areaId === selection.id
        );

        break;

      case 'level':
        this.filteredZones = this.zones.filter(
          x => x.levelId === selection.id
        );

        this.filteredRooms = this.rooms.filter(
          x => x.levelId === selection.id
        );
        break;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
