import * as moment from 'moment';

/* tslint:disable:max-line-length */
export const crypto = {
    btc      : {
        amount     : 8878.48,
        trend      : {
            dir   : 'up',
            amount: 0.17
        },
        marketCap  : 148752956966,
        volume     : 22903438381,
        supply     : 18168448,
        allTimeHigh: 19891.00,
        price      : {
            series: [
                {
                    name: 'Price',
                    data: [
                        {
                            x: -145,
                            y: 6554.36
                        },
                        {
                            x: -144,
                            y: 6554.36
                        },
                        {
                            x: -143,
                            y: 6546.94
                        },
                        {
                            x: -142,
                            y: 6546.96
                        },
                        {
                            x: -141,
                            y: 6546.11
                        },
                        {
                            x: -140,
                            y: 6550.26
                        },
                        {
                            x: -139,
                            y: 6546.11
                        },
                        {
                            x: -138,
                            y: 6550.79
                        },
                        {
                            x: -137,
                            y: 6545.36
                        },
                        {
                            x: -136,
                            y: 6541.06
                        },
                        {
                            x: -135,
                            y: 6540.10
                        },
                        {
                            x: -134,
                            y: 6538.31
                        },
                        {
                            x: -133,
                            y: 6538.42
                        },
                        {
                            x: -132,
                            y: 6538.48
                        },
                        {
                            x: -131,
                            y: 6538.71
                        },
                        {
                            x: -130,
                            y: 6548.42
                        },
                        {
                            x: -129,
                            y: 6546.87
                        },
                        {
                            x: -128,
                            y: 6547.07
                        },
                        {
                            x: -127,
                            y: 6535.07
                        },
                        {
                            x: -126,
                            y: 6535.01
                        },
                        {
                            x: -125,
                            y: 6539.02
                        },
                        {
                            x: -124,
                            y: 6547.96
                        },
                        {
                            x: -123,
                            y: 6547.92
                        },
                        {
                            x: -122,
                            y: 6546.56
                        },
                        {
                            x: -121,
                            y: 6546.56
                        },
                        {
                            x: -120,
                            y: 6564.16
                        },
                        {
                            x: -119,
                            y: 6560.83
                        },
                        {
                            x: -118,
                            y: 6559.08
                        },
                        {
                            x: -117,
                            y: 6553.02
                        },
                        {
                            x: -116,
                            y: 6564.99
                        },
                        {
                            x: -115,
                            y: 6558.70
                        },
                        {
                            x: -114,
                            y: 6568.73
                        },
                        {
                            x: -113,
                            y: 6568.80
                        },
                        {
                            x: -112,
                            y: 6568.80
                        },
                        {
                            x: -111,
                            y: 6568.80
                        },
                        {
                            x: -110,
                            y: 6571.83
                        },
                        {
                            x: -109,
                            y: 6562.64
                        },
                        {
                            x: -108,
                            y: 6561.28
                        },
                        {
                            x: -107,
                            y: 6561.28
                        },
                        {
                            x: -106,
                            y: 6560.40
                        },
                        {
                            x: -105,
                            y: 6564.41
                        },
                        {
                            x: -104,
                            y: 6562.44
                        },
                        {
                            x: -103,
                            y: 6565.13
                        },
                        {
                            x: -102,
                            y: 6553.30
                        },
                        {
                            x: -101,
                            y: 6552.68
                        },
                        {
                            x: -100,
                            y: 6551.92
                        },
                        {
                            x: -99,
                            y: 6553.85
                        },
                        {
                            x: -98,
                            y: 6560.00
                        },
                        {
                            x: -97,
                            y: 6560.00
                        },
                        {
                            x: -96,
                            y: 6565.01
                        },
                        {
                            x: -95,
                            y: 6583.19
                        },
                        {
                            x: -94,
                            y: 6555.79
                        },
                        {
                            x: -93,
                            y: 6556.04
                        },
                        {
                            x: -92,
                            y: 6558.85
                        },
                        {
                            x: -91,
                            y: 6564.75
                        },
                        {
                            x: -90,
                            y: 6564.88
                        },
                        {
                            x: -89,
                            y: 6565.10
                        },
                        {
                            x: -88,
                            y: 6565.72
                        },
                        {
                            x: -87,
                            y: 6565.72
                        },
                        {
                            x: -86,
                            y: 6565.95
                        },
                        {
                            x: -85,
                            y: 6561.82
                        },
                        {
                            x: -84,
                            y: 6566.26
                        },
                        {
                            x: -83,
                            y: 6568.81
                        },
                        {
                            x: -82,
                            y: 6588.57
                        },
                        {
                            x: -81,
                            y: 6587.11
                        },
                        {
                            x: -80,
                            y: 6577.86
                        },
                        {
                            x: -79,
                            y: 6586.51
                        },
                        {
                            x: -78,
                            y: 6581.14
                        },
                        {
                            x: -77,
                            y: 6581.45
                        },
                        {
                            x: -76,
                            y: 6589.54
                        },
                        {
                            x: -75,
                            y: 6580.91
                        },
                        {
                            x: -74,
                            y: 6581.67
                        },
                        {
                            x: -73,
                            y: 6579.06
                        },
                        {
                            x: -72,
                            y: 6578.73
                        },
                        {
                            x: -71,
                            y: 6578.64
                        },
                        {
                            x: -70,
                            y: 6579.08
                        },
                        {
                            x: -69,
                            y: 6577.43
                        },
                        {
                            x: -68,
                            y: 6582.12
                        },
                        {
                            x: -67,
                            y: 6572.42
                        },
                        {
                            x: -66,
                            y: 6578.72
                        },
                        {
                            x: -65,
                            y: 6572.43
                        },
                        {
                            x: -64,
                            y: 6570.64
                        },
                        {
                            x: -63,
                            y: 6561.64
                        },
                        {
                            x: -62,
                            y: 6550.84
                        },
                        {
                            x: -61,
                            y: 6561.83
                        },
                        {
                            x: -60,
                            y: 6561.84
                        },
                        {
                            x: -59,
                            y: 6552.44
                        },
                        {
                            x: -58,
                            y: 6552.47
                        },
                        {
                            x: -57,
                            y: 6562.31
                        },
                        {
                            x: -56,
                            y: 6562.10
                        },
                        {
                            x: -55,
                            y: 6561.65
                        },
                        {
                            x: -54,
                            y: 6547.96
                        },
                        {
                            x: -53,
                            y: 6559.95
                        },
                        {
                            x: -52,
                            y: 6562.08
                        },
                        {
                            x: -51,
                            y: 6557.71
                        },
                        {
                            x: -50,
                            y: 6559.05
                        },
                        {
                            x: -49,
                            y: 6562.69
                        },
                        {
                            x: -48,
                            y: 6578.18
                        },
                        {
                            x: -47,
                            y: 6580.15
                        },
                        {
                            x: -46,
                            y: 6584.26
                        },
                        {
                            x: -45,
                            y: 6574.75
                        },
                        {
                            x: -44,
                            y: 6574.85
                        },
                        {
                            x: -43,
                            y: 6582.63
                        },
                        {
                            x: -42,
                            y: 6569.70
                        },
                        {
                            x: -41,
                            y: 6570.10
                        },
                        {
                            x: -40,
                            y: 6570.11
                        },
                        {
                            x: -39,
                            y: 6569.71
                        },
                        {
                            x: -38,
                            y: 6578.03
                        },
                        {
                            x: -37,
                            y: 6579.92
                        },
                        {
                            x: -36,
                            y: 6571.03
                        },
                        {
                            x: -35,
                            y: 6571.48
                        },
                        {
                            x: -34,
                            y: 6576.67
                        },
                        {
                            x: -33,
                            y: 6576.67
                        },
                        {
                            x: -32,
                            y: 6576.63
                        },
                        {
                            x: -31,
                            y: 6576.68
                        },
                        {
                            x: -30,
                            y: 6573.29
                        },
                        {
                            x: -29,
                            y: 6577.28
                        },
                        {
                            x: -28,
                            y: 6577.73
                        },
                        {
                            x: -27,
                            y: 6577.70
                        },
                        {
                            x: -26,
                            y: 6578.36
                        },
                        {
                            x: -25,
                            y: 6578.24
                        },
                        {
                            x: -24,
                            y: 6581.30
                        },
                        {
                            x: -23,
                            y: 6582.59
                        },
                        {
                            x: -22,
                            y: 6602.51
                        },
                        {
                            x: -21,
                            y: 6582.65
                        },
                        {
                            x: -20,
                            y: 6574.77
                        },
                        {
                            x: -19,
                            y: 6574.41
                        },
                        {
                            x: -18,
                            y: 6575.08
                        },
                        {
                            x: -17,
                            y: 6575.08
                        },
                        {
                            x: -16,
                            y: 6574.09
                        },
                        {
                            x: -15,
                            y: 6568.84
                        },
                        {
                            x: -14,
                            y: 6567.49
                        },
                        {
                            x: -13,
                            y: 6559.75
                        },
                        {
                            x: -12,
                            y: 6566.65
                        },
                        {
                            x: -11,
                            y: 6567.52
                        },
                        {
                            x: -10,
                            y: 6567.59
                        },
                        {
                            x: -9,
                            y: 6564.18
                        },
                        {
                            x: -8,
                            y: 6570.11
                        },
                        {
                            x: -7,
                            y: 6562.70
                        },
                        {
                            x: -6,
                            y: 6562.70
                        },
                        {
                            x: -5,
                            y: 6562.77
                        },
                        {
                            x: -4,
                            y: 6569.46
                        },
                        {
                            x: -3,
                            y: 6571.04
                        },
                        {
                            x: -2,
                            y: 6571.48
                        },
                        {
                            x: -1,
                            y: 6571.30
                        }
                    ]
                }
            ]
        }
    },
    prices   : {
        btc: 8878.48,
        eth: 170.46,
        bch: 359.93,
        xrp: 0.23512
    },
    wallets  : {
        btc: 24.97311243,
        eth: 126.3212,
        bch: 78.454412,
        xrp: 11278.771123
    },
    watchlist: [
        {
            title : 'Ethereum',
            iso   : 'ETH',
            amount: 170.46,
            trend : {
                dir   : 'up',
                amount: 2.35
            },
            series: [
                {
                    name: 'Price',
                    data: [
                        {
                            x: moment().subtract(20, 'minutes').format('HH:mm'),
                            y: 154.36
                        },
                        {
                            x: moment().subtract(19, 'minutes').format('HH:mm'),
                            y: 154.36
                        },
                        {
                            x: moment().subtract(18, 'minutes').format('HH:mm'),
                            y: 146.94
                        },
                        {
                            x: moment().subtract(17, 'minutes').format('HH:mm'),
                            y: 146.96
                        },
                        {
                            x: moment().subtract(16, 'minutes').format('HH:mm'),
                            y: 146.11
                        },
                        {
                            x: moment().subtract(15, 'minutes').format('HH:mm'),
                            y: 150.26
                        },
                        {
                            x: moment().subtract(14, 'minutes').format('HH:mm'),
                            y: 146.11
                        },
                        {
                            x: moment().subtract(13, 'minutes').format('HH:mm'),
                            y: 150.79
                        },
                        {
                            x: moment().subtract(12, 'minutes').format('HH:mm'),
                            y: 145.36
                        },
                        {
                            x: moment().subtract(11, 'minutes').format('HH:mm'),
                            y: 141.06
                        },
                        {
                            x: moment().subtract(10, 'minutes').format('HH:mm'),
                            y: 140.10
                        },
                        {
                            x: moment().subtract(9, 'minutes').format('HH:mm'),
                            y: 138.31
                        },
                        {
                            x: moment().subtract(8, 'minutes').format('HH:mm'),
                            y: 138.42
                        },
                        {
                            x: moment().subtract(7, 'minutes').format('HH:mm'),
                            y: 138.48
                        },
                        {
                            x: moment().subtract(6, 'minutes').format('HH:mm'),
                            y: 138.71
                        },
                        {
                            x: moment().subtract(5, 'minutes').format('HH:mm'),
                            y: 148.42
                        },
                        {
                            x: moment().subtract(4, 'minutes').format('HH:mm'),
                            y: 146.87
                        },
                        {
                            x: moment().subtract(3, 'minutes').format('HH:mm'),
                            y: 147.07
                        },
                        {
                            x: moment().subtract(2, 'minutes').format('HH:mm'),
                            y: 135.07
                        },
                        {
                            x: moment().subtract(1, 'minutes').format('HH:mm'),
                            y: 135.01
                        }
                    ]
                }
            ]
        },
        {
            title : 'Bitcoin Cash',
            iso   : 'BCH',
            amount: 359.93,
            trend : {
                dir   : 'up',
                amount: 9.94
            },
            series: [
                {
                    name: 'Price',
                    data: [
                        {
                            x: moment().subtract(20, 'minutes').format('HH:mm'),
                            y: 374.77
                        },
                        {
                            x: moment().subtract(19, 'minutes').format('HH:mm'),
                            y: 374.41
                        },
                        {
                            x: moment().subtract(18, 'minutes').format('HH:mm'),
                            y: 375.08
                        },
                        {
                            x: moment().subtract(17, 'minutes').format('HH:mm'),
                            y: 375.08
                        },
                        {
                            x: moment().subtract(16, 'minutes').format('HH:mm'),
                            y: 374.09
                        },
                        {
                            x: moment().subtract(15, 'minutes').format('HH:mm'),
                            y: 368.84
                        },
                        {
                            x: moment().subtract(14, 'minutes').format('HH:mm'),
                            y: 367.49
                        },
                        {
                            x: moment().subtract(13, 'minutes').format('HH:mm'),
                            y: 359.75
                        },
                        {
                            x: moment().subtract(12, 'minutes').format('HH:mm'),
                            y: 366.65
                        },
                        {
                            x: moment().subtract(11, 'minutes').format('HH:mm'),
                            y: 367.52
                        },
                        {
                            x: moment().subtract(10, 'minutes').format('HH:mm'),
                            y: 367.59
                        },
                        {
                            x: moment().subtract(9, 'minutes').format('HH:mm'),
                            y: 364.18
                        },
                        {
                            x: moment().subtract(8, 'minutes').format('HH:mm'),
                            y: 370.11
                        },
                        {
                            x: moment().subtract(7, 'minutes').format('HH:mm'),
                            y: 362.70
                        },
                        {
                            x: moment().subtract(6, 'minutes').format('HH:mm'),
                            y: 362.70
                        },
                        {
                            x: moment().subtract(5, 'minutes').format('HH:mm'),
                            y: 362.77
                        },
                        {
                            x: moment().subtract(4, 'minutes').format('HH:mm'),
                            y: 369.46
                        },
                        {
                            x: moment().subtract(3, 'minutes').format('HH:mm'),
                            y: 371.04
                        },
                        {
                            x: moment().subtract(2, 'minutes').format('HH:mm'),
                            y: 371.48
                        },
                        {
                            x: moment().subtract(1, 'minutes').format('HH:mm'),
                            y: 371.30
                        }
                    ]
                }
            ]
        },
        {
            title : 'XRP',
            iso   : 'XRP',
            amount: 0.23512,
            trend : {
                dir   : 'down',
                amount: 0.35
            },
            series: [
                {
                    name: 'Price',
                    data: [
                        {
                            x: moment().subtract(20, 'minutes').format('HH:mm'),
                            y: 0.258
                        },
                        {
                            x: moment().subtract(19, 'minutes').format('HH:mm'),
                            y: 0.256
                        },
                        {
                            x: moment().subtract(18, 'minutes').format('HH:mm'),
                            y: 0.255
                        },
                        {
                            x: moment().subtract(17, 'minutes').format('HH:mm'),
                            y: 0.255
                        },
                        {
                            x: moment().subtract(16, 'minutes').format('HH:mm'),
                            y: 0.254
                        },
                        {
                            x: moment().subtract(15, 'minutes').format('HH:mm'),
                            y: 0.248
                        },
                        {
                            x: moment().subtract(14, 'minutes').format('HH:mm'),
                            y: 0.247
                        },
                        {
                            x: moment().subtract(13, 'minutes').format('HH:mm'),
                            y: 0.249
                        },
                        {
                            x: moment().subtract(12, 'minutes').format('HH:mm'),
                            y: 0.246
                        },
                        {
                            x: moment().subtract(11, 'minutes').format('HH:mm'),
                            y: 0.247
                        },
                        {
                            x: moment().subtract(10, 'minutes').format('HH:mm'),
                            y: 0.247
                        },
                        {
                            x: moment().subtract(9, 'minutes').format('HH:mm'),
                            y: 0.244
                        },
                        {
                            x: moment().subtract(8, 'minutes').format('HH:mm'),
                            y: 0.250
                        },
                        {
                            x: moment().subtract(7, 'minutes').format('HH:mm'),
                            y: 0.242
                        },
                        {
                            x: moment().subtract(6, 'minutes').format('HH:mm'),
                            y: 0.251
                        },
                        {
                            x: moment().subtract(5, 'minutes').format('HH:mm'),
                            y: 0.251
                        },
                        {
                            x: moment().subtract(4, 'minutes').format('HH:mm'),
                            y: 0.251
                        },
                        {
                            x: moment().subtract(3, 'minutes').format('HH:mm'),
                            y: 0.249
                        },
                        {
                            x: moment().subtract(2, 'minutes').format('HH:mm'),
                            y: 0.242
                        },
                        {
                            x: moment().subtract(1, 'minutes').format('HH:mm'),
                            y: 0.240
                        }
                    ]
                }
            ]
        },
        {
            title : 'Litecoin',
            iso   : 'LTC',
            amount: 60.15,
            trend : {
                dir   : 'up',
                amount: 0.99
            },
            series: [
                {
                    name: 'Price',
                    data: [
                        {
                            x: moment().subtract(20, 'minutes').format('HH:mm'),
                            y: 62.54
                        },
                        {
                            x: moment().subtract(19, 'minutes').format('HH:mm'),
                            y: 61.54
                        },
                        {
                            x: moment().subtract(18, 'minutes').format('HH:mm'),
                            y: 62.55
                        },
                        {
                            x: moment().subtract(17, 'minutes').format('HH:mm'),
                            y: 60.55
                        },
                        {
                            x: moment().subtract(16, 'minutes').format('HH:mm'),
                            y: 59.54
                        },
                        {
                            x: moment().subtract(15, 'minutes').format('HH:mm'),
                            y: 58.48
                        },
                        {
                            x: moment().subtract(14, 'minutes').format('HH:mm'),
                            y: 54.47
                        },
                        {
                            x: moment().subtract(13, 'minutes').format('HH:mm'),
                            y: 51.49
                        },
                        {
                            x: moment().subtract(12, 'minutes').format('HH:mm'),
                            y: 51.46
                        },
                        {
                            x: moment().subtract(11, 'minutes').format('HH:mm'),
                            y: 53.47
                        },
                        {
                            x: moment().subtract(10, 'minutes').format('HH:mm'),
                            y: 52.47
                        },
                        {
                            x: moment().subtract(9, 'minutes').format('HH:mm'),
                            y: 54.44
                        },
                        {
                            x: moment().subtract(8, 'minutes').format('HH:mm'),
                            y: 59.50
                        },
                        {
                            x: moment().subtract(7, 'minutes').format('HH:mm'),
                            y: 62.42
                        },
                        {
                            x: moment().subtract(6, 'minutes').format('HH:mm'),
                            y: 61.42
                        },
                        {
                            x: moment().subtract(5, 'minutes').format('HH:mm'),
                            y: 60.42
                        },
                        {
                            x: moment().subtract(4, 'minutes').format('HH:mm'),
                            y: 58.49
                        },
                        {
                            x: moment().subtract(3, 'minutes').format('HH:mm'),
                            y: 57.51
                        },
                        {
                            x: moment().subtract(2, 'minutes').format('HH:mm'),
                            y: 54.51
                        },
                        {
                            x: moment().subtract(1, 'minutes').format('HH:mm'),
                            y: 51.25
                        }
                    ]
                }
            ]
        },
        {
            title : 'Zcash',
            iso   : 'ZEC',
            amount: 58.41,
            trend : {
                dir   : 'down',
                amount: 8.79
            },
            series: [
                {
                    name: 'Price',
                    data: [
                        {
                            x: moment().subtract(20, 'minutes').format('HH:mm'),
                            y: 53.54
                        },
                        {
                            x: moment().subtract(19, 'minutes').format('HH:mm'),
                            y: 52.54
                        },
                        {
                            x: moment().subtract(18, 'minutes').format('HH:mm'),
                            y: 52.55
                        },
                        {
                            x: moment().subtract(17, 'minutes').format('HH:mm'),
                            y: 46.44
                        },
                        {
                            x: moment().subtract(16, 'minutes').format('HH:mm'),
                            y: 49.50
                        },
                        {
                            x: moment().subtract(15, 'minutes').format('HH:mm'),
                            y: 55.42
                        },
                        {
                            x: moment().subtract(14, 'minutes').format('HH:mm'),
                            y: 54.42
                        },
                        {
                            x: moment().subtract(13, 'minutes').format('HH:mm'),
                            y: 43.49
                        },
                        {
                            x: moment().subtract(12, 'minutes').format('HH:mm'),
                            y: 43.46
                        },
                        {
                            x: moment().subtract(11, 'minutes').format('HH:mm'),
                            y: 41.47
                        },
                        {
                            x: moment().subtract(10, 'minutes').format('HH:mm'),
                            y: 41.47
                        },
                        {
                            x: moment().subtract(9, 'minutes').format('HH:mm'),
                            y: 51.55
                        },
                        {
                            x: moment().subtract(8, 'minutes').format('HH:mm'),
                            y: 48.54
                        },
                        {
                            x: moment().subtract(7, 'minutes').format('HH:mm'),
                            y: 49.48
                        },
                        {
                            x: moment().subtract(6, 'minutes').format('HH:mm'),
                            y: 45.47
                        },
                        {
                            x: moment().subtract(5, 'minutes').format('HH:mm'),
                            y: 51.42
                        },
                        {
                            x: moment().subtract(4, 'minutes').format('HH:mm'),
                            y: 49.49
                        },
                        {
                            x: moment().subtract(3, 'minutes').format('HH:mm'),
                            y: 46.51
                        },
                        {
                            x: moment().subtract(2, 'minutes').format('HH:mm'),
                            y: 41.51
                        },
                        {
                            x: moment().subtract(1, 'minutes').format('HH:mm'),
                            y: 44.25
                        }
                    ]
                }
            ]
        },
        {
            title : 'Bitcoin Gold',
            iso   : 'BTG',
            amount: 12.23,
            trend : {
                dir   : 'down',
                amount: 4.42
            },
            series: [
                {
                    name: 'Price',
                    data: [
                        {
                            x: moment().subtract(20, 'minutes').format('HH:mm'),
                            y: 14.77
                        },
                        {
                            x: moment().subtract(19, 'minutes').format('HH:mm'),
                            y: 14.41
                        },
                        {
                            x: moment().subtract(18, 'minutes').format('HH:mm'),
                            y: 15.08
                        },
                        {
                            x: moment().subtract(17, 'minutes').format('HH:mm'),
                            y: 15.08
                        },
                        {
                            x: moment().subtract(16, 'minutes').format('HH:mm'),
                            y: 14.09
                        },
                        {
                            x: moment().subtract(15, 'minutes').format('HH:mm'),
                            y: 18.84
                        },
                        {
                            x: moment().subtract(14, 'minutes').format('HH:mm'),
                            y: 17.49
                        },
                        {
                            x: moment().subtract(13, 'minutes').format('HH:mm'),
                            y: 19.75
                        },
                        {
                            x: moment().subtract(12, 'minutes').format('HH:mm'),
                            y: 16.65
                        },
                        {
                            x: moment().subtract(11, 'minutes').format('HH:mm'),
                            y: 17.52
                        },
                        {
                            x: moment().subtract(10, 'minutes').format('HH:mm'),
                            y: 17.59
                        },
                        {
                            x: moment().subtract(9, 'minutes').format('HH:mm'),
                            y: 14.18
                        },
                        {
                            x: moment().subtract(8, 'minutes').format('HH:mm'),
                            y: 10.11
                        },
                        {
                            x: moment().subtract(7, 'minutes').format('HH:mm'),
                            y: 12.70
                        },
                        {
                            x: moment().subtract(6, 'minutes').format('HH:mm'),
                            y: 12.70
                        },
                        {
                            x: moment().subtract(5, 'minutes').format('HH:mm'),
                            y: 12.77
                        },
                        {
                            x: moment().subtract(4, 'minutes').format('HH:mm'),
                            y: 19.46
                        },
                        {
                            x: moment().subtract(3, 'minutes').format('HH:mm'),
                            y: 11.04
                        },
                        {
                            x: moment().subtract(2, 'minutes').format('HH:mm'),
                            y: 11.48
                        },
                        {
                            x: moment().subtract(1, 'minutes').format('HH:mm'),
                            y: 11.30
                        }
                    ]
                }
            ]
        }
    ]
};

