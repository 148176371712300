import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations';
import { Store } from '@ngrx/store';
import { Project, User } from 'app/core/interfaces';
import { Job } from 'app/core/interfaces/job';
import { AccessManagement, Setting } from 'app/core/interfaces/roles';
import { JobService } from 'app/core/services/job.service';
import { JobODataService } from 'app/core/services/odata/job-odata.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import {
  selectUser,
  selectUserSettings,
} from 'app/core/store/selectors/auth.selectors';
import { selectIsMobileView, selectProject } from 'app/core/store/selectors/common.selectors';
import { getDialogInfo } from 'app/core/utils/dialog.utils';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  calculateDuration,
  formatTime,
} from '../../../core/utils/date-time.utils';
import {
  SNACKBAR_ACTION_ERROR,
  SNACKBAR_ACTION_SUCCESSFUL,
  getAssetTypeIcon,
  getColorClassByJobStatus,
  getHistory,
  getJobTitle,
  showJobStatusSnackbar,
  showSnackbar,
} from '../../../core/utils/utils';
import { JobConfirmDialogComponent } from '../job-confirm-dialog/job-confirm-dialog.component';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss'],
  animations: fuseAnimations,
})

export class JobCardComponent implements OnInit, OnDestroy {
  @Input() job: Job;
  @Input() isManageCrane = false;
  @Input() isManageGate = false;
  @Input() isReadOnly = false;
  @Input() relatedJobs: Job[] = [];
  @Input() jobDisplay: string = '';
  @Output() getSelectedJob = new EventEmitter<Job>();
  @Output() getSelectedCraneJob = new EventEmitter<Job>();

  assetIcon = 'assets/images/crane_images/deliverytime.crane_icon.svg';
  jobTitle: string = '';
  statusClass: string = '';
  userAccess: AccessManagement;
  user: User;
  dialogRef: any;
  project: Project;
  showRelatedJobs: Job[] = [];
  jobCounter: number = 0;
  relatedJobCss = 'card-job custom-job';
  isMobileView: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<AppState>,
    private jobODataSvc: JobODataService,
    private jobService: JobService,
    private _snackBar: MatSnackBar,
    private _matDialog: MatDialog,
  ) {
    this.store.select(selectUser).pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
      this.user = user;
    });

    this.store
      .select(selectUserSettings)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: AccessManagement) => {
        if (response) {
          this.userAccess = response;
        }
      });

    this.store
      .select(selectProject)
      .pipe(filter((project) => !!project && !!project.id && !!project.companyName), takeUntil(this._unsubscribeAll))
      .subscribe(project => {
        this.project = project;
      });

    this.store
      .select(selectIsMobileView)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isMobileView) => (this.isMobileView = isMobileView));
  }

  get duration(): string {
    return calculateDuration(
      this.job.requestedStartTime,
      this.job.requestedEndTime
    );
  }

  get startTime(): string {
    return formatTime(this.job.requestedStartTime);
  }

  get endTime(): string {
    return formatTime(this.job.requestedEndTime);
  }

  ngOnInit(): void {
    this.assetIcon = getAssetTypeIcon(this.job.assetType);
    this.statusClass = getColorClassByJobStatus(this.job.status);
    this.job.jobsHistory = this.job.jobsHistory ? this.job.jobsHistory : [];
    this.jobTitle = getJobTitle(this.job);
    this.showRelatedJobs = this.relatedJobs.filter(x => x.id !== this.job.id);
  }

  relatedAssetImage(rJob): string {
    return getAssetTypeIcon(rJob.assetType);
  }

  openLinkedJob(linkedJob: Job): void {
    this.getSelectedJob.emit(linkedJob);
  }

  jobToEdit(): void {
    this.getSelectedJob.emit(this.job);
  }

  jobToEditCrane(): void {
    this.getSelectedCraneJob.emit(this.job);
  }

  formatStartDate(date, showDuration = true): string {
    return showDuration
      ? moment(date).format('DD/MM/YY') + ' - ' + moment(date).fromNow()
      : moment(date).format('DD/MM/YY');
  }

  formatTime(date): string {
    return formatTime(date);
  }

  checkUserSettings(key: string): Setting[] {
    return this.userAccess?.settings.filter(x => x.name === key && x.isChecked);
  }

  cancelJob = (): void => {
    if (this.jobCounter > 1) {
      for (const job of this.relatedJobs) {
        job.status = 'cancelled';
        job.jobsHistory.push(getHistory('Job Cancelled', this.user));
        this.jobODataSvc
          .put(job.id, job)
          .subscribe(() => {
            showSnackbar(this._snackBar, `Related ${this.jobCounter} Jobs Cancelled`, SNACKBAR_ACTION_SUCCESSFUL);
            this.jobService.refreshJobs();
            this.jobService.refreshSchedulerJobs();
          });
      }
    } else {
      this.job.status = 'cancelled';
      this.job.jobsHistory.push(getHistory('Job Cancelled', this.user));
      this.jobODataSvc
        .put(this.job.id, this.job)
        .subscribe(() => {
          showJobStatusSnackbar(this._snackBar, 'Cancelled');
          this.jobService.refreshJobs();
          this.jobService.refreshSchedulerJobs();
        });
    }
  };

  approveJob = (): void => {
    if (this.jobCounter > 1) {
      for (const job of this.relatedJobs) {
        job.status = 'approved';
        job.jobsHistory.push(getHistory('Job Approved', this.user));
        this.jobODataSvc
          .put(job.id, job)
          .subscribe(() => {
            showSnackbar(this._snackBar, `Related ${this.jobCounter} Jobs approved successfully`, SNACKBAR_ACTION_SUCCESSFUL);
            this.jobService.refreshJobs();
            this.jobService.refreshSchedulerJobs();
          });
      }
    } else {
      this.job.status = 'approved';
      this.job.jobsHistory.push(getHistory('Job Approved', this.user));
      this.jobODataSvc
        .put(this.job.id, this.job)
        .subscribe(() => {
          showJobStatusSnackbar(this._snackBar, 'Approved');
          this.jobService.refreshJobs();
          this.jobService.refreshSchedulerJobs();
        });
    }
  };

  openConfirmDialog(action: string): void {
    if (action === 'approve') {
      this.jobODataSvc.checkApprovedJobsExists(this.job, this.project.id).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
        if (response.count > 0) {
          const jobs = response.value.filter(
            x =>
              x.requestedStartTime <= this.job.requestedEndTime &&
              x.requestedEndTime >= this.job.requestedStartTime
          );
          if (jobs.length === 0) {
            this.openDialogWithCallback(action);
          } else {
            const isDoubleBookingEnabled = this.project.appSettings.find(
              x => x.name === 'Double Booking' && x.isChecked
            );
            if (!isDoubleBookingEnabled) {
              this.showDuplicateJobError();
            } else {
              this.openDialogWithCallback(action);
            }
          }
        } else {
          this.openDialogWithCallback(action);
        }
      });
    } else {
      this.openDialogWithCallback(action);
    }
  }

  openDialogWithCallback = (action): void => {
    const dialogInfo = getDialogInfo(action);

    if (this.relatedJobs.length > 1) {
      dialogInfo['relatedJobs'] = true;
      dialogInfo['relatedJobsCount'] = this.relatedJobs.length;
    } else {
      dialogInfo['relatedJobs'] = false;
      dialogInfo['relatedJobsCount'] = 0;
    }

    this.dialogRef = this._matDialog.open(JobConfirmDialogComponent, {
      data: dialogInfo,
      width: '500px',
    });

    this.dialogRef.afterClosed().subscribe((response: string) => {
      if (!response) {
        return;
      }

      switch (response) {
        case 'allRelatedJobs':
          this.jobCounter = dialogInfo['relatedJobsCount'];
          break;

        default:
          this.jobCounter = 1;
      }

      if (action === 'approve') {
        if ((this.job.gateId === "" || this.job.gateId === null) && this.job.assetType === 'bay') {
          showSnackbar(this._snackBar, `Unable to approve this job, kindly provide Gate Information`, SNACKBAR_ACTION_ERROR);
        }
        else {
          this.approveJob();
        }
      } else {
        this.cancelJob();
      }
    });
  };

  showDuplicateJobError = (): void => {
    this.dialogRef = this._matDialog.open(JobConfirmDialogComponent, {
      data: getDialogInfo('error-duplicate-jobs'),
      width: '500px',
    });
  };

  addRemoveRelatedJobOverlay(isCheck: boolean): void {
    if (this.jobDisplay === 'list') {
      if (isCheck) {
        if (this.isMobileView) {
          this.statusClass = getColorClassByJobStatus(this.job.status) + ' showJobs showJobsInListViewMobile';
        }
        else {
          this.statusClass = getColorClassByJobStatus(this.job.status) + ' showJobs';
        }
      }
      else {
        this.statusClass = getColorClassByJobStatus(this.job.status);
      }
    }
    else {
      if (isCheck) {
        this.relatedJobCss = 'card-job custom-job showJobs';
      }
      else {
        this.relatedJobCss = 'card-job custom-job';
      }
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
