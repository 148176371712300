import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { ImportDataModule } from 'app/components/import-data/import-data.module';
import { LiveDashboardModule } from 'app/components/live-dashboard/live-dashboard.module';
import { ManageModule } from 'app/components/manage/manage.module';
import { PlanModule } from 'app/components/plan/plan.module';
import { ReportsModule } from 'app/components/reports/reports.module';
import { ScheduleModule } from 'app/components/schedule/schedule.module';
import { SettingsModule } from 'app/components/settings/settings.module';
import { LayoutComponent } from 'app/layout/layout.component';
import { AuthForgotPasswordModule } from 'app/modules/auth/forgot-password/forgot-password.module';
import { AuthResetPasswordModule } from 'app/modules/auth/reset-password/reset-password.module';
import { AuthSignInModule } from 'app/modules/auth/sign-in/sign-in.module';
import { MovementRequestModule } from './components/movement-request/movement-request.module';
import { PackagesModule } from './components/packages/packages.module';
import { ScanQrcodeModule } from './components/scan-qrcode/scan-qrcode.module';
import { ProjectListsModule } from './components/settings/project-list.module';
import { AuthGuard } from './core/guards/auth.guard';

export const appRoutes: Route[] = [
    { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'dense',
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'control',
                resolve: {
                    initialData: InitialDataResolver,
                },
                loadChildren: (): Promise<ManageModule> =>
                    import('app/components/manage/manage.module').then(
                        m => m.ManageModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'book-job',
                loadChildren: (): Promise<PlanModule> =>
                    import('app/components/plan/plan.module').then(m => m.PlanModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'jobs',
                loadChildren: (): Promise<ScheduleModule> =>
                    import('app/components/schedule/schedule.module').then(
                        m => m.ScheduleModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'live',
                loadChildren: (): Promise<LiveDashboardModule> =>
                    import('app/components/live-dashboard/live-dashboard.module').then(
                        m => m.LiveDashboardModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'import',
                loadChildren: (): Promise<ImportDataModule> =>
                    import('app/components/import-data/import-data.module').then(
                        m => m.ImportDataModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'reports',
                loadChildren: (): Promise<ReportsModule> =>
                    import('app/components/reports/reports.module').then(
                        m => m.ReportsModule
                    ),
                canActivate: [AuthGuard],
            },

            {
                path: 'settings',
                loadChildren: (): Promise<SettingsModule> =>
                    import('app/components/settings/settings.module').then(
                        m => m.SettingsModule
                    ),
            },

            {
                path: 'packages',
                loadChildren: (): Promise<PackagesModule> =>
                    import('app/components/packages/packages.module').then(
                        m => m.PackagesModule
                    ),
                canActivate: [AuthGuard],
            },

            {
                path: 'scan',
                loadChildren: (): Promise<ScanQrcodeModule> =>
                    import('app/components/scan-qrcode/scan-qrcode.module').then(
                        m => m.ScanQrcodeModule
                    ),
                canActivate: [AuthGuard],
            },

            {
                path: 'movement-request',
                loadChildren: (): Promise<MovementRequestModule> =>
                    import('app/components/movement-request/movement-request.module').then(
                        m => m.MovementRequestModule
                    ),
                canActivate: [AuthGuard],
            },

            {
                path: 'project-list',
                loadChildren: (): Promise<ProjectListsModule> =>
                    import('app/components/settings/project-list.module').then(
                        m => m.ProjectListsModule
                    ),
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: '',

        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'forgot-password',
                loadChildren: (): Promise<AuthForgotPasswordModule> =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then(m => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: (): Promise<AuthResetPasswordModule> =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then(m => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: (): Promise<AuthSignInModule> =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        m => m.AuthSignInModule
                    ),
            },
        ],
    },
    { path: '**', redirectTo: 'sign-in' },
];
