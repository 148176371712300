import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { SNACKBAR_ACTION_ERROR, showSnackbar } from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMsg =
          error.error instanceof ErrorEvent || error.error?.message
            ? error.error.message
            : error?.message &&
              error.message.indexOf('Http failure response for ') === -1
              ? error.message
              : '';

        if (errorMsg) {
          console.log(errorMsg);
          showSnackbar(this.snackBar, errorMsg, SNACKBAR_ACTION_ERROR);
        }

        return throwError(errorMsg);
      })
    );
  }
}
