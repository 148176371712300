import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IODataParams } from 'app/core/interfaces';
import { GridOperationsService } from 'app/core/services/grid-operations.service';
import { SetUserProjectsAction } from 'app/core/store/actions/auth.actions';
import { SelectProjectAction } from 'app/core/store/actions/common.actions';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectUserProjects } from 'app/core/store/selectors';
import { selectProject } from 'app/core/store/selectors/common.selectors';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BaseGridEditService extends BehaviorSubject<any[]> {
  private data: any[] = [];
  private originalData: any[] = [];
  private createdItems: any[] = [];
  private updatedItems: any[] = [];
  private deletedItems: any[] = [];
  projectId: string;

  protected get baseUrl(): string {
    return `${environment.apiBaseUrl}/odata/${this.tableName}`;
  }

  constructor(
    protected http: HttpClient,
    private tableName: string,
    protected store: Store<AppState>,
    protected gridOperationsService: GridOperationsService
  ) {
    super([]);
    this.store.select(selectProject)
      .pipe(filter(project => !!project && !!project.id))
      .subscribe((project) => {
        this.projectId = project.id;
      });
  }

  public read(params?: IODataParams) {
    this.fetch(params).subscribe((data: any) => {
      this.data = data.value;
      this.originalData = _.cloneDeep(this.data);
      super.next(this.data);
    });
  }

  public create(item: any): void {
    const body = { ...item, isDeleted: false, projectId: this.projectId };

    let params = null;
    if (this.tableName === "projects") {
      params = {
        filter: `(name eq '${encodeURIComponent(item.name.replace(/'/g, "''"))}' or code eq '${encodeURIComponent(item.code.replace(/'/g, "''"))}') and isDeleted ne true ${this.getDuplicationVerificationFilter(item)}`,
      };
    } else {
      params = {
        filter: `name eq '${encodeURIComponent(item.name.replace(/'/g, "''"))}' and projectId eq '${this.projectId}' ` +
          `and isDeleted ne true ${this.getDuplicationVerificationFilter(item)}`,
      };
    }

    this.checkItemExists(params).subscribe((response) => {
      if (response.value.length > 0) {
        this.gridOperationsService.checkDuplicateUpdate(true);
        return false;
      } else {
        this.post(body).subscribe((res) => {
          this.createdItems.push(res);
          if (this.tableName === "projects") {
            this.updateProjectNameInDropdown(res, true);
          }

          this.gridOperationsService.refreshGridUpdate(this.tableName);
        });
      }
    });
  }

  public update(item: any): void {
    if (!this.isNew(item)) {
      // const body = { ...item, isDeleted: false, projectId: this.projectId };

      let params = null;
      if (this.tableName === "projects") {
        params = {
          filter: `(name eq '${encodeURIComponent(item.name.replace(/'/g, "''"))}' or code eq '${encodeURIComponent(item.code.replace(/'/g, "''"))}') and isDeleted ne true and id ne '${item.id}' ${this.getDuplicationVerificationFilter(item)}`,
        };
      } else {
        params = {
          filter: `name eq '${encodeURIComponent(item.name.replace(/'/g, "''"))}' and projectId eq '${this.projectId}' ` +
            `and isDeleted ne true and id ne '${item.id}' ${this.getDuplicationVerificationFilter(item)}`,
        };
      }

      this.checkItemExists(params).subscribe((response) => {
        if (response.value.length > 0) {
          const obj = response.value[0];
          if (obj.id !== item.id) {
            this.gridOperationsService.checkDuplicateUpdate(true);
            return false;
          } else {
            this.updateItem(item);
          }
        } else {
          this.updateItem(item);
        }
      });
    } else {
      _.remove(this.createdItems, (x) => x.id == item.id);
    }
  }

  private updateItem = (item: any): void => {
    this.put(item.id, item).subscribe(() => {
      const obj = this.updatedItems.find((x) => x.id == item.id);
      if (obj) {
        _.remove(this.updatedItems, obj);
      } else {
        this.updatedItems.push(item);
      }

      if (this.tableName === "projects") {
        this.updateProjectNameInDropdown(item, false);
      }

      this.gridOperationsService.refreshGridUpdate(this.tableName);
    });
  };

  public remove(item: any): void {
    const obj = _.remove(this.data, (x) => x.id == item.id);

    if (obj) {
      _.remove(this.createdItems, (x) => x.id == item.id);
      const body = { ...item, isDeleted: true };
      this.put(item.id, body).subscribe(() => {
        this.gridOperationsService.refreshGridUpdate(this.tableName);
      });
    } else {
      this.deletedItems.push(item);
    }

    _.remove(this.updatedItems, (x) => x.id == item.id);
    this.gridOperationsService.refreshGridUpdate(this.tableName);
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public hasChanges(): boolean {
    return Boolean(
      this.deletedItems.length ||
      this.updatedItems.length ||
      this.createdItems.length
    );
  }

  public saveChanges(): void {
    if (!this.hasChanges()) {
      return;
    }

    const completed = [];
    if (
      this.deletedItems.length ||
      this.updatedItems.length ||
      this.createdItems.length
    ) {
      completed.push(this.fetch());
    }

    this.reset();
  }

  public cancelChanges(): void {
    this.reset();

    this.data = this.originalData;
    this.originalData = _.cloneDeep(this.originalData);
    super.next(this.data);
  }

  public assignValues(target: any, source: any): void {
    Object.assign(target, source);
  }

  protected getDuplicationVerificationFilter(item: any) {
    return item.id === 0 ? '' : '';
  }

  private reset() {
    this.data = [];
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
  }

  private fetch(params?: IODataParams): Observable<any[]> {
    let filter = ``;
    if (this.tableName === "projects") {
      filter = ` isDeleted ne true `;
    } else {
      filter = ` (projectId eq '${this.projectId}') and isDeleted ne true `;
    }

    let queryString = '';
    if (params?.filter) {
      params.filter = filter + ' and ' + params.filter;
      queryString = `$filter=${params?.filter}`;
    }

    return this.http.get<any[]>(`${this.baseUrl}?${queryString}`);
  }

  post(body: any): Observable<any> {
    return this.http.post(`${this.baseUrl}`, body);
  }

  put(id: string, body: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}`, body);
  }

  // patch(id: string, body: Operation[]): Observable<any> {
  //   return this.http.patch(`${this.baseUrl}/${id}`, body);
  // }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  checkItemExists = (params): Observable<any> => {
    return this.fetch(params);
  };

  private updateProjectNameInDropdown(item: any, isProjectNew: boolean): void {
    const userAllProjects = this.store.select(selectUserProjects);
    const userCurrentProject = this.store.select(selectProject);

    combineLatest([userAllProjects, userCurrentProject])
      .pipe(
        take(1),
        filter(data => !!data && !!data[0] && !!data[1] && !!data[1].id && !!data[1].companyName))
      .subscribe((data) => {
        const allUserProjects = [...data[0]];
        const currentProject = data[1];

        if (isProjectNew) {
          const updatedProjects = [...allUserProjects, { id: item.id, name: item.name }];
          this.store.dispatch(new SetUserProjectsAction(updatedProjects));
        } else {
          if (currentProject.id === item.id) {
            const updateCurrentProject = { ...currentProject, name: item.name };
            this.store.dispatch(new SelectProjectAction(updateCurrentProject));
          }

          const projectIndex = allUserProjects.findIndex((p) => p.id === item.id);
          if (projectIndex !== -1) {
            const updatedProjects = [...allUserProjects];
            updatedProjects[projectIndex] = { ...updatedProjects[projectIndex], name: item.name };
            this.store.dispatch(new SetUserProjectsAction(updatedProjects));
          }
        }
      });
  }
}
