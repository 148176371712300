import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { User } from '../interfaces';
import { AuthGuardService } from '../services/auth-guard.service';
import { AppState } from '../store/reducers/app.reducer';
import { selectUser } from '../store/selectors/auth.selectors';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    user: User;
    constructor(
        private store: Store<AppState>,
        private authGuardService: AuthGuardService,
        private router: Router
    ) {
        this.store
            .select(selectUser)
            .pipe(filter(x => !!x))
            .subscribe((user) => {
                this.user = user;
            });
    }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        const canActivate = await this.authGuardService.isRouteAllowed(state.url);

        if (!canActivate) {
            this.router.navigate(['/sign-in'], {
                queryParams: { returnUrl: state.url },
            });
        }

        return canActivate;
    }
}
