import {
    Component,
    Input, ViewEncapsulation
} from '@angular/core';
import { UploadFile } from 'app/core/interfaces/upload-file';
import SwiperCore, {
    A11y, Grid, Navigation,
    Pagination,
    Scrollbar, SwiperOptions
} from 'swiper';

SwiperCore.use([Grid, Navigation, Pagination, Scrollbar, A11y]);

@Component({
    selector: 'app-image-list',
    templateUrl: './image-list.component.html',
    styleUrls: ['./image-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ImageListComponent {
    @Input() images: UploadFile[] = [];
    @Input() type: string = '';

    loginConfig: SwiperOptions = {
        slidesPerView: 3,
        spaceBetween: 50,
        // centeredSlides: true,
        // loop: true,
        navigation: {
            nextEl: '.swiper-button-next.nextb',
            prevEl: '.swiper-button-prev.nexta',
        },
        pagination: {
            el: '.swiper-pagination.pagination',
            clickable: true,
        },
        scrollbar: false,

        // breakpoints: {
        //     992: {
        //         slidesPerView: 3,
        //     },
        //     768: {
        //         slidesPerView: 2.3,
        //     },
        //     576: {
        //         slidesPerView: 1.3,
        //     },
        //     300: {
        //         slidesPerView: 1,
        //         spaceBetween: 0,
        //     },
        // },
    };

    logoutConfig: SwiperOptions = {
        slidesPerView: 3,
        spaceBetween: 50,
        // centeredSlides: true,
        // loop: true,
        navigation: {
            nextEl: '.swiper-button-next.next2',
            prevEl: '.swiper-button-prev.next1',
        },
        pagination: {
            el: '.swiper-pagination.pagination1',
            clickable: true,
        },
        scrollbar: false,

        // breakpoints: {
        //     992: {
        //         slidesPerView: 3,
        //     },
        //     768: {
        //         slidesPerView: 2.3,
        //     },
        //     576: {
        //         slidesPerView: 1.3,
        //     },
        //     300: {
        //         slidesPerView: 1,
        //         spaceBetween: 0,
        //     },
        // },
    };
}
