import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Job, LinkedJobsId } from 'app/core/interfaces';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { BaseODataService } from './base-odata.service';

@Injectable({
  providedIn: 'root',
})

export class JobODataService extends BaseODataService<Job> {
  constructor(protected http: HttpClient) {
    super(http, 'jobs');
  }

  checkApprovedJobsExists = (job: Job, projectId: string) => {
    let dateParams = ``;

    if (job.requestedStartTime && job.requestedEndTime) {
      dateParams = ` ( requestedStartTime lt '${moment(job.requestedStartTime)
        .utc()
        .format()}' and requestedEndTime gt '${moment(job.requestedStartTime)
          .utc()
          .format()}' )`;

      dateParams += ` or ( requestedStartTime lt '${moment(job.requestedEndTime)
        .utc()
        .format()}' and requestedEndTime gt '${moment(job.requestedEndTime)
          .utc()
          .format()}' )`;

      dateParams += ` or ( requestedStartTime eq '${moment(job.requestedStartTime)
        .utc()
        .format()}' and requestedEndTime eq '${moment(job.requestedEndTime)
          .utc()
          .format()}' )`;

      dateParams += ` or ( requestedStartTime ge '${moment(job.requestedStartTime)
        .utc()
        .format()}' and requestedEndTime le '${moment(job.requestedEndTime)
          .utc()
          .format()}' ) `;
    }

    const params = {
      filter: `(projectId eq '${projectId}')
        and (assetId eq '${job.assetId}')
        and (id ne '${job.id}' )
        and (isDeleted ne true)
        and (status eq 'approved')
        and ( ${dateParams} )`,
    };

    return this.get(params);
  };

  bulkUpdateGateInfolinkedJobs(jobId: LinkedJobsId): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/update-gateInfo-linked-jobs`, jobId);
  }
}
