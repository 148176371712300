export interface Export_Columns {
  header: string;
  key: string;
  width: number;
}

export const EXPORT_COLUMNS_MOBILE_CRANES: Export_Columns[] = [
  { header: 'Name', key: 'name', width: 50 },
  { header: 'Make', key: 'make', width: 30 },
  { header: 'Model', key: 'model', width: 30 },
  { header: 'Maximum Length', key: 'maximumLength', width: 25 },
  { header: 'Is Active', key: 'isActive', width: 15 },
];

export const EXPORT_COLUMNS_TOWER_CRANES: Export_Columns[] = [
  { header: 'Name', key: 'name', width: 50 },
  { header: 'Make', key: 'make', width: 20 },
  { header: 'Model', key: 'model', width: 20 },
  { header: 'Height Under Hook (meter)', key: 'heightUnderHook', width: 35 },
  { header: 'Freestanding Height (meter)', key: 'freestandingHeight', width: 35 },
  { header: 'Boom Length (meter)', key: 'boomLength', width: 25 },
  { header: 'Lifting Capacity (kg)', key: 'liftingCapacity', width: 25 },
  { header: 'Sectors', key: 'sectors', width: 15 },
  { header: 'Is Active', key: 'isActive', width: 15 },
];

export const EXPORT_COLUMNS_HOISTS: Export_Columns[] = [
  { header: 'Name', key: 'name', width: 50 },
  { header: 'CC Width', key: 'carClearWidth', width: 15 },
  { header: 'CC Depth', key: 'carClearDepth', width: 15 },
  { header: 'CC Height', key: 'carClearHeight', width: 15 },
  { header: 'Door Width', key: 'doorOpeningWidth', width: 15 },
  { header: 'Door Height', key: 'doorOpeningHeight', width: 15 },
  { header: 'Weight', key: 'weightLoad', width: 15 },
  { header: 'Is Active', key: 'isActive', width: 15 },
];

export const EXPORT_COLUMNS_HOT_SEATS: Export_Columns[] = [
  { header: 'Name', key: 'name', width: 50 },
  { header: 'Is Active', key: 'isActive', width: 15 },
];

export const EXPORT_COLUMNS_BAYS: Export_Columns[] = [
  { header: 'Name', key: 'name', width: 50 },
  { header: 'Maximum Length (meter)', key: 'maximumLength', width: 30 },
  { header: 'Is Active', key: 'isActive', width: 15 },
];

export const EXPORT_COLUMNS_MATERIAL_HANDLINGS: Export_Columns[] = [
  { header: 'Name', key: 'name', width: 50 },
  { header: 'Is Active', key: 'isActive', width: 15 },
];

export const EXPORT_COLUMNS_GATES: Export_Columns[] = [
  { header: 'Name', key: 'name', width: 50 },
];

export const EXPORT_COLUMNS_TRUCK_TYPES: Export_Columns[] = [
  { header: 'Name', key: 'name', width: 50 },
];

export const EXPORT_COLUMNS_UNLOADING_METHODS: Export_Columns[] = [
  { header: 'Name', key: 'name', width: 50 },
];

export const EXPORT_COLUMNS_ASSET_DOWNTIMES_HISTORY: Export_Columns[] = [
  { header: 'Asset Name', key: 'name', width: 50 },
  { header: 'Reason', key: 'reason', width: 30 },
  { header: 'Recurrence', key: 'recurrence', width: 20 },
  { header: 'From', key: 'startDate', width: 30 },
  { header: 'To', key: 'endDate', width: 30 },
  { header: 'Note', key: 'note', width: 150 },
];

export const EXPORT_COLUMNS_ASSET_TIMEOFF_HISTORY: Export_Columns[] = [
  { header: 'Title Name', key: 'name', width: 50 },
  { header: 'Asset Name', key: 'assetName', width: 50 },
  { header: 'Start Time', key: 'startTime', width: 30 },
  { header: 'End Time', key: 'endTime', width: 30 },
];

export const EXPORT_COLUMNS_MATERIALS_ACTIVITIES: Export_Columns[] = [
  { header: 'Name', key: 'name', width: 100 },
];

export const EXPORT_COLUMNS_AREAS: Export_Columns[] = [
  { header: 'Area', key: 'name', width: 30 },
  { header: 'Assets', key: 'assets', width: 40 },
  { header: 'Storage Location', key: 'storageLocation', width: 20 },
];

export const EXPORT_COLUMNS_LEVELS: Export_Columns[] = [
  { header: 'Area', key: 'areaName', width: 50 },
  { header: 'Level', key: 'name', width: 30 },
];

export const EXPORT_COLUMNS_ZONES: Export_Columns[] = [
  { header: 'Level', key: 'levelName', width: 50 },
  { header: 'Zone', key: 'name', width: 30 },
];

export const EXPORT_COLUMNS_ROOMS: Export_Columns[] = [
  { header: 'Level', key: 'levelName', width: 50 },
  { header: 'Room', key: 'name', width: 30 },
];

export const EXPORT_COLUMNS_CONTRACTORS: Export_Columns[] = [
  { header: 'Contractor Name', key: 'name', width: 50 },
  { header: 'Color on Schedule', key: 'color', width: 30 },
  { header: 'Users', key: 'users', width: 50 },
  { header: 'Areas', key: 'areas', width: 50 },
  { header: 'Assets', key: 'assets', width: 50 },
  { header: 'Packages', key: 'packages', width: 50 },
  { header: 'Activities', key: 'activities', width: 50 },
];

export const EXPORT_COLUMNS_USERS: Export_Columns[] = [
  { header: 'Name', key: 'name', width: 50 },
  { header: 'Email', key: 'principalName', width: 50 },
  { header: 'Phone', key: 'contactNo', width: 30 },
  { header: 'Role', key: 'roleName', width: 40 },
];

export const EXPORT_COLUMNS_ROLES: Export_Columns[] = [
  { header: 'Name', key: 'name', width: 50 },
];

export const EXPORT_COLUMNS_PROJECTS_LIST: Export_Columns[] = [
  { header: 'Project Name', key: 'name', width: 70 },
  { header: 'Code', key: 'code', width: 40 },
  { header: 'Company Name', key: 'companyName', width: 40 },
  { header: 'Address', key: 'address', width: 80 },
  { header: 'Foot Plate Dimension', key: 'footPlateDimension', width: 30 },
  { header: 'Floors', key: 'floors', width: 15 },
  { header: 'Maximum Date', key: 'maxDate', width: 25 },
  { header: 'Maximum Number of Lifts', key: 'maxNoOfLifts', width: 35 },
  { header: 'Maximum Number of Trucks', key: 'maxNoOfTrucks', width: 35 },
  { header: 'Hot Seats', key: 'isHotSeats', width: 15 },
  { header: 'Bay Required', key: 'isBayRequired', width: 20 },
  { header: 'Tower Crane Required', key: 'isCraneRequired', width: 30 },
  { header: 'Hoist Required', key: 'isHoistRequired', width: 20 },
  { header: 'Mobile Crane Required', key: 'isMobileCraneRequired', width: 30 },
];

export const EXPORT_COLUMNS_MOVEMENT_REQUEST: Export_Columns[] = [
  { header: 'Package Name', key: 'package', width: 50 },
  { header: 'Requester Name', key: 'requesterName', width: 50 },
  { header: 'Duration (days)', key: 'storageDuration', width: 20 },
  { header: 'Storage Status', key: 'storageStatus', width: 20 },
  { header: 'Location', key: 'deliveryLocationName', width: 50 },
];

export const EXPORT_COLUMNS_JOBS: Export_Columns[] = [
  { header: 'Ref No', key: 'refNo', width: 50 },
  { header: 'Requester Name', key: 'requesterName', width: 50 },
  { header: 'Requester Email', key: 'requesterEmail', width: 50 },
  { header: 'Operator Name', key: 'operatorName', width: 50 },
  { header: 'Operator Email', key: 'operatorEmail', width: 50 },
  { header: 'Contractor Name', key: 'contractorName', width: 50 },
  { header: 'From Location Type', key: 'fromLocationType', width: 50 },
  { header: 'From Milestone Name', key: 'fromMilestoneName', width: 50 },
  { header: 'From Bay Name', key: 'fromBayName', width: 50 },
  { header: 'To Location Type', key: 'toLocationType', width: 50 },
  { header: 'To Milestone Name', key: 'milestoneName', width: 50 },
  { header: 'Level Name', key: 'levelName', width: 50 },
  { header: 'Zone Name', key: 'zoneName', width: 50 },
  { header: 'Room Name', key: 'roomName', width: 50 },
  { header: 'Asset Name', key: 'assetName', width: 50 },
  { header: 'Asset Type', key: 'assetType', width: 20 },
  { header: 'Tonnage', key: 'tonnage', width: 20 },
  { header: 'Tonnage Unit', key: 'tonnageUnit', width: 20 },
  { header: 'Trucks Count', key: 'trucks', width: 20 },
  { header: 'Truck Type Name', key: 'truckTypeName', width: 50 },
  { header: 'Lifts Count', key: 'lifts', width: 20 },
  { header: 'Requested Start Time', key: 'requestedStartTime', width: 50 },
  { header: 'Requested End Time', key: 'requestedEndTime', width: 50 },
  { header: 'Extension Reason', key: 'extensionReason', width: 50 },
  { header: 'Gate Name', key: 'gateName', width: 50 },
  { header: 'Status', key: 'status', width: 20 },
  { header: 'Created Date', key: 'createdDate', width: 50 },
  { header: 'Max Weight', key: 'maxWeight', width: 20 },
  { header: 'Size Dimensions', key: 'sizeDimensions', width: 20 },
  { header: 'Vertical Trips', key: 'verticalTrips', width: 20 },
  { header: 'Pickup', key: 'pickup', width: 20 },
  { header: 'DropOff', key: 'dropOff', width: 20 },
];
