; import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GridOperationsService {
  private refreshGridSubject = new BehaviorSubject<string>('');
  private updateGridSubject = new BehaviorSubject<any>(null);
  private checkDuplicateSubject = new BehaviorSubject<boolean>(false);



  public get refreshGrid() { return this.refreshGridSubject.asObservable() }

  public get updateGrid() { return this.updateGridSubject.asObservable() }
  public get checkDuplicate() { return this.checkDuplicateSubject.asObservable() }


  public refreshGridUpdate(value) {
    this.refreshGridSubject.next(value);
  }
  public updateGridUpdate(value) {
    this.updateGridSubject.next(value);
  }
  public checkDuplicateUpdate(value) {
    this.checkDuplicateSubject.next(value);
  }


  constructor() { }
}
