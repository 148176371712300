import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FuseDrawerService } from '@fuse/components/drawer';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Store } from '@ngrx/store';
import { LOCAL_STORAGE_KEYS } from 'app/core/constants/local-storage-keys';
import { AccessManagement, Setting } from 'app/core/interfaces/roles';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { CustomFuseDrawerData, CustomFuseDrawerPanelService } from 'app/core/services/data-services/custom-fusedrawer-panel.service';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectUserSettings } from 'app/core/store/selectors/auth.selectors';
import { checkUserSettingsPermission } from 'app/core/utils/utils';
import { environment } from 'environments/environment';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
    selector: 'dense-layout',
    templateUrl: './dense.component.html',
    styleUrls: ['./dense.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DenseLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    navigationAppearance: 'default' | 'dense' = 'dense';
    hideLowerToolbar = false;
    accessManagement: AccessManagement;
    activeAdminMenu: string;
    customFuseData: CustomFuseDrawerData = null;
    menuOptions = [
        {
            id: "manage-projects",
            displayName: "Manage Projects",
            url: "/project-list",
            icon: "heroicons_outline:view-list",
        },
    ];

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private store: Store<AppState>,
        private localStorage: LocalStorageService,
        private _customFuseDrawerPanelService: CustomFuseDrawerPanelService,
        private _fuseDrawerService: FuseDrawerService
    ) {
        this.store
            .select(selectUserSettings)
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter(x => !!x)
            )
            .subscribe((response: AccessManagement) => {
                if (response) {
                    this.accessManagement = response;
                }
            });

        this.restoreLeftMenuOpenState();

        this._customFuseDrawerPanelService.data
            .pipe(filter(x => !!x && !!x.type), takeUntil(this._unsubscribeAll))
            .subscribe(x => {
                this.customFuseData = x;
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
                this.navigation.default = this.navigation.default.filter(route => {
                    return checkUserSettingsPermission(route.id, this.accessManagement.settings).length !== 0;
                });

                if (!environment.tahadyRequirementsMovementRequest) {
                    this.navigation.default = this.navigation.default.filter(x => x.id !== 'manage-movement-request');
                }
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');

                if (this.isScreenSmall) {
                    this.navigationAppearance = 'dense';
                } else {
                    this.restoreLeftMenuOpenState();
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void {
        this.navigationAppearance = (this.navigationAppearance === 'default' ? 'dense' : 'default');
        if (!this.isScreenSmall) {
            this.localStorage.set(
                LOCAL_STORAGE_KEYS.LeftMenuOpenState,
                this.navigationAppearance
            );
        }
    }

    createRequest(): void {
        this._router.navigateByUrl('/book-job');
    }

    navigateTo(url: string, activeLinkId: string): void {
        this.activeAdminMenu = activeLinkId;
        this._router.navigateByUrl(url);
        this.closeSettingDrawer();
    }

    closeSettingDrawer(): void {
        const drawer = this._fuseDrawerService.getComponent('projectInfoDrawer');
        drawer.toggle();
    }

    restoreLeftMenuOpenState(): void {
        this.navigationAppearance = this.localStorage.get(
            LOCAL_STORAGE_KEYS.LeftMenuOpenState
        );
    }

    checkUserSettings = (key: string): Setting[] => {
        if (this.accessManagement?.settings) {
            return this.accessManagement.settings.filter(
                x => x.name === key && x.isChecked
            );
        }

        return [];
    };

    customDrawerOpenedChanged(value: boolean): void {
        if (!value) {
            this.customFuseData = null;
            this._customFuseDrawerPanelService.changeData(null);
        }
    }

    drawerOpened(value: boolean): void {
        if (!value) {
            const drawer = this._fuseDrawerService.getComponent('customDrawer');
            drawer.toggle();
        }
    }
}
