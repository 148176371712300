import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { JobsImportViewModel } from 'app/core/interfaces/jobs-import-view-model';
import { JobApiService } from 'app/core/services/custom-services/job-api.service';
import { JobService } from 'app/core/services/job.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectProject } from 'app/core/store/selectors/common.selectors';
import { SNACKBAR_ACTION_ERROR, SNACKBAR_ACTION_SUCCESSFUL, SNACKBAR_ACTION_WARNING, getAssetTypes, showSnackbar } from 'app/core/utils/utils';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-jobs-import',
  templateUrl: './jobs-import.component.html',
  styleUrls: ['./jobs-import.component.scss'],
})

export class JobsImportComponent implements OnInit, OnDestroy {
  worksheet: any;
  fileUploaded: File;
  fileJson: string;
  projectId: string;
  jsonData: any[] = [];
  jobs: any[] = [];
  showSubmit = false;
  isFileValid = false;
  fileExtensions: string[] = ['xlsx', 'xls'];
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    protected http: HttpClient,
    private store: Store<AppState>,
    private jobApiService: JobApiService,
    private jobService: JobService,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {
    this.store.select(selectProject)
      .pipe(filter(project => !!project && !!project.id), takeUntil(this._unsubscribeAll))
      .subscribe((project) => {
        this.projectId = project.id;
      });
  }

  ngOnInit(): void { }

  selectFile() {
    const fileInput = this.fileInput.nativeElement;
    fileInput.onchange = (event) => {
      const ext = event.target.files[0].name.split('.').pop();
      switch (this.fileExtensions.indexOf(ext) >= 0) {
        case true:
          this.convertToJson(event);
          break;

        case false:
          showSnackbar(this._snackBar, 'Invalid file format', SNACKBAR_ACTION_ERROR);
          break;
      }
    };
    fileInput.click();
  }

  convertToJson(e): void {
    this.showSubmit = true;
    let workBook = null;
    const reader = new FileReader();
    this.fileUploaded = e.target.files[0];
    reader.onload = (event) => {
      console.log(event);
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      this.jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet, { raw: false });
        return initial;
      }, {});
      console.log(this.jsonData);

      if (this.jsonData) {
        if (this.jsonData['Sheet1'] && this.jsonData['Sheet1']?.length === 0) {
          showSnackbar(this._snackBar, 'No record exists in file', SNACKBAR_ACTION_WARNING);
          return;
        }
        const keys = this.jsonData['Sheet1']
          ? _.keys(this.jsonData['Sheet1'][0])
          : [];
        if (
          keys.indexOf('Requester Name') !== -1 &&
          keys.indexOf('Asset Name') !== -1 &&
          keys.indexOf('Area') !== -1 &&
          keys.indexOf('Activity') !== -1
        ) {
          this.isFileValid = true;
          this.jobs = this.jsonData['Sheet1'].map((x) => ({
            id: '',
            projectId: this.projectId,
            requesterName: String(x['Requester Name'] || ''),
            requesterEmail: String(x['Requester Email'] || ''),
            operatorName: String(x['Operator Name'] || ''),
            operatorEmail: String(x['Operator Email'] || ''),
            contractorName: String(x['Contractor Name'] || ''),
            milestoneName: String(x['Area'] || ''),
            assetName: String(x['Asset Name']),
            assetType: getAssetTypes(String(x['Asset Type']).trim()),
            trucks: Number(x['Number Of Trucks'] || 0),
            tonnage: Number(x['Tonnage'] || 0),
            tonnageUnit: String(x['Tonnage Unit'] || ''),
            lifts: Number(x['Number Of Lifts'] || 0),
            requestedStartTime: moment(x['Request Start Time']).utc().format(),
            requestedEndTime: moment(x['Request End Time']).utc().format(),
            status: String(x['Status'])?.toLowerCase(),
            operatorId: '',
            requesterId: '',
            contractorId: '',
            milestoneId: '',
            assetId: '',
            assetDetail: null,
            gateId: '',
            gateName: '',
            extensionReason: '',
            jobsHistory: [],
            timeLogs: [],
            createdDate: moment().utc().format(),
            isDeleted: false,
          }));
        } else {
          this.isFileValid = false;
          showSnackbar(this._snackBar, 'File columns are not matched with the required format', SNACKBAR_ACTION_ERROR);
        }
      }
    };
    reader.readAsBinaryString(this.fileUploaded);
    e.target.value = '';
  }

  importJobs(): void {
    const body: JobsImportViewModel = {
      jobs: this.jobs,
      batchImport: {
        id: '',
        name: this.fileUploaded.name,
        path: '',
        projectId: this.projectId,
        isDeleted: false,
        createdDate: moment().utc().format(),
      },
    };

    this.jobApiService.importJobs(body).subscribe((response) => {
      this.jobService.refreshJobs();
      showSnackbar(this._snackBar, 'Jobs uploaded successfully', SNACKBAR_ACTION_SUCCESSFUL);
      this.router.navigate(['jobs']);
    });
    // const data: Blob = new Blob([this.jsonData],json { type: "application/json" });
    // FileSaver.saveAs(data, "JsonFile" + new Date().getTime() + '.json');
  }

  cancelUpload(): void {
    this.showSubmit = false;
  }

  download() {
    window.location.href = '/assets/docs/Jobs Template.xlsx';
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
