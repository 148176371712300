import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  jobCompleted = new BehaviorSubject<boolean>(false);
  jobsRefreshed: Observable<void>;
  schedulerJobsRefreshed: Observable<void>;

  private refreshJobsSubject = new BehaviorSubject<void>(null);
  private refreshScheduleJobsSubject = new BehaviorSubject<void>(null);

  constructor() {
    this.jobsRefreshed = this.refreshJobsSubject.asObservable().pipe(skip(1));
    this.schedulerJobsRefreshed = this.refreshScheduleJobsSubject
      .asObservable()
      .pipe(skip(1));
  }

  refreshJobs(): void {
    this.refreshJobsSubject.next();
  }

  refreshSchedulerJobs(): void {
    this.refreshScheduleJobsSubject.next();
  }
}
