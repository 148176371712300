import { User } from '../../interfaces/user';
import * as actions from '../actions/auth.actions';

export interface IState {
    isLoadingUser: boolean;
    loggedInAccount: any;
    user: User;
}

const initialState: IState = {
    isLoadingUser: false,
    loggedInAccount: null,
    user: {
        id: '',
        name: '',
        principalName: '',
        projectId: '',
        contactNo: '',
        isDeleted: false,
        roleId: '',
        roleName: '',
        accessManagement: null,
        projects: [],
        notificationSettings: []
    },
};

export const reducer = (
    state = initialState,
    action: actions.AuthActions
): IState => {
    switch (action.type) {
        case actions.LOAD_USER:
            return {
                ...state,
                isLoadingUser: action.payload,
            };

        case actions.USER_LOGIN:
            return {
                ...state,
                loggedInAccount: action.payload,
            };

        case actions.SET_USER:
            return {
                ...state,
                user: {
                    ...action.payload,
                },
            };
        case actions.SET_USER_PROJECTS:
            return {
                ...state,
                user: {
                    ...state.user,
                    projects: action.payload,
                },
            };

        default:
            return state;
    }
};
