import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RecurrenceEvents } from 'app/core/interfaces';
import * as moment from 'moment';

@Component({
  selector: 'app-events-list-dialog',
  templateUrl: './events-list-dialog.component.html',
  styleUrls: ['./events-list-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class EventsListDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<RecurrenceEvents>;
  displayedColumns: string[] = ['start', 'end'];

  constructor(
    public matDialogRef: MatDialogRef<EventsListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RecurrenceEvents[],
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<RecurrenceEvents>(this.data || []);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  formatDate = (date): string => moment(date).format('lll');
}
