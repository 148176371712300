import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnloadingMethod } from '../../interfaces/unloading-method';
import { BaseODataService } from './base-odata.service';

@Injectable({
  providedIn: 'root',
})
export class UnloadingMethodsODataService extends BaseODataService<UnloadingMethod> {
  constructor(protected http: HttpClient) {
    super(http, 'unloadingMethods');
  }
}
