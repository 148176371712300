import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    Component,
    ElementRef, OnDestroy, OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LOCAL_STORAGE_KEYS } from 'app/core/constants/local-storage-keys';
import { Project } from 'app/core/interfaces';
import { UserProject } from 'app/core/interfaces/user-project';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { ProjectODataService } from 'app/core/services/odata/project-odata.service';
import { SelectProjectAction, SetPrevProjectId } from 'app/core/store/actions/common.actions';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectIsMobileView, selectPrevProjectId, selectProject, selectUser, selectUserProjects } from 'app/core/store/selectors';
import { Subject } from 'rxjs';
import { combineLatestWith, filter, takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-project-starter',
    templateUrl: './project-starter.component.html',
    styleUrls: ['./project-starter.component.scss'],
})
export class ProjectStarterComponent implements OnInit, OnDestroy {
    @ViewChild('tagsPanelOrigin') private _tagsPanelOrigin: ElementRef;
    @ViewChild('tagsPanel') private _tagsPanel: TemplateRef<any>;

    accountName = '';
    isLoggedIn = false;
    userProjects: UserProject[] = [];
    selectedProjectName: string;

    tagsEditMode: boolean = false;
    searchText: string;
    isMobileView: boolean = false;

    private _tagsPanelOverlayRef: OverlayRef;
    private _unsubscribeAll = new Subject();

    constructor(
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _localStorageService: LocalStorageService,
        private store: Store<AppState>,
        private router: Router,
        private projectsSvc: ProjectODataService
    ) {
        this.store
            .select(selectIsMobileView)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((isMobileView) => (this.isMobileView = isMobileView));
    }

    searchProject(searchText: string): void {
        if (searchText === '') {
            this.store.select(selectUserProjects)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((projects) => {
                    this.userProjects = projects;
                    const userProjectsForSort = [...this.userProjects];
                    userProjectsForSort.sort(this.getSortOrder('name'));
                    this.userProjects = userProjectsForSort;
                });
        } else {
            this.store.select(selectUserProjects)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((projects) => {
                    this.userProjects = projects.filter(item =>
                        item.name.toLowerCase().includes(searchText.toLowerCase())
                    );
                    this.userProjects.sort(this.getSortOrder('name'));
                });
        }
    }

    onCloseSearchProject(newTagInput): void {
        this.searchText = '';
        newTagInput.focus();
        this.searchProject(this.searchText);
    }

    ngOnInit(): void {
        this.checkAccount();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    checkAccount(): void {
        const token = this._localStorageService.get<string>('token');
        this.isLoggedIn = !!token && token !== '';

        if (this.isLoggedIn) {
            this.getUserDetail();
        } else {
            this.accountName = '';
        }
    }

    getUserDetail(): void {
        this.store.select(selectUser)
            .pipe(
                takeUntil(this._unsubscribeAll),
                combineLatestWith(
                    this.store.select(selectUserProjects),
                    this.store.select(selectPrevProjectId)))
            .subscribe(([user, projects, prevProjectId]) => {
                this.accountName = user.name;

                this.userProjects = projects;
                const userProjectsForSort = [...this.userProjects];
                userProjectsForSort.sort(this.getSortOrder('name'));
                this.userProjects = userProjectsForSort;

                this.store
                    .select(selectProject)
                    .pipe(
                        takeUntil(this._unsubscribeAll),
                        filter((x) => !!x && !!x.id && !!x.companyName))
                    .subscribe((x) => {
                        this.selectedProjectName = x.name;
                    });

                const selectedProjectId = this.getSelectedProjectId(user.projects);

                if (selectedProjectId === prevProjectId) {
                    return;
                }

                this.store.dispatch(new SetPrevProjectId(selectedProjectId));

                this.projectsSvc.getById(selectedProjectId)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((project) => {
                        this.store.dispatch(new SelectProjectAction(project));
                    });
            });
    }

    getSortOrder(prop) {
        return (a, b) => {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    }

    getSelectedProjectId(userProjects: UserProject[]): string {
        const projectIdFromLocalStorage = this._localStorageService.get<string>(
            LOCAL_STORAGE_KEYS.SelectedProject
        );

        if (!!projectIdFromLocalStorage && userProjects.find((p) => p.id === projectIdFromLocalStorage)) {
            return projectIdFromLocalStorage;
        }

        const defaultProjectId = userProjects[0].id;
        this._localStorageService.set(
            LOCAL_STORAGE_KEYS.SelectedProject,
            defaultProjectId
        );

        return defaultProjectId;
    }

    changeProject(project: Project): void {
        this.store.dispatch(new SelectProjectAction(project));
        this._localStorageService.set(
            LOCAL_STORAGE_KEYS.SelectedProject,
            project.id
        );

        this.reloadComponent();
    }

    reloadComponent(): void {
        const currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
        this._tagsPanelOverlayRef.dispose();
        if (currentUrl === '/jobs/multi-assets') {
            window.location.reload();
        }
    }

    openTagsPanel(): void {
        // Create the overlay
        this._tagsPanelOverlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._tagsPanelOrigin.nativeElement)
                .withFlexibleDimensions()
                .withViewportMargin(64)
                .withLockedPosition()
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                ]),
        });

        // Subscribe to the attachments observable
        this._tagsPanelOverlayRef.attachments()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Focus to the search input once the overlay has been attached
                this._tagsPanelOverlayRef.overlayElement.querySelector('input').focus();
            });

        // Create a portal from the template
        const templatePortal = new TemplatePortal(
            this._tagsPanel,
            this._viewContainerRef
        );

        // Attach the portal to the overlay
        this._tagsPanelOverlayRef.attach(templatePortal);

        // Subscribe to the backdrop click
        this._tagsPanelOverlayRef.backdropClick().subscribe(() => {
            // If overlay exists and attached...
            if (
                this._tagsPanelOverlayRef &&
                this._tagsPanelOverlayRef.hasAttached()
            ) {
                // Detach it
                this._tagsPanelOverlayRef.detach();

                // Toggle the edit mode off
                this.tagsEditMode = false;
            }

            // If template portal exists and attached...
            if (templatePortal && templatePortal.isAttached) {
                // Detach it
                templatePortal.detach();
            }
        });
    }
}
