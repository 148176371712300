import { createSelector } from '@ngrx/store';
import * as fromCommon from '../reducers/common.reducer';
import { AppState } from './../reducers/app.reducer';

export const selectCommon = (state: AppState) => state.common;

export const selectIsMobileView = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.isMobileView
);

export const selectPageTitle = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.pageTitle
);

export const selectProject = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.project
);

export const selectPrevProjectId = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.prevProjectId
);

export const selectSpinner = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.inProgress
);

export const selectToolBar = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.toolbar
);

export const filterScheduler = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.filterScheduler
);

export const selectIsNightShift = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.isNightShift
);

export const selectCustomizedUnloadingMethods = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.customizedUnloadingMethods
);

export const selectRecurrenceData = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.recurrenceData
);
