import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Asset } from 'app/core/interfaces';
import { Observable } from 'rxjs';
import { BaseODataService } from './base-odata.service';

@Injectable({
  providedIn: 'root',
})

export class AssetODataService extends BaseODataService<Asset> {
  constructor(
    protected http: HttpClient,
  ) {
    super(http, 'assets');
  }

  isExist(projectId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/isExist/${projectId}`);
  }

  inActiveHotSeats(projectId: string): Observable<any> {
    const objData = {
      projectId: projectId
    };

    return this.http.post<any>(`${this.baseUrl}/bulk-inactive-hotseats`, objData);
  }
}
