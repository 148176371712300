import * as moment from 'moment';
import {
  dateFormat,
  dateTimeFormat,
  timeFormat,
  timeExtendedFormat,
} from '../constants/date-time-formats';

export const calculateDuration = (start: any, end: any): string => {
  const duration = moment.utc(moment(end).diff(moment(start)));
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  let result: string = '';

  if (hours) {
    result = `${hours}h`;
  }

  if (minutes) {
    result += ` ${minutes}m`;
  }

  if (seconds) {
    result += ` ${seconds}s`;
  }

  return result;
};

export const computeDuration = (start: string, end: string): string => {
  const duration = moment.utc(moment(end).diff(moment(start)));
  const hours = duration.hours();
  const minutes = duration.minutes();
  let result: string;

  if (hours) {
    result = `${hours}h`;
  }

  if (minutes) {
    result += ` ${minutes}m`;
  }

  return result;
};

export const computeEndDateTime = (startDateTime, hours, minutes): string => {
  const endDateTime = moment(startDateTime)
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .format();
  return endDateTime;
};

export const extendTime=(existingTime,newlyAddedTime):string=>{
  const duration = existingTime;
  const durationParts = duration.split(' ');

  let hours = parseInt(durationParts[0]?.replace('h', ''));
  hours=hours?hours:0
  let minutes = parseInt(durationParts[1]?.replace('m', ''));
  minutes=minutes?minutes:0;
  const extention=newlyAddedTime;
  const extentionDurationParts = extention.split(' ');

  let extentionHours = parseInt(extentionDurationParts[0]?.replace('h', ''));
  extentionHours=extentionHours?extentionHours:0
  let extentionMinutes = parseInt(extentionDurationParts[1]?.replace('m', ''));
  extentionMinutes=extentionMinutes?extentionMinutes:0
  let totalHours = hours + extentionHours;
  let totalMinutes = minutes + extentionMinutes;

  totalHours += Math.floor( totalMinutes / 60);
  totalMinutes =Math.floor( totalMinutes % 60);
  const result=`${totalHours?totalHours+'h':''} ${totalMinutes?totalMinutes+'m':''}`

  return result
}

export const formatDate = (date: Date): string =>
  moment(date).format(dateFormat);

export const formatDateTime = (date: Date): string =>
  moment(date).format(dateTimeFormat);

export const formatTime = (date: Date): string =>
  moment(date).format(timeFormat);

export const formatExtendedTime = (date: Date): string =>
  moment(date).format(timeExtendedFormat);

export const disabledCalendarDates = (date: Date, maxDate: Date, isRoleAdmin: boolean): boolean => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const normalizedDate = new Date(date);
  normalizedDate.setHours(0, 0, 0, 0);
  const normalizedMaxDate = new Date(maxDate);
  normalizedMaxDate.setHours(0, 0, 0, 0);

  if (isRoleAdmin) {
    return normalizedDate > normalizedMaxDate;
  } else {
    return normalizedDate < currentDate || normalizedDate > normalizedMaxDate;
  }
}
