import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AuthWelcomeDescriptionComponent } from 'app/modules/auth/welcome-description/welcome-description.component';

@NgModule({
  declarations: [
    AuthWelcomeDescriptionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    AuthWelcomeDescriptionComponent,
    ReactiveFormsModule
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },]
})

export class SharedModule {
}
