import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetDetailPanelService {

  constructor() { }

  private dataSource = new BehaviorSubject(null);

  data = this.dataSource.asObservable();

  changeData(data: number): void {
    this.dataSource.next(data);
  }
}
