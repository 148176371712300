import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CdTimerComponent } from 'angular-cd-timer';
import { Job, PackageLocation, TimeLog, TruckDetailsLogs, User } from 'app/core/interfaces';
import { JobService } from 'app/core/services/job.service';
import { JobODataService } from 'app/core/services/odata/job-odata.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectUser } from 'app/core/store/selectors/auth.selectors';
import { getHistory } from 'app/core/utils/utils';
import { compare } from 'fast-json-patch';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { take } from 'rxjs/operators';
import { dateTimeFormat } from '../../../core/constants/date-time-formats';
import { CheckInOutImageUploadDialogComponent } from '../check-in-out-image-upload-dialog/check-in-out-image-upload-dialog.component';

@Component({
  selector: 'app-check-in-detail',
  templateUrl: './check-in-detail.component.html',
  styleUrls: ['./check-in-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CheckInDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() job: Job;
  @Input() truckNumber: number;
  @Input() isHeader: boolean = false;
  @ViewChild('cdTimer') private cdTimer: CdTimerComponent;
  autoStart = false;
  cdStartTime = 0;
  closedDurationInSeconds = 0;
  endTime: string;
  fetchTime = '00:00:00';
  isCheckedIn = false;
  allowCheckIn = true;
  isCompleted = false;
  startTime: string;
  jobTimeLog: TimeLog;
  user: User;
  dialogRef: any;
  showImagesOption = false;

  isMobileView: boolean = false;
  isTabletView: boolean = false;
  increment: number = 1;
  showActions: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private jobODataService: JobODataService,
    private jobService: JobService,
    private store: Store<AppState>,
    private _matDialog: MatDialog
  ) {
    this.store.select(selectUser).pipe(takeUntil(this._unsubscribeAll)).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    if (this.job.timeLogs && this.job.timeLogs.length > 0) {
      this.calculateClosedDuration();
      this.cdStartTime = this.closedDurationInSeconds;

      const timeLog = this.getExistingOpenTimeLog(this.job.timeLogs);
      if (timeLog) {
        const currentTime = moment(
          moment().utc().format(dateTimeFormat),
          dateTimeFormat
        );

        const startTime = moment(timeLog.startTime, dateTimeFormat);
        this.cdStartTime += currentTime.diff(startTime, 'seconds');
        this.autoStart = true;
        this.isCheckedIn = true;
      } else {
        if (this.job.status === 'completed') {
          this.isCompleted = true;
        }
      }
    }

    this.allowCheckIn = this.closedDurationInSeconds === 0 ? true : false;

    if (this.job.files != null && this.job?.files?.length > 0 && this.job.files.some(item => item.truckNumber === this.truckNumber)) {
      this.showImagesOption = true;
    }
  }

  ngAfterViewInit(): void {
    this.cdTimer.start();

    if (!this.autoStart) {
      this.cdTimer.onTick.pipe(take(1)).subscribe(() => this.cdTimer.stop());
    }
  }

  calculateClosedDuration(): void {
    this.closedDurationInSeconds = 0;

    const closedLogs = this.job.timeLogs.filter(
      x => x.truckNumber === this.truckNumber && x.endTime
    );

    closedLogs.forEach((log) => {
      const startDate = moment(log.startTime, dateTimeFormat);
      const endDate = moment(log.endTime, dateTimeFormat);
      this.closedDurationInSeconds += endDate.diff(startDate, 'seconds');
    });
  }

  openPictureDialog = (showActions: boolean): void => {
    this.dialogRef = this._matDialog.open(
      CheckInOutImageUploadDialogComponent,
      {
        data: {
          type: this.isCheckedIn ? 'check-out' : 'check-in',
          jobId: this.job.id,
          truckNumber: this.truckNumber,
          showActions: showActions,
        },
        width: '800px',
      }
    );
  };

  checkInCheckOut = (): void => {
    this.openPictureDialog(true);

    this.dialogRef.afterClosed().subscribe((response: any) => {
      if (!response) {
        return;
      }
      this.saveCheckInCheckOut();
    });
  };

  saveCheckInCheckOut = (): void => {
    let packageLocation: PackageLocation[] = null;
    let packageLocationData: PackageLocation = null;
    let patch = null;

    this.isCheckedIn = !this.isCheckedIn;
    const timeLogs =
      this.job?.timeLogs && this.job?.timeLogs?.length > 0
        ? _.cloneDeep(this.job.timeLogs)
        : [];

    this.jobTimeLog = {
      userId: this.user.id,
      userName: this.user.name,
      truckNumber: this.truckNumber,
      startTime: null,
      assetId: '',
      assetType: '',
      isActive: true,
    };

    let type = '';

    if (this.isCheckedIn) {
      this.cdTimer.resume();
      this.jobTimeLog.startTime = moment().utc().format(dateTimeFormat);
      timeLogs.push(this.jobTimeLog);
      type = 'Check-in';
      packageLocationData = {
        id: this.job.gateId,
        name: this.job.gateName,
        type: type,
      }
    } else {
      this.cdTimer.stop();
      const existingTimeLog = this.getExistingOpenTimeLog(timeLogs);
      existingTimeLog.endTime = moment().utc().format(dateTimeFormat);
      type = 'Check-out';
      packageLocationData = {
        id: this.job.assetId,
        name: this.job.assetName,
        type: type,
      }
    }

    const objTruckDetailsLogs: TruckDetailsLogs = {
      truckNumber: this.truckNumber + 1,
      totalTrucks: this.job.trucks,
    }

    const jobHistory = [...this.job.jobsHistory, getHistory(type, this.user, '', objTruckDetailsLogs)];

    if (this.job.package !== null) {
      if (this.job.package?.items?.length > 0 && this.job.package?.items !== null) {
        if (!this.job.packageLocation) {
          packageLocation = [packageLocationData];
        }
        else {
          packageLocation = [...this.job.packageLocation, packageLocationData];
        }

        patch = compare(
          { timeLogs: this.job.timeLogs, jobsHistory: this.job.jobsHistory, packageLocation: this.job.packageLocation },
          { timeLogs, jobsHistory: jobHistory, packageLocation }
        );
      }
      else {
        patch = compare(
          { timeLogs: this.job.timeLogs, jobsHistory: this.job.jobsHistory },
          { timeLogs, jobsHistory: jobHistory }
        );
      }
    } else {
      patch = compare(
        { timeLogs: this.job.timeLogs, jobsHistory: this.job.jobsHistory },
        { timeLogs, jobsHistory: jobHistory }
      );
    }

    this.jobODataService.patch(this.job.id, patch).subscribe(() => {
      this.getJobTimeLogs(this.job.id);
      this.jobService.refreshJobs();
    });
  };

  getExistingOpenTimeLog(timeLogs: TimeLog[]): TimeLog {
    return timeLogs.find(
      x => x.truckNumber === this.truckNumber && !x.endTime && x.isActive
    );
  }

  getJobTimeLogs = (jobId: string): void => {
    this.jobODataService.getById(jobId).pipe(takeUntil(this._unsubscribeAll)).subscribe((job) => {
      this.job.timeLogs = job.timeLogs;
      this.calculateClosedDuration();

      const openTimeLogsCount = this.job.timeLogs.filter(
        x => !x.endTime && x.isActive
      ).length;

      if (openTimeLogsCount === 0) {
        const closedTimeLogs = this.job.timeLogs
          .filter(x => x.endTime)
          .map(x => x.truckNumber);

        const closedTrucks = _.uniq(closedTimeLogs);
        if (this.job.trucks === closedTrucks.length) {
          const patch = compare(
            { status: this.job.status },
            { status: 'completed' }
          );

          this.jobODataService.patch(this.job.id, patch).subscribe(() => {
            this.jobService.refreshJobs();
            this.isCompleted = true;
          });
        }
      }
    });
  };

  restore = (): void => {
    this.jobODataService.getById(this.job.id).pipe(takeUntil(this._unsubscribeAll)).subscribe((job) => {
      this.job.timeLogs = job.timeLogs;
      let truckTimeLog = _.cloneDeep(this.job.timeLogs);

      const jobHistory = [
        ...this.job.jobsHistory,
        getHistory('Job Restored', this.user),
      ];

      const patch = compare(
        {
          status: this.job.status,
          timeLogs: this.job.timeLogs,
          jobsHistory: this.job.jobsHistory,
        },
        { status: 'approved', timeLogs: truckTimeLog, jobsHistory: jobHistory }
      );

      this.jobODataService.patch(this.job.id, patch).subscribe(() => {
        this.getJobTimeLogs(this.job.id);
      });
    });
  };

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
