import { BaseExtend } from './base';
import { JobHistory } from './job-history';

export interface BoqItem extends BaseExtend {
  id: string;
  itemId: string;
  packageTitle: string;
  materialDescription: string;
  materialQuantity: number;
  deliveredQuantity: number;
  measuredUnit: string;
  storageDuration: number;
  storageStatus: string;
  projectId: string;
  createdBy: string;
  createdByEmail: string;
  createdDate: string;
  modifiedBy: string;
  modifiedByEmail: string;
  modifiedDate: string;
  scanHistory: JobHistory[];
  isSelected?: boolean;
  qrCodeImage: string;
}

export interface Boq extends BaseExtend {
  status: string;
  projectId: string;
  history: JobHistory[];
}

export class QRCodeDetails {
  codeType: string = undefined;
  projectId: string = undefined;
  id: string = undefined;
}

export interface SelectedPackageItems {
  id: string;
  itemId: string;
  name: string;
  quantity: number;
  allowableQuantity: number;
  measuredUnit: string;
}
