import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CalendarOptions,
  DateSelectArg,
  EventApi,
  EventClickArg,
  FullCalendarComponent,
} from '@fullcalendar/angular';
import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { FuseDrawerService } from '@fuse/components/drawer';
import { Store } from '@ngrx/store';
import {
  dateFormat,
  timeExtendedFormat,
} from 'app/core/constants/date-time-formats';
import { Asset, IODataParams, Job, Project, User } from 'app/core/interfaces';
import { Base } from 'app/core/interfaces/base';
import { DowntimeScheduler, TimeOffScheduler } from 'app/core/interfaces/down-time';
import { AccessManagement } from 'app/core/interfaces/roles';
import { JobDetailPanelService } from 'app/core/services/data-services/job-detail-panel.service';
import { JobService } from 'app/core/services/job.service';
import { AssetODataService } from 'app/core/services/odata/asset-odata.service';
import { ContractorODataService } from 'app/core/services/odata/contractor-odata.service';
import { JobODataService } from 'app/core/services/odata/job-odata.service';
import { SchedulerService } from 'app/core/services/scheduler.service';
import { FilterScheduler } from 'app/core/store/actions/common.actions';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectUser, selectUserSettings } from 'app/core/store/selectors/auth.selectors';
import {
  filterScheduler,
  selectIsMobileView,
  selectProject
} from 'app/core/store/selectors/common.selectors';
import { disabledCalendarDates, formatDate, formatTime } from 'app/core/utils/date-time.utils';
import { getDialogInfo } from 'app/core/utils/dialog.utils';
import { getJobConflictsDowntime } from 'app/core/utils/job-conflicts-downtime.utils';
import { SNACKBAR_ACTION_ERROR, SNACKBAR_ACTION_WARNING, getAssetTypeIcon, getCalendarsJobTitle, getHistory, getJobTitle, isRolePermissionAllowed, showJobStatusSnackbar, showSnackbar } from 'app/core/utils/utils';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject, forkJoin } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { JobConfirmDialogComponent } from '../job-confirm-dialog/job-confirm-dialog.component';

declare var $: any;

@Component({
  selector: 'app-job-scheduler',
  styleUrls: ['./job-scheduler.component.scss'],
  templateUrl: './job-scheduler.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class JobSchedulerComponent
  implements AfterViewInit, OnDestroy {
  @ViewChild(FullCalendarComponent) fullCalendar: FullCalendarComponent;
  @Input() showTooltip = false;
  @Input() showCreateJobTooltip = true;
  @Input() isRequestJob = false;
  @Input() config: any = {
    view: 'timeGridDay',
    showTableHeader: true,
    showNextPrevControls: true,
  };

  private interval = null;

  jobsConfig: any = {
    title: 'All',
    view: 'full-view',
    edit: true,
    filter: {
      myJobsOnly: false,
      activeJobs: false,
      completedJobs: false,
      sorting: false,
    },
    filterDataBy: 'all',
    sortDataBy: 'recent',
    parameters: null,
    showTodayJobs: false,
  };

  jobId: string;
  calendarOptions: CalendarOptions;
  calendarVisible = false;
  showEditDrawer: boolean = false;
  currentEvents: EventApi[] = [];
  isMobileView: boolean = false;
  searchObjectFromService: any = [];
  top = 5;
  skip = 0;
  week = 0;
  month = 0;
  user: User;
  dialogRef: any;
  highlightEvent: any;
  project: Project;
  downTimes: DowntimeScheduler[] = [];
  timeOffs: TimeOffScheduler[] = [];
  assets: Asset[] = [];
  dateFormatForSearch = 'YYYY-MM-DD';
  searchObject: any[] = [];
  searchStringForScheduler = '';
  selectedFilters: any;
  selectedAssets: Asset[] = [];
  selectedDate = moment().toDate();
  maxAssetsSelection: number = 5;
  isSchedulerOnly: boolean = false;
  jobCategories: string[] = ['All', 'Recent Jobs', 'Requested Jobs'];
  sortJobsFilter: string = '';
  jobCategoriesFilter: any = null;
  selectedJob: Job;
  selectedMultiAssets: Base[] = [];
  isMultiAssets: boolean = false;
  propsResponse: any;
  maxDate = new Date();
  userAccess: AccessManagement;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private jobODataService: JobODataService,
    private contractorService: ContractorODataService,
    private assetODataService: AssetODataService,
    private _jobDetailPanelService: JobDetailPanelService,
    private store: Store<AppState>,
    private jobService: JobService,
    private _snackBar: MatSnackBar,
    private _matDialog: MatDialog,
    private _schedulerService: SchedulerService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private _fuseDrawerService: FuseDrawerService
  ) {
    this.store
      .select(selectUserSettings)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: AccessManagement) => {
        if (response) {
          this.userAccess = response;
        }
      });

    this.selectUser();
    this.setScrollTime();

    if (this.router.url === '/jobs/working-week') {
      this.jobCategories.push('Conflict Jobs');
    }

    this.store
      .select(filterScheduler)
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(x => !!x)
      )
      .subscribe(response => {
        this.selectedFilters = response.searchObject;
      });

    this._jobDetailPanelService.data.pipe(takeUntil(this._unsubscribeAll)).subscribe(x => {
      if (x && x.jobId) {
        this.jobODataService.getById(x.jobId).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
          this.toggleEditDrawer(res);
        });
      }
    });
  }

  fetchFilterScheduler(): void {
    let selectedDate = this.selectedDate;

    if (this.selectedFilters) {
      const assets = this.selectedFilters.find(
        x => x.field === 'assets'
      );

      const dateFilterObj = this.selectedFilters.find(
        x => x.field === 'requestedStartTime'
      );

      if (assets || assets?.value?.length > 0) {
        this.selectedAssets = _.take(
          assets.value,
          this.maxAssetsSelection
        );
      } else {
        this.selectedAssets = [];
        if (this.project.defaultAssets && this.project.defaultAssets.length > 0) {
          this.project.defaultAssets.forEach((x) => {
            const fetchAsset = this.assets.find(as => as.id === x.id);
            if (fetchAsset) {
              this.selectedAssets.push(fetchAsset);
            }
          });

          if (this.selectedAssets.length === 0) {
            this.selectedAssets.push(this.assets[0]);
          }
        }
        else {
          this.selectedAssets.push(this.assets[0]);
        }
      }

      if (dateFilterObj) {
        selectedDate = dateFilterObj.value;
        this.selectedDate = selectedDate;
      }
    } else {
      this.selectedAssets = [];
      if (this.project.defaultAssets && this.project.defaultAssets.length > 0) {
        this.project.defaultAssets.forEach((x) => {
          const fetchAsset = this.assets.find(as => as.id === x.id);
          if (fetchAsset) {
            this.selectedAssets.push(fetchAsset);
          }
        });

        if (this.selectedAssets.length === 0) {
          this.selectedAssets.push(this.assets[0]);
        }
      }
      else {
        if (this.assets.length > 0) {
          this.selectedAssets.push(this.assets[0]);
        }
      }
    }

    this.addOrUpdateFilter('assets', this.selectedAssets);
    this.addOrUpdateFilter(
      'requestedStartTime',
      moment(selectedDate).format()
    );

    this.dispatchFilterScheduler();

    this.store.select(selectIsMobileView).pipe(takeUntil(this._unsubscribeAll)).subscribe(isMobileView => {
      this.isMobileView = isMobileView;
      this.loadScheduler(isMobileView ? 'responsive' : '');
    });

    this.currentRoute.url.pipe(takeUntil(this._unsubscribeAll)).subscribe(url => {
      if (url.length > 0) {
        this.showEditDrawer = true;
        const customUrl = url[0];
        if (
          customUrl.path === 'working-week' ||
          customUrl.path === 'multi-assets'
        ) {
          this.isSchedulerOnly = true;
        } else {
          this.isSchedulerOnly = false;
        }

        if (customUrl.path === 'multi-assets') {
          this.isMultiAssets = true;
        }

        else {
          this.isMultiAssets = false;
        }
      } else {
        this.showEditDrawer = false;
        this.isSchedulerOnly = false;
        this.isMultiAssets = false;
      }
    });
  }

  getAssets(): void {
    const contractorParams: IODataParams = {
      filter: `(projectId eq '${this.project.id}' and isDeleted ne true)`,
      orderBy: ['name asc'],
    };

    const params: IODataParams = {
      filter: `(projectId eq '${this.project.id}') and isActive eq true and isDeleted ne true`,
      selects: ['id', 'name', 'type', 'downtime', 'timeOff'],
      orderBy: ['name asc'],
    };

    const contractorObservable = this.contractorService.get(contractorParams).pipe(takeUntil(this._unsubscribeAll));
    const assetObservable = this.assetODataService.get(params).pipe(takeUntil(this._unsubscribeAll));

    if (isRolePermissionAllowed('manage-job-access', this.userAccess)) {
      forkJoin({
        contractorData: contractorObservable,
        assetData: assetObservable,
      }).subscribe(({ contractorData, assetData }) => {
        if (contractorData.count > 0) {
          const currentUserInContractor = contractorData.value.filter((x) =>
            x.users?.some((user) => user.id === this.user.id)
          );

          this.assets = currentUserInContractor
            .flatMap((contractor) => contractor.assets.length > 0 ? contractor.assets : assetData.value)
            .filter((asset, index, arr) =>
              arr.findIndex((a) => a.id === asset.id) === index
            ).map((obj1) => {
              const matchingObj = assetData.value.find((obj2) => obj1.id === obj2.id);
              return { ...obj1, ...matchingObj };
            });

          this.fetchFilterScheduler();
        }
      });
    } else {
      assetObservable.subscribe(data => {
        this.assets = data.value;
        this.fetchFilterScheduler();
      });
    }
  }

  toggleEditDrawer(job: Job): void {
    this.selectedJob = job;
    const drawer = this._fuseDrawerService.getComponent(
      'editJobSchedulerDrawer'
    );
    drawer.toggle();
  }

  updateEditJobDrawer(value: boolean): void {
    if (!value) {
      const drawer = this._fuseDrawerService.getComponent(
        'editJobSchedulerDrawer'
      );
      drawer.toggle();
      this.selectedJob = null;
      this.jobId = '';
      this._jobDetailPanelService.changeData(null);
    }
  }

  removeEditJobValue(value: boolean): void {
    if (!value) {
      this.selectedJob = null;
      this.jobId = '';
      this._jobDetailPanelService.changeData(null);
    }
  }

  ngDoCheck() {
    $('.fc-dayGridMonth-button').click(function () {
      $('.gmt').hide();
    })

    $('.fc-dayGridWeek-button').click(function () {
      $('.gmt').hide();
    })

    $('.fc-timeGridDay-button').click(function () {
      $('.gmt').show();
    })

    $('.fc-conflictView-button').click(function () {
      $('.gmt').show();
    })
  }

  ngAfterViewInit(): void {
    const currentTarget = this;

    // if (this.config.view === 'timeGridWeek' || this.config.view === 'dayGridWeek') {
    //   $(document).on('click', '.fc-next-button', () => {
    //     currentTarget.renderWeekScheduler('next');
    //   });

    //   $(document).on('click', '.fc-prev-button', () => {
    //     currentTarget.renderWeekScheduler('prev');
    //   });
    // }

    $(document).on('click', '.popover .approve-action', () => {
      $('.popover').remove();
      const jobVal = JSON.parse(localStorage.getItem('job'));
      this.openConfirmDialog('approve', jobVal);
    });

    $(document).on('click', '.popover .cancel-action', () => {
      $('.popover').remove();
      const jobVal = JSON.parse(localStorage.getItem('job'));
      this.openConfirmDialog('cancel', jobVal);
    });

    if (this.config.view === 'dayGridMonth') {
      $(document).on('click', '.fc-next-button', () => {
        currentTarget.renderMonthScheduler('next');
      });

      $(document).on('click', '.fc-prev-button', () => {
        currentTarget.renderMonthScheduler('prev');
      });
    }

    $('html').on('click', e => {
      $('.popover').each(() => {
        if ($(e.target).find('.fc-timegrid-slot').length === 0) {
          $(this).popover('hide');
        }
      });
    });

    if (this.fullCalendar) {
      const now = new Date();
      // add 4 hours margin so that current time is not touching the top border
      const scrollToHour = now.getHours() - 4;
      this.fullCalendar.getApi().scrollToTime({
        hour: scrollToHour,
        minute: now.getMinutes(),
      });
      // if (this.scrollToTime) {
      //   this.fullCalendar.getApi().scrollToTime(this.scrollToTime);
      // }
    }

    // if (this.showCreateJobTooltip) {
    //   $('html').on('click', function (e) {
    //     if (
    //       typeof $(e.target).data('original-title') === 'undefined' &&
    //       !$(e.target).parents().is('.popover.in')
    //     ) {
    //       $('[data-original-title]').popover('hide');
    //     }
    //   });
    // }
  }

  loadScheduler(mode: string) {
    this.store
      .select(filterScheduler)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(response => {
        if (response) {
          this.propsResponse = response;
          this.initScheduler();

          this.searchObjectFromService = response['searchObject'];
          const filteredDate = this.searchObjectFromService.find(
            x => x.field === 'requestedStartTime'
          ).value;

          this.calendarOptions.initialDate =
            moment(filteredDate).format(dateFormat);

          switch (this.config.view) {
            case 'conflictView':
              this.renderDayScheduler(response, true);
              if (mode === 'responsive') {
                this.calendarOptions.contentHeight = 'auto';
              } else {
                this.calendarOptions.contentHeight = '64px';
              }

              break;

            case 'timeGridDay':
              if (this.isMultiAssets && this.isMobileView) {
                this.renderMultiAssetScheduler();
                this.calendarOptions.contentHeight = 'auto';
              }
              else {
                this.renderDayScheduler(response, false);
                if (mode === 'responsive') {
                  this.calendarOptions.contentHeight = 'auto';
                } else {
                  this.calendarOptions.contentHeight = '64px';
                }
              }

              break;

            case 'resourceTimeGridDay':
              this.renderMultiAssetScheduler();
              this.calendarOptions.contentHeight = 'auto';
              break;

            case 'timeGridWeek':
            case 'dayGridWeek':
              this.renderWeekScheduler('normal');
              this.calendarOptions.contentHeight = this.router.url === '/jobs' ? '64px' : 'auto';
              break;

            case 'dayGridMonth':
              this.renderMonthScheduler('');
              this.calendarOptions.contentHeight = 'auto';
              break;
          }
        }
      });
  }

  initScheduler(): void {
    this.calendarVisible = true;
    this.calendarOptions = {
      firstDay: 1,
      allDaySlot: false,
      contentHeight: '64px',
      dayHeaders: this.config.showTableHeader, // hiding day header of calender
      editable: true,
      views: {
        conflictView: {
          type: 'timeGridDay',
          buttonText: 'Conflict',
          duration: { days: 1 },
        },
      },
      headerToolbar: {
        left: this.config.showNextPrevControls ? 'prev,next title' : null,
        right: this.config.showNextPrevControls
          ? 'timeGridDay,dayGridWeek,dayGridMonth,conflictView'
          : null,
      },

      height: '100%',
      // height: 'parent',

      initialView: this.config.view,
      locale: 'en-GB',
      nowIndicator: true,
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      selectable: true,
      // selectMirror: true,
      slotEventOverlap: false,
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: false,
        hour12: false,
      },
      resourceOrder: 'sortKey',
      themeSystem: 'standard',
      customButtons: {
        prev: {
          click: this.prev,
        },
        next: {
          click: this.next,
        },
        dayGridMonth: { // this overrides the month button
          text: 'Month',
          click: () => {
            const calendarApi = this.fullCalendar?.getApi();
            calendarApi.changeView('dayGridMonth');
            this.config.view = 'dayGridMonth';
            this.loadScheduler('');
          }
        },
        dayGridWeek: { // this overrides the week button
          text: 'Week',
          click: () => {
            const calendarApi = this.fullCalendar?.getApi();
            calendarApi.changeView('dayGridWeek');
            this.config.view = 'dayGridWeek';
            this.loadScheduler('');
          }
        },
        timeGridDay: { // this overrides the day button
          text: 'Day',
          click: () => {
            const calendarApi = this.fullCalendar?.getApi();
            calendarApi.changeView('timeGridDay');
            this.config.view = 'timeGridDay';
            this.loadScheduler('');
          }
        },
        conflictView: {
          text: 'Conflict',
          click: () => {
            const calendarApi = this.fullCalendar?.getApi();
            calendarApi.changeView('conflictView');
            this.config.view = 'conflictView';
            this.loadScheduler('');
          },
        },
      },
      eventClick: this.handleEventClick,
      eventDidMount: this.eventDidMount,
      eventsSet: this.handleEvents,
      eventDrop: this.handleDropEvent,
      eventResize: this.handleResizeEvent,
      select: this.handleDateSelect,
      unselectAuto: true,
      resourceLabelDidMount: (args) => {
        const id = args.resource.id;
        const $select = $(`<select class="form-control dropdown" data-id="${id}"></select>`);

        this.assets.forEach((value) => {
          $select.append(
            `<option value="${value.id}" ${value.id === id ? 'selected' : ''}>${value.name}</option>`
          );
        });

        $select.on('change', ev => {
          if (!this.interval) {
            clearInterval(this.interval);
          }

          const prevId = $(ev.target).data('id');
          const newId = $(ev.target).val();
          $('.form-control.dropdown').not(ev.target).each((idx, elem) => {
            if ($(elem).val() === newId) {
              $(elem).val(prevId);
              $(elem).data('id', prevId);
            }
          });

          $(ev.target).data('id', newId);

          this.selectedMultiAssets = $('select').map((_, elem) => ({
            id: $(elem).val(),
            name: this.assets.find(x => x.id === $(elem).val()).name
          })).get();

          this.callMultiAssets([]);
        });

        const $div = $('<div class="event_schedular schedular_main"></div>');
        $div.append($select);

        $(args.el).append($div);
      },
    };
  }

  handleDropEvent = (info): void => {
    this.openConfirmationDialog(info);
  }

  handleResizeEvent = (arg: EventResizeDoneArg): void => {
    this.openConfirmationDialog(arg);
  }

  openConfirmationDialog(info): void {
    const dialogRef = this._matDialog.open(JobConfirmDialogComponent, {
      data: getDialogInfo('updateTimeSlotCalendar'),
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((response: string) => {
      if (!response) {
        info.revert();
        return;
      }

      const job = info.event.extendedProps.job;
      job.requestedStartTime = moment(info.event.start).utc().format();
      job.requestedEndTime = moment(info.event.end).utc().format();

      const params: IODataParams = {
        filter: `(projectId eq '${this.project.id}' and id eq '${job.assetId}') and isActive eq true and isDeleted ne true`,
      };

      this.assetODataService.get(params).pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
        const asset: Asset = response.value[0];
        if (asset.downtime !== undefined && asset.downtime !== null) {
          const downtime = getJobConflictsDowntime(asset.downtime, job);
          if (downtime.length === 0) {
            this.updateJob(job);
          } else {
            showSnackbar(this._snackBar, 'The asset has downtime / is in-active during the selected period.', SNACKBAR_ACTION_WARNING);
          }
        } else {
          this.updateJob(job);
        }
      });
    });
  }

  createJob(job: Job): any {
    const startTime = formatTime(job.requestedStartTime);
    const endTime = formatTime(job.requestedEndTime);
    const start = `${formatDate(job.requestedStartTime)}T${startTime}`;
    const end = `${formatDate(job.requestedEndTime)}T${endTime}`;

    let eventColor = '';

    switch (job.status) {
      case 'pending':
      case 'requested':
        eventColor = 'event-orange';
        break;
      case 'booked':
      case 'approved':
        eventColor = 'event-blue';
        break;
      case 'completed':
        eventColor = 'event-green';
        break;
      default:
        eventColor = 'event-red';
        break;
    }

    const assetIcon = getAssetTypeIcon(job.assetType);
    const assetName = `<div class='asset-div'><span class='asset-name'>${job.assetName}</span></div>`;
    const assetImage = ` <img class="asset-icon" src="${assetIcon}" />`;

    return {
      id: job.id,
      title: this.isUserRoleContractor(job) ? '' : getCalendarsJobTitle(job),
      start: start,
      end: end,
      resourceId: job.assetId,
      description: `${assetName}`,
      status: job.status,
      classNames: ['custom-event', this.isUserRoleContractor(job) ? '#e1e1e1' : eventColor],
      backgroundColor: this.isUserRoleContractor(job) ? '#e1e1e1' : job.contractorColor,
      allDay: false,
      extendedProps: { job: job, assetImage: assetImage },
    };
  }

  createDownTime(downtime: DowntimeScheduler): any {
    const assetIcon = getAssetTypeIcon(downtime.assetType);
    const assetImage = ` <img class="downtime-image" src="${assetIcon}" />`;

    return {
      id: downtime.id,
      start: downtime.startDate,
      end: downtime.endDate,
      resourceId: downtime.assetId,
      classNames: ['custom-event', 'has-downtime'],
      backgroundColor: '#e1e1e1',
      allDay: false,
      extendedProps: { downtime: downtime, assetImage: assetImage },
    };
  }

  createTimeOff(timeOff: TimeOffScheduler): any {
    const assetIcon = getAssetTypeIcon(timeOff.assetType);
    const assetImage = ` <img class="timeOff-image" src="${assetIcon}" />`;

    return {
      id: timeOff.id,
      start: timeOff.startDate,
      end: timeOff.endDate,
      resourceId: timeOff.assetId,
      classNames: ['custom-event', 'has-timeOff'],
      backgroundColor: '#cbcbcb',
      allDay: false,
      extendedProps: { timeOff: timeOff, assetImage: assetImage },
    };
  }

  createResource(asset: any): { id: string; title: string } {
    return {
      id: asset.id,
      title: asset.name,
    };
  }

  eventDidMount = info => {
    let job: Job = info.event.extendedProps['job'];
    let downtime: DowntimeScheduler = info.event.extendedProps['downtime'];
    let timeOff: TimeOffScheduler = info.event.extendedProps['timeOff'];

    if (downtime) {
      info.event.setProp('editable', false);
      const eventDesign = this.getDowntimeDisplayDesign(downtime, info.event.extendedProps.assetImage);
      $(info.el)
        .find('.fc-event-title')
        .html(eventDesign);

      return;
    }

    if (timeOff) {
      info.event.setProp('editable', false);
      const eventDesign = this.getTimeOffDisplayDesign(timeOff, info.event.extendedProps.assetImage);
      $(info.el)
        .find('.fc-event-title')
        .html(eventDesign);

      return;
    }

    if (!job) {
      return;
    }

    if (this.user.roleName !== 'Admin' && this.user.roleName !== 'Global Admin') {
      info.event.setProp('editable', false);
    }

    let actions = '';
    if (this.checkUserSettings('approveJob') && job.status === 'requested') {
      actions +=
        '<span class="material-icons approve-action text-primary">check</span>';
    }
    if (
      this.checkUserSettings('cancelJob') &&
      (job.status === 'requested' || job.status === 'approved')
    ) {
      actions +=
        '<span class="material-icons cancel-action text-danger">clear</span>';
    }

    const duration = this.calculateDuration(
      job.requestedStartTime,
      job.requestedEndTime
    );

    let jobDesign = '<div class="event_schedular">' +
      '<div class="media-body">' +
      '<div class="media-title">' +
      info.event.title +
      '</div>' + info.event.extendedProps.description +
      '</div>' +
      '<div class="controlAssets">' +
      '<span class="icon">' + info.event.extendedProps.assetImage +
      '</span>' +
      '</div>' +
      '</div>';

    if (this.isUserRoleContractor(job)) {
      jobDesign = '<div class="event_schedular other_contractor_jobs"></div>';
    }

    $(info.el)
      .find('.fc-event-title')
      .html(jobDesign);

    let status = '';
    if (info.event.classNames.includes('event-orange')) {
      status = 'Requested';
    } else if (info.event.classNames.includes('event-blue')) {
      status = 'Approved';
    } else if (info.event.classNames.includes('event-green')) {
      status = 'Completed';
    } else {
      status = 'Cancelled';
    }

    const title = `
                      <span  class="status ${status}">
                      ${status}
                      </span>

                  `;
    const assetName = job.assetName;
    const contractorColor = job.contractorColor;
    const contractorName = job.contractorName;
    const jobStartTime = moment(job.requestedStartTime).format('DD/MM/YY') + ' - ' + moment(job.requestedStartTime).fromNow();

    if (this.showTooltip) {
      const template = `
      <div class="popover popoverJobs"  >
         <div class="arrow"></div>
         <div class="popover-content popover-body"></div>
      </div> `;
      const body = `
      <div class="popoverHeader">
      <div class="assetName" ><span class="status-dot"></span>  <h4> ${contractorName} </h4></div>
      <div class="timeDate"><i><span class="material-icons">schedule </span></i>
      <span>${jobStartTime}</span>
      </div>
      </div>
      <div class="card-body">
     <h3>${getJobTitle(job)}</h3>
     <div class="info-assets">
        <div class="asset"><i>${info.event.extendedProps.assetImage}</i> <h4> ${assetName} </h4></div>
        <div class="duration"><i><span class="material-icons">
        hourglass_empty
        </span></i>  <span>
        ${moment(info.event.start).format('HH:mm')}-${moment(
        info.event.end
      ).format('HH:mm')} | ${duration ? duration : "0s"}
        </span></div>
     </div>
      </div>
      <div class="cardFooter">
      ${title}
      <div class="tooltip-action">
      ${actions}
      </div>
   </div>
      `;



      $(info.el)
        .find('.event_schedular:not(.other_contractor_jobs)')
        .popover({
          title: title,
          placement: 'right',
          trigger: 'manual',
          animation: true,
          html: true,
          template: template,
          content: body,
        })
        .on('mouseenter', function () {
          $('.popover').popover('hide');
          var _this = this;
          $(this).popover('show');
          $(".status-dot").css('background-color', contractorColor);
          localStorage.setItem('job', JSON.stringify(info.event.extendedProps.job));
          $(this)
            .siblings('.popover')
            .on('mouseleave', function () {
              $(_this).popover('hide');
            });
        })
        .on('mouseleave', function () {
          var _this = this;
          setTimeout(function () {
            if (!$('.popover:hover').length) {
              $(_this).popover('hide');
            }
          }, 100);
        });

      $(document).on('click', '.popover .close-popup', () => {
        $(this).parents('.popover').popover('hide');
      });
    }
  };

  getDowntimeDisplayDesign(downtime: DowntimeScheduler, assetImage: string): string {
    const duration = this.calculateDuration(downtime.startDate, downtime.endDate);

    return '<div class="event_schedular">' +
      '<div class="media-body">' +
      '<div class="media-title">Downtime</div>' +
      '<div>' + downtime.assetName + '</div>' +
      `<div class="flex items-center"><span class="material-icons pr-1" style="font-size: inherit;">hourglass_empty</span><span>${moment(downtime.startDate).format('HH:mm')}-${moment(downtime.endDate).format('HH:mm')} | ${duration ? duration : "0s"}</span></div>` +
      '</div>' +
      '<div>' + assetImage + '</div>' +
      '</div>';
  }

  getTimeOffDisplayDesign(timeOff: TimeOffScheduler, assetImage: string): string {
    const duration = this.calculateDuration(timeOff.startDate, timeOff.endDate);

    return '<div class="event_schedular">' +
      '<div class="media-body">' +
      '<div class="media-title">' + timeOff.name + '</div>' +
      '<div>' + timeOff.assetName + '</div>' +
      `<div class="flex items-center"><span class="material-icons pr-1" style="font-size: inherit;">hourglass_empty</span><span>${moment(timeOff.startDate).format('HH:mm')}-${moment(timeOff.endDate).format('HH:mm')} | ${duration ? duration : "0s"}</span></div>` +
      '</div>' +
      '<div>' + assetImage + '</div>' +
      '</div>';
  }

  renderDayScheduler(props: any, isConflictView: boolean): void {
    const search = props.searchString;
    const events = [];
    const params: IODataParams = {
      filter: `(projectId eq '${this.project.id}') and isBatchJob ne true and isDeleted ne true`,
    };

    const calendarApi = this.fullCalendar?.getApi();
    if (calendarApi) {
      calendarApi.gotoDate(this.calendarOptions.initialDate);
    }

    if (search) {
      params.filter += ` and ${search}`;
    }

    this.jobODataService
      .get(params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((jobResponse) => {
        for (const job of jobResponse.value) {
          events.push(this.createJob(job));
        }

        this.highlightAssetDowntimes(this.assets, this.propsResponse.assets);

        for (const assetDowntime of this.downTimes) {
          events.push(this.createDownTime(assetDowntime));
        }

        const overlappingEvents = this.getOverlappingEvents(events);
        this.calendarOptions.events = isConflictView ? overlappingEvents : events;
        this.addHighlighEvent();
      });
  }

  getOverlappingEvents(events: any[]): any[] {
    const overlappingData: any[] = [];

    for (let i = 0; i < events.length; i++) {
      for (let j = i + 1; j < events.length; j++) {
        if (
          ((events[i].start <= events[j].start && events[i].end >= events[j].start) ||
            (events[j].start <= events[i].start && events[j].end >= events[i].start)) &&
          events[i].resourceId === events[j].resourceId
        ) {
          if (!overlappingData.includes(events[i])) {
            overlappingData.push(events[i]);
          }

          if (!overlappingData.includes(events[j])) {
            overlappingData.push(events[j]);
          }
        }
      }
    }

    return overlappingData;
  }

  handleDateSelect = (selectInfo: DateSelectArg): void => {
    const startDateTime = selectInfo.startStr;
    const endDateTime = selectInfo.endStr;

    const startDate = moment(startDateTime).format(dateFormat);
    const startTime = moment(startDateTime).format(timeExtendedFormat);

    const endDate = moment(endDateTime).format(dateFormat);
    const endTime = moment(endDateTime).format(timeExtendedFormat);

    if (
      disabledCalendarDates(new Date(startDate), this.maxDate, this.isRoleAdmin()) ||
      disabledCalendarDates(new Date(endDate), this.maxDate, this.isRoleAdmin())
    ) {
      return;
    }

    const difference = moment.utc(
      moment(endDateTime).diff(moment(startDateTime))
    );

    const endTimeInHours = difference.hours();
    const endTimeInMinutes = difference.minutes();

    let duration = '';
    if (endTimeInHours) {
      duration = `${endTimeInHours}h`;
    }
    if (endTimeInMinutes) {
      duration += ` ${endTimeInMinutes}m`;
    }

    const data = {
      startDateTime,
      endDateTime,
      startDate,
      startTime,
      endDate,
      endTime,
      endTimeInHours,
      endTimeInMinutes,
    };

    const title = `<span>${startTime} - ${endTime} | ${duration}</span>`;
    const template = `
    <div class="popover" style="max-width:600px;" >
       <div class="arrow"></div>
       <div  class="popover-header p-3 text-center font-15-px">

        <h3 class="popover-title"></h3>
       </div>
       <div class="text-center popover-content popover-body p-3 "></div>
    </div>
    `;

    const content: string =
      '<span class="btn custom-button font-16-px _btnCreateJob">Create Job</span>';

    if (this.showCreateJobTooltip) {
      // $('.popover').remove();
      $(selectInfo.jsEvent.target)
        .popover({
          title: title,
          placement: 'right',
          trigger: 'manual',
          animation: true,
          html: true,
          template: template,
          content: content,
        })
        .popover('show');

      const popover = $(selectInfo.jsEvent.target).attr('aria-describedby');
      $('.popover')
        .not('#' + popover)
        .remove();

      $(document).on('click', '._btnCreateJob', () => {
        $('.popover').remove();
        this.router.navigateByUrl('/book-job');
        this._schedulerService.autoUpdateRequestJob(data);
      });

      $(document).on('click', '.popover .close-popup', function () {
        $(this).parents('.popover').popover('hide');
      });
    } else {
      this._schedulerService.autoUpdateRequestJob(data);
    }
  };

  handleEventClick = (clickInfo: EventClickArg): void => {
    const target = $(clickInfo.jsEvent.target);
    const job: Job = clickInfo.event.extendedProps.job;
    if (!job || clickInfo.event.title === "") {
      return;
    }
    if (target.hasClass('approve-action') || target.hasClass('approve')) {
      this.openConfirmDialog('approve', job);
      return;
    }
    if (target.hasClass('cancel-action') || target.hasClass('cancel')) {
      this.openConfirmDialog('cancel', job);
      return;
    }

    if (this.router.url !== '/book-job') {
      this._jobDetailPanelService.changeData({
        jobId: clickInfo.event.id,
        assetType: job.assetType,
      });
    }

    this.jobId = clickInfo.event.id;
  };

  handleEvents = (events: EventApi[]): void => {
    this.currentEvents = events;
  };

  loadCalendarEvents = (): void => {
    const events = [];

    if (this.isMobileView && this.isMultiAssets && this.assets.length !== 0) {
      this.selectedMultiAssets.push(this.assets[0]);
      this.fullCalendar?.getApi().addResource(this.createResource(this.assets[0]));
    } else {
      let assetData: any[] = [];

      if (this.project.defaultAssets && this.project.defaultAssets.length > 0) {
        assetData = this.project.defaultAssets.slice();
        for (const asset of this.assets) {
          if (assetData.length < 5 && !assetData.some(x => x.id === asset.id)) {
            assetData.push({
              id: asset.id,
              name: asset.name
            });
          }
        }
      } else {
        assetData = this.assets.slice();
      }

      if (this.selectedMultiAssets.length === 0) {
        for (const asset of assetData.slice(0, 5)) {
          this.selectedMultiAssets.push(asset);
          this.fullCalendar?.getApi().addResource(this.createResource(asset));
        }
      }
    }

    this.callMultiAssets(events);
  };

  callMultiAssets(events: any): void {
    if (!this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      this.selectedMultiAssets.forEach((asset, idx) => {
        const $ctrl = $(`select:eq(${idx})`);
        $ctrl.val(asset.id);
        $ctrl.data('id', asset.id);
      });
    }, 100);

    if (this.selectedMultiAssets.length > 0) {
      // TODO: Verify this process
      // TODO: I think we might use this: calendarApi.gotoDate( <new date> );

      let currentDate = moment(
        this.fullCalendar?.getApi().currentData.currentDate
      );

      let filters = `(${this.selectedMultiAssets.map(x => `assetId eq '${x.id}'`).join(' or ')})`;
      let jobCategoriesString = '';

      if (this.jobCategoriesFilter) {
        const jobCategoriesMapped = `(${this.jobCategoriesFilter
          .map(x => `${x.field} eq '${x.value}'`)
          .join(' or ')})`;

        jobCategoriesString = ` and ${jobCategoriesMapped}`;
      }

      filters = `(projectId eq '${this.project.id}') and isBatchJob ne true and isDeleted ne true
              and ${filters}
              ${jobCategoriesString}
              and ( requestedStartTime le '${currentDate
          .endOf('day')
          .utc()
          .format()}' and requestedEndTime ge '${currentDate
            .startOf('day')
            .utc()
            .format()}' )`;

      const params = { filter: filters };

      if (this.sortJobsFilter) {
        params['orderBy'] = [this.sortJobsFilter];
      }

      this.jobODataService
        .get(params)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(jobsResponse => {
          for (const job of jobsResponse.value) {
            events.push(this.createJob(job));
          }

          this.highlightAssetDowntimes(this.assets, this.propsResponse.assets);

          for (const assetDowntime of this.downTimes) {
            events.push(this.createDownTime(assetDowntime));
          }

          this.calendarOptions.resources = this.selectedMultiAssets.map((x, idx) => ({
            id: x.id,
            title: x.name,
            sortKey: idx,
          }));

          this.calendarOptions.events = events;
          this.fullCalendar?.getApi().render();
          this.addHighlighEvent();
        });
    }
  }

  renderMultiAssetScheduler(): void {
    const calendarApi = this.fullCalendar?.getApi();
    if (calendarApi) {
      calendarApi.gotoDate(this.calendarOptions.initialDate);
    }

    this.loadCalendarEvents();
  }

  renderWeekScheduler(direction, numWeek: number = 0): void {
    switch (direction) {
      case 'next':
        this.week += 1;
        break;
      case 'prev':
        this.week -= 1;
        break;
      case 'customDate':
        this.week = numWeek;
        break;
      case 'normal':
        this.week += 0;
        break;
      default:
        this.week = 0;
        break;
    }

    const events = [];
    let assetIds = '';
    let jobCategoriesString = '';
    let startDate = moment()
      .add(this.week, 'weeks')
      .startOf('week')
      .utc()
      .format();
    let endWeekDate = moment()
      .add(this.week, 'weeks')
      .endOf('week')
      .utc()
      .format();

    startDate = moment(startDate).add(1, 'days').utc().format();
    endWeekDate = moment(endWeekDate).add(1, 'days').utc().format();

    if (
      this.searchObjectFromService &&
      this.searchObjectFromService.length > 0
    ) {
      const assets = this.searchObjectFromService.find(
        x => x.field === 'assets'
      );
      if (assets) {
        assetIds =
          '(' +
          assets.value.map(x => `assetId eq '${x.id}'`).join(' or ') +
          ')';
      }
    }

    if (this.jobCategoriesFilter) {
      const jobCategoriesMapped = `(${this.jobCategoriesFilter.map(x => `${x.field} eq '${x.value}'`).join(' or ')})`;
      jobCategoriesString = ` and ${jobCategoriesMapped}`;
    }

    const dateFilter = `(requestedStartTime ge '${startDate}' and requestedStartTime le '${endWeekDate}')`;
    const filters = `(projectId eq '${this.project.id}')  and isBatchJob ne true and isDeleted ne true and ${assetIds} and ${dateFilter} ${jobCategoriesString}`;
    const params = { filter: filters };

    const calendarApi = this.fullCalendar?.getApi();
    if (calendarApi) {
      calendarApi.gotoDate(startDate); // this is used to update defaultdate or else it will not fetch events dynamically
    }

    startDate = moment(startDate).format('YYYY-MM-DD') + 'T00:00:00';
    this.jobODataService.get(params).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      for (const job of response.value) {
        events.push(this.createJob(job));
      }

      this.highlightAssetDowntimes(this.assets, this.propsResponse.assets);
      this.highlightAssetTimeOffs(
        this.assets,
        this.propsResponse.assets,
      );

      for (const assetDowntime of this.downTimes) {
        events.push(this.createDownTime(assetDowntime));
      }

      for (const assetTimeOff of this.timeOffs) {
        events.push(this.createTimeOff(assetTimeOff));
      }

      const overlappingEvents = this.getOverlappingEvents(events);
      this.calendarOptions.events = this.jobsConfig.title === 'Conflict Jobs' ? overlappingEvents : events;
      this.addHighlighEvent();
    });
  }

  renderMonthScheduler(direction): void {
    switch (direction) {
      case 'next':
        this.month += 1;
        break;
      case 'prev':
        this.month -= 1;
        break;
      default:
        this.month = 0;
        break;
    }

    const events = [];
    let assetIds = '';
    let startDate = moment()
      .add(this.month, 'months')
      .startOf('month')
      .utc()
      .format();
    const endMonthDate = moment()
      .add(this.month, 'months')
      .endOf('month')
      .utc()
      .format();

    if (
      this.searchObjectFromService &&
      this.searchObjectFromService.length > 0
    ) {
      const assets = this.searchObjectFromService.find(
        x => x.field === 'assets'
      );
      if (assets) {
        assetIds =
          '(' +
          assets.value.map(x => `assetId eq '${x.id}'`).join(' or ') +
          ')';
      }
    }

    const dateFilter = `(requestedStartTime ge '${startDate}' and requestedStartTime le '${endMonthDate}')`;
    const filters = `(projectId eq '${this.project.id}') and isBatchJob ne true and isDeleted ne true and ${assetIds} and ${dateFilter}`;
    const params = { filter: filters };

    const calendarApi = this.fullCalendar?.getApi();
    if (calendarApi) {
      calendarApi.gotoDate(startDate); // this is used to update defaultdate or else it will not fetch events dynamically
    }

    startDate = moment(startDate).format('YYYY-MM-DD') + 'T00:00:00';
    this.jobODataService.get(params).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      for (const job of response.value) {
        events.push(this.createJob(job));
      }

      this.highlightAssetDowntimes(this.assets, this.propsResponse.assets);
      this.highlightAssetTimeOffs(
        this.assets,
        this.propsResponse.assets,
      );

      for (const assetDowntime of this.downTimes) {
        events.push(this.createDownTime(assetDowntime));
      }

      for (const assetTimeOff of this.timeOffs) {
        events.push(this.createTimeOff(assetTimeOff));
      }

      this.calendarOptions.events = events;
      this.addHighlighEvent();
    });
  }

  next = (): void => {
    if (this.config.view === 'resourceTimeGridDay') {
      const calendarApi = this.fullCalendar?.getApi();
      if (calendarApi) {
        calendarApi.next();
      }

      this.loadCalendarEvents();
    } else if (this.config.view === 'timeGridDay') {
      const calendarApi = this.fullCalendar?.getApi();
      if (calendarApi) {
        calendarApi.next();
        if (this.router.url === '/jobs') {
          const selectedDate = calendarApi.currentData.currentDate;
          this.addOrUpdateFilter('requestedStartTime', formatDate(selectedDate));
          this.dispatchFilterScheduler();
        } else if (this.router.url === '/jobs/multi-assets') {
          this.loadCalendarEvents();
        }
      }
    } else if (this.config.view === 'dayGridMonth') {
      const calendarApi = this.fullCalendar?.getApi();
      if (calendarApi) {
        calendarApi.next();
      }

      this.renderMonthScheduler('next');
    }
    else {
      const calendarApi = this.fullCalendar?.getApi();
      if (calendarApi) {
        calendarApi.next();
      }

      this.renderWeekScheduler('next');
    }
  };

  prev = (): void => {
    if (this.config.view === 'resourceTimeGridDay') {
      const calendarApi = this.fullCalendar?.getApi();
      if (calendarApi) {
        calendarApi.prev();
      }

      this.loadCalendarEvents();
      // if (this.skip > 0) {
      //   this.skip -= this.top;
      // }

      // this.renderMultiAssetScheduler(this.top, this.skip);
    } else if (this.config.view === 'timeGridDay') {
      const calendarApi = this.fullCalendar?.getApi();
      if (calendarApi) {
        calendarApi.prev();
        if (this.router.url === '/jobs') {
          const selectedDate = calendarApi.currentData.currentDate;
          this.addOrUpdateFilter('requestedStartTime', formatDate(selectedDate));
          this.dispatchFilterScheduler();
        } else if (this.router.url === '/jobs/multi-assets') {
          this.loadCalendarEvents();
        }
      }
    } else if (this.config.view === 'dayGridMonth') {
      const calendarApi = this.fullCalendar?.getApi();
      if (calendarApi) {
        calendarApi.prev();
      }
      this.renderMonthScheduler('prev');
    }
    else {
      const calendarApi = this.fullCalendar?.getApi();
      if (calendarApi) {
        calendarApi.prev();
      }
      this.renderWeekScheduler('prev');
    }
  };

  calculateDuration = (startTime, endTime): string => {
    const start = moment(startTime);
    const end = moment(endTime);
    const duration = moment.utc(end.diff(start));
    const hours = duration.hours();
    const minutes = duration.minutes();
    let durationStr = '';
    if (hours) {
      durationStr = hours + 'h';
    }
    if (minutes) {
      durationStr = durationStr + ' ' + minutes + 'm';
    }
    return durationStr;
  };

  openConfirmDialog(action: string, job: Job) {
    localStorage.removeItem('job');
    if (action === 'approve') {
      this.jobODataService.checkApprovedJobsExists(job, this.project.id).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
        if (response.count > 0) {
          const jobs = response.value.filter(
            x =>
              x.requestedStartTime <= job.requestedEndTime &&
              x.requestedEndTime >= job.requestedStartTime
          );
          if (jobs.length === 0) {
            this.openDialogWithCallback(action, job);
          } else {
            const isDoubleBookingEnabled = this.project.appSettings.find(
              x => x.name === 'Double Booking' && x.isChecked
            );
            if (!isDoubleBookingEnabled) {
              this.showDuplicateJobError();
            } else {
              this.openDialogWithCallback(action, job);
            }
          }
        } else {
          this.openDialogWithCallback(action, job);
        }
      });
    } else {
      this.jobODataService.checkApprovedJobsExists(job, this.project.id).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
        this.openDialogWithCallback(action, job);
      });
    }
  }

  openDialogWithCallback(action: string, job: Job) {
    this.dialogRef = this._matDialog.open(JobConfirmDialogComponent, {
      data: getDialogInfo(action),
      width: '500px',
    });

    this.dialogRef.afterClosed().subscribe((response: string) => {
      if (!response) {
        return;
      }

      if (action === 'approve') {
        this.approveJob(job);
      }
      // cancel
      else {
        this.cancelJob(job);
      }
    });
  }

  showDuplicateJobError = (): void => {
    this.dialogRef = this._matDialog.open(JobConfirmDialogComponent, {
      data: getDialogInfo('error-duplicate-jobs'),
      width: '500px',
    });
  };

  cancelJob = (job: Job): void => {
    job.status = 'cancelled';
    job.jobsHistory =
      job.jobsHistory && job.jobsHistory.length > 0 ? job.jobsHistory : [];
    job.jobsHistory.push(getHistory('Job Cancelled', this.user));
    this.jobODataService.put(job.id, job).subscribe(() => {
      showJobStatusSnackbar(this._snackBar, 'Cancelled');
      this.jobService.refreshJobs();
      this.jobService.refreshSchedulerJobs();
    });
  };

  approveJob = (job: Job): void => {
    job.status = 'approved';
    job.jobsHistory =
      job.jobsHistory && job.jobsHistory.length > 0 ? job.jobsHistory : [];
    job.jobsHistory.push(getHistory('Job Approved', this.user));
    this.jobODataService.put(job.id, job).subscribe(() => {
      showJobStatusSnackbar(this._snackBar, 'Approved');
      this.jobService.refreshJobs();
      this.jobService.refreshSchedulerJobs();
    });
  };

  updateJob = (job: Job): void => {
    job.jobsHistory =
      job.jobsHistory && job.jobsHistory.length > 0 ? job.jobsHistory : [];
    job.jobsHistory.push(getHistory('Job Updated', this.user));

    this.jobODataService.put(job.id, job).subscribe(() => {
      showJobStatusSnackbar(this._snackBar, 'Updated');
      this.jobService.refreshJobs();
      this.jobService.refreshSchedulerJobs();
    });
  };

  checkUserSettings(key: string): boolean {
    return this.user?.accessManagement?.settings.filter(
      x => x.name === key && x.isChecked
    ).length > 0
      ? true
      : false;
  }

  addHighlighEvent = (): void => {
    this.fullCalendar
      ?.getApi()
      ?.getEventById(this.highlightEvent?.id)
      ?.remove();
    if (this.highlightEvent) {
      const calendarApi = this.fullCalendar?.getApi();
      if (calendarApi) {
        calendarApi.addEvent(this.highlightEvent);
      }
    }
  };

  ngOnDestroy(): void {
    this._jobDetailPanelService.changeData(null);
    $('.popover').remove();
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  highlightAssetDowntimes(
    allAssets: Asset[],
    selectedAssets: Asset[],
  ) {
    const searchedAssetIds = selectedAssets.map(x => x.id);
    const assetDowntimes = allAssets
      .filter(x => searchedAssetIds.indexOf(x.id) !== -1 && !!x.downtime)
      .map(x => ({
        assetId: x.id,
        assetName: x.name,
        assetType: x.type,
        downtimes: x.downtime,
      }));

    const formattedArray = assetDowntimes.map(x =>
      x.downtimes.map(y => ({
        id: uuidv4(),
        assetId: x.assetId,
        assetName: x.assetName,
        assetType: x.assetType,
        startDate: y.startDate,
        endDate: y.endDate,
      }))
    );

    this.downTimes = [];

    formattedArray.forEach(element => {
      element.forEach(downtime => {
        this.downTimes.push(downtime);
      });
    });
  }

  highlightAssetTimeOffs(
    allAssets: Asset[],
    selectedAssets: Asset[],
  ) {
    this.timeOffs = [];
    const searchedAssetIds = selectedAssets.map(x => x.id);
    const assetTimeOffs = allAssets
      .filter(x => searchedAssetIds.indexOf(x.id) !== -1 && !!x.timeOff)
      .map(x => ({
        assetId: x.id,
        assetName: x.name,
        assetType: x.type,
        timeOffs: x.timeOff,
      }));

    assetTimeOffs.forEach(x =>
      x.timeOffs.forEach(y => {
        if (y.eventList && y.eventList.length > 0) {
          y.eventList.forEach(z => {
            this.timeOffs.push({
              id: uuidv4(),
              name: y.titleName,
              assetId: x.assetId,
              assetName: x.assetName,
              assetType: x.assetType,
              startDate: z.start,
              endDate: z.end,
            });
          });
        }
      })
    );
  }

  selectProject = () => {
    this.store
      .select(selectProject)
      .pipe(filter(project => !!project && !!project.id), takeUntil(this._unsubscribeAll))
      .subscribe(project => {
        if (this.project !== null) {
          this.selectedFilters = null;
        }

        if (project.maxDate && project.maxDate !== 0) {
          this.maxDate.setDate(this.maxDate.getDate() + project.maxDate);
        } else {
          this.maxDate.setDate(this.maxDate.getDate() + 1000);
        }

        this.project = project;
        this.getAssets();

        this.jobService.schedulerJobsRefreshed.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
          this.store.select(selectIsMobileView).pipe(takeUntil(this._unsubscribeAll)).subscribe(isMobileView => {
            this.loadScheduler(isMobileView ? 'responsive' : '');
          });
        });
      });
  };

  selectUser = () => {
    this.store
      .select(selectUser)
      .pipe(filter(x => !!x.id), takeUntil(this._unsubscribeAll))
      .subscribe(user => {
        this.user = user;
        this.selectProject();
      });
  };

  setScrollTime = () => {
    this._schedulerService.getScrollTime
      .pipe(filter(x => !!x), takeUntil(this._unsubscribeAll))
      .subscribe(response => {
        const startTimeObj = moment(response.startTime);
        const hours = startTimeObj.hours() - 4;
        const minutes = startTimeObj.minutes();

        const calendarApi = this.fullCalendar?.getApi();

        if (calendarApi) {
          this.calendarOptions.initialDate = startTimeObj.format(dateFormat);
          calendarApi.scrollToTime({
            hour: hours,
            minute: minutes,
          });
        }

        this.highlightEvent = {
          id: 'test',
          title: '',
          start: response.startTime,
          end: response.endTime,
          // classNames: null,
          // backgroundColor: job.contractorColor,
          allDay: false,
          // extendedProps: { job: job },
        };
        this.addHighlighEvent();
      });
  };

  addOrUpdateFilter(field, value): void {
    this.searchObject = [...this.searchObject];
    _.remove(this.searchObject, x => x.field === field);
    this.searchObject.push({
      field: field,
      value: value,
    });

    this.searchStringForScheduler = this.searchObject
      .map(x => {
        let filter = '';
        if (x.field === 'requestedStartTime') {
          filter = `( requestedStartTime le '${moment(x.value)
            .endOf('day')
            .utc()
            .format()}' and requestedEndTime ge '${moment(x.value)
              .startOf('day')
              .utc()
              .format()}' )`;
        } else {
          filter =
            '(' + x.value.map(a => `assetId eq '${a.id}'`).join(' or ') + ')';
        }
        return filter;
      })
      .join(' and ');
  }

  filterScheduler(event, type): void {
    if (type === 'asset') {
      if (event?.length > this.maxAssetsSelection) {
        this.selectedAssets = _.take(event, this.maxAssetsSelection);
        showSnackbar(this._snackBar, `You can select a maximum of ${this.maxAssetsSelection} Assets`, SNACKBAR_ACTION_ERROR);
        return;
      } else if (event?.length === 0) {
        this.selectedAssets = this.assets;
        this.addOrUpdateFilter('assets', this.selectedAssets);
        this.dispatchFilterScheduler();
        this.selectedAssets = [];
        return;
      }
      this.selectedAssets = _.take(event, this.maxAssetsSelection);
      this.addOrUpdateFilter('assets', this.selectedAssets);
    } else if (type === 'date') {
      this.addOrUpdateFilter('requestedStartTime', formatDate(event.value));
      if (this.config.view === 'dayGridWeek' || this.config.view === 'timeGridWeek') {
        const selectedWeekNumber = moment(event.value, "MM-DD-YYYY").week();
        const currentWeekNumber = moment(new Date(), "MM-DD-YYYY").week();

        if (currentWeekNumber === selectedWeekNumber) {
          this.renderWeekScheduler('');
        }

        else {
          this.renderWeekScheduler('customDate', selectedWeekNumber - currentWeekNumber);
        }
      }
    }

    this.dispatchFilterScheduler();
  }

  sortJobs(value): void {
    if (value === '1') {
      this.sortJobsFilter = 'createdDate desc';
      this.renderMultiAssetScheduler();
    } else if (value === '2') {
      this.sortJobsFilter = 'requestedStartTime desc';
      this.renderMultiAssetScheduler();
    }
  }

  changeConfigDetails(category: string): void {
    this.sortJobsFilter = '';
    switch (category) {
      case 'All':
        this.jobsConfig = {
          title: 'All',
          view: 'full-view',
          edit: true,
          filter: {
            myJobsOnly: false,
            activeJobs: false,
            completedJobs: false,
            sorting: false,
          },
          filterDataBy: 'all',
          sortDataBy: 'recent',
          parameters: null,
          showTodayJobs: false,
        };

        this.jobCategoriesFilter = null;
        if (this.router.url === '/jobs/working-week') {
          this.renderWeekScheduler('normal');
        } else if (this.router.url === '/jobs/multi-assets') {
          this.renderMultiAssetScheduler();
        }

        break;

      case 'Recent Jobs':
        this.jobsConfig = {
          title: 'Recent Jobs',
          view: 'full-view',
          edit: true,
          filterDataBy: 'approved,completed',
          sortDataBy: 'recent',
          showTodayJobs: false,
        };

        const filtersRecent = this.getConfigFilters();
        this.jobCategoriesFilter = filtersRecent;
        if (this.router.url === '/jobs/working-week') {
          this.renderWeekScheduler('normal');
        } else if (this.router.url === '/jobs/multi-assets') {
          this.renderMultiAssetScheduler();
        }

        break;

      case 'Requested Jobs':
        this.jobsConfig = {
          title: 'Requested Jobs',
          view: 'full-view',
          edit: true,
          filter: {
            sorting: true,
          },
          filterDataBy: 'requested',
          sortDataBy: 'recent',
          showTodayJobs: false,
        };

        const filtersRequested = this.getConfigFilters();
        this.jobCategoriesFilter = filtersRequested;
        if (this.router.url === '/jobs/working-week') {
          this.renderWeekScheduler('normal');
        } else if (this.router.url === '/jobs/multi-assets') {
          this.renderMultiAssetScheduler();
        }

        break;

      case 'Conflict Jobs':
        this.jobsConfig = {
          title: 'Conflict Jobs',
          view: 'full-view',
          edit: true,
          filter: {
            myJobsOnly: false,
            activeJobs: false,
            completedJobs: false,
            sorting: false,
          },
          filterDataBy: 'all',
          sortDataBy: 'recent',
          parameters: null,
          showTodayJobs: false,
        };

        this.jobCategoriesFilter = null;
        this.renderWeekScheduler('normal');
        break;
    }
  }

  getConfigFilters(): any[] {
    let filters = [];

    if (
      this.jobsConfig.filterDataBy &&
      this.jobsConfig.filterDataBy !== 'all'
    ) {
      if (this.jobsConfig.filterDataBy.includes(',')) {
        const statuses = this.jobsConfig.filterDataBy
          .split(',')
          .map(x => ({ field: 'status', value: x }));
        filters = statuses;
      } else {
        filters.push({
          field: 'status',
          value: this.jobsConfig.filterDataBy,
        });
      }
    }

    return filters;
  }

  isUserRoleContractor(job: Job): boolean {
    return isRolePermissionAllowed('manage-job-access', this.userAccess) && job.requesterEmail !== this.user.principalName;
  }

  isRoleAdmin = (): boolean => (this.user.roleName === 'Admin' || this.user.roleName === 'Global Admin');

  private dispatchFilterScheduler(): void {
    const data = {
      assets: this.selectedAssets,
      searchString: this.searchStringForScheduler,
      searchObject: this.searchObject,
    };

    this.store.dispatch(new FilterScheduler(data));
    this.searchStringForScheduler = '';
  }
}
