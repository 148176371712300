import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TimeLog } from 'app/core/interfaces';
import { calculateDuration } from 'app/core/utils/date-time.utils';
import * as moment from 'moment';

@Component({
    selector: 'app-job-time-logs-list',
    templateUrl: './job-time-logs-list.component.html',
    styleUrls: ['./job-time-logs-list.component.scss'],
})

export class JobTimeLogsListComponent implements OnInit, AfterViewInit {
    @Input() timeLogs: TimeLog[] = [];
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns: string[] = ['operator', 'start', 'duration'];
    dataSource: MatTableDataSource<TimeLog>;

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<TimeLog>(this.timeLogs || []);
    }

    formatDate = (date): string => moment(date).format('lll');

    getDuration(startTime, endTime): string {
        return startTime && endTime ? calculateDuration(startTime, endTime) : '---';
    }
}
