import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project } from 'app/core/interfaces';
import { Observable } from 'rxjs';
import { BaseODataService } from './base-odata.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectODataService extends BaseODataService<Project> {
  constructor(protected http: HttpClient) {
    super(http, 'projects');
  }

  removeProject(projectId: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/remove-project/${projectId}`, null);
  }
}
