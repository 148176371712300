import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { User } from '../interfaces/user';
import { SetUserAction } from '../store/actions/auth.actions';
import { AppState } from '../store/reducers/app.reducer';
import {
  selectIsUserLoading,
  selectUser
} from '../store/selectors/auth.selectors';
import { AuthService } from './custom-services/auth.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  user: User;
  constructor(
    private store: Store<AppState>,
    private _localStorageService: LocalStorageService,
    private authService: AuthService,
    private router: Router
  ) {
    this.store
      .select(selectUser)
      .pipe(filter(x => !!x))
      .subscribe((user) => {
        this.user = user;

      });
  }

  isLoggedIn = (): boolean => {
    let response = false;
    if (this.user?.id) {
      response = true;
    }
    return response;
  };

  isRouteAllowed = async (url: string): Promise<boolean> => {
    await this.loadUser();

    if (!this.user?.accessManagement) {
      const token = String(this._localStorageService.get('token'));

      if (!!token && token !== 'null') {
        const user = JSON.parse(atob(token.split('.')[1]));
        const response = await this.authService.getUserById(user.Id).toPromise();

        this.store.dispatch(new SetUserAction(response));
        this.user = response;

        const userSettings = this.user.accessManagement?.settings || [];
        return userSettings.filter(x => x.path && x.path.filter(y => url.indexOf(y) === 0).length > 0 && x.isChecked).length > 0;
      } else {
        this.router.navigate(['/auth/login']);
      }
    } else {
      const userSettings = this.user.accessManagement?.settings || [];
      return userSettings.filter(x => x.path && x.path.filter(y => url.indexOf(y) === 0).length > 0 && x.isChecked).length > 0;
    }
  };

  async loadUser() {
    const isUserLoading = await this.store
      .pipe(select(selectIsUserLoading), take(1))
      .toPromise();

    if (isUserLoading) {
      this.user = await this.store
        .pipe(
          select(selectUser),
          filter(user => !!user && !!user.id),
          take(1)
        )
        .toPromise();
    } else {
      this.user = await this.store
        .pipe(select(selectUser), take(1))
        .toPromise();
    }
  }
}
