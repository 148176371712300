import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Asset, DateList, DayList, RecurrenceEvents, RecurrenceStoreData } from 'app/core/interfaces';
import { BaseIdName } from 'app/core/interfaces/base';
import { SetRecurrenceData } from 'app/core/store/actions/common.actions';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { SNACKBAR_ACTION_DENIED, showSnackbar } from 'app/core/utils/utils';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

enum RecurrenceType {
  DontRepeat = "dontRepeat",
  Daily = "daily",
  Weekly = "weekly",
  Custom = "custom",
}

@Component({
  selector: 'app-recurrence',
  templateUrl: './recurrence.component.html',
  styleUrls: ['./recurrence.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class RecurrenceComponent {
  @Input() assets: Asset[];
  @Input() type: string;
  recurrenceTypeList: BaseIdName[] = [
    { id: RecurrenceType.DontRepeat, name: "Don't repeat" },
    { id: RecurrenceType.Daily, name: "Daily" },
    { id: RecurrenceType.Weekly, name: "Weekly" },
    { id: RecurrenceType.Custom, name: "Custom" },
  ];

  daysList: DayList[] = [
    { id: 'monday', name: 'M', selected: true, },
    { id: 'tuesday', name: 'T', selected: true, },
    { id: 'wednesday', name: 'W', selected: true, },
    { id: 'thursday', name: 'T', selected: true, },
    { id: 'friday', name: 'F', selected: true, },
    { id: 'saturday', name: 'S', selected: true, },
    { id: 'sunday', name: 'S', selected: true, },
  ];

  form: FormGroup;
  selectedDaysMsg: string = '';
  selectedDates: DateList[] = [];
  RecurrenceType = RecurrenceType;
  public dateTimeFormat = "dd/MM/yyyy hh:mm a";
  public dateFormat = "dd/MM/yyyy";
  public timeFormat = "hh:mm a";
  public readOnlyInput = true;
  private momentDateFormat: string = 'DD/MM/YYYY';

  constructor(
    private _store: Store<AppState>,
    private _snackbar: MatSnackBar,
  ) {
    this.form = this.createFormGroup();
  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      assetId: new FormControl(null),
      titleName: new FormControl(null),
      recurrenceType: new FormControl(RecurrenceType.DontRepeat),
      startDateTime: new FormControl(null),
      endDateTime: new FormControl(null),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      startTime: new FormControl(null),
      endTime: new FormControl(null),
      weeklyDays: new FormControl(null),
      customDates: new FormControl(null),
    });
  }

  toggleDays(dayId: string): void {
    const day = this.daysList.find(x => x.id === dayId);

    if (this.getSelectedWeeklyDays().length === 1 && day.selected) {
      showSnackbar(this._snackbar, 'Unable to unselect all weekly days', SNACKBAR_ACTION_DENIED);
      return;
    }

    day.selected = !day.selected;
    this.setRecurrenceMsg();
    this.form.patchValue({
      weeklyDays: this.getSelectedWeeklyDays(),
    });
  }

  setRecurrenceMsg(): void {
    this.selectedDaysMsg = `Occurs every ${this.getSelectedWeeklyDays().map(x => x.id).join(', ')}`;
  }

  onCustomDatesSelection(value: Date): void {
    const displayFormattedDate = moment(value).format(`dd, ${this.momentDateFormat}`);
    const isDateExist = this.isSelected(value);

    if (!isDateExist) {
      this.selectedDates = [
        ...this.selectedDates,
        {
          displayDate: displayFormattedDate,
          date: value,
        },
      ];
    } else {
      this.selectedDates = this.selectedDates.filter(x => x.displayDate !== displayFormattedDate);
    }

    this.form.patchValue({
      customDates: this.selectedDates,
    });
  }

  isSelected(date: Date): boolean {
    return this.selectedDates.some(highlightedDate =>
      date.getFullYear() === highlightedDate.date.getFullYear() &&
      date.getMonth() === highlightedDate.date.getMonth() &&
      date.getDate() === highlightedDate.date.getDate()
    );
  }

  setRecurrenceType(selectedValue: BaseIdName): void {
    if (selectedValue.id === RecurrenceType.Weekly) {
      this.setRecurrenceMsg();
    }
  }

  closeDialog(): void {
    this._store.dispatch(new SetRecurrenceData({
      formValues: null,
    }));
  }

  submit(): void {
    const { recurrenceType, startDateTime, endDateTime, startDate, endDate, startTime, endTime } = this.form.value;
    let data: RecurrenceStoreData = null;

    if (recurrenceType === RecurrenceType.DontRepeat) {
      data = {
        formValues: {
          ...this.form.value,
          id: uuidv4(),
          eventList: [{
            start: moment.utc(startDateTime).format(),
            end: moment.utc(endDateTime).format(),
          }],
        },
      };
    } else if (recurrenceType === RecurrenceType.Daily) {
      const recurrenceStartDate = moment(startDate, this.momentDateFormat);
      const recurrenceEndDate = moment(endDate, this.momentDateFormat);
      const events: RecurrenceEvents[] = [];
      let currentDate = recurrenceStartDate.clone();

      while (currentDate.isSameOrBefore(recurrenceEndDate, 'day')) {
        const startEventSDT = `${moment(currentDate).format(this.momentDateFormat)} ${moment(startTime).format(this.timeFormat)}`;
        const combinedStartEventSDTMoment = moment(startEventSDT, `${this.momentDateFormat} ${this.timeFormat}`);
        const formattedStartEventSDTDateTime = combinedStartEventSDTMoment.utc().format();

        const endEventSDT = `${moment(currentDate).format(this.momentDateFormat)} ${moment(endTime).format(this.timeFormat)}`;
        const combinedEndEventSDTMoment = moment(endEventSDT, `${this.momentDateFormat} ${this.timeFormat}`);
        const formattedEndEventSDTDateTime = combinedEndEventSDTMoment.utc().format();

        events.push({
          start: formattedStartEventSDTDateTime,
          end: formattedEndEventSDTDateTime,
        });

        currentDate.add(1, 'day');
      }

      data = {
        formValues: {
          ...this.form.value,
          id: uuidv4(),
          eventList: events,
        },
      };
    } else if (recurrenceType === RecurrenceType.Weekly) {
      const recurrenceStartDate = moment(startDate, this.momentDateFormat);
      const recurrenceEndDate = moment(endDate, this.momentDateFormat);
      const events: RecurrenceEvents[] = [];
      let currentDate = recurrenceStartDate.clone();

      while (currentDate.isSameOrBefore(recurrenceEndDate, 'day')) {
        const dayId = currentDate.format('dddd').toLowerCase();
        const isDaySelected = this.daysList.find(day => day.id === dayId)?.selected;
        if (isDaySelected) {
          const startEventSDT = `${moment(currentDate).format(this.momentDateFormat)} ${moment(startTime).format(this.timeFormat)}`;
          const combinedStartEventSDTMoment = moment(startEventSDT, `${this.momentDateFormat} ${this.timeFormat}`);
          const formattedStartEventSDTDateTime = combinedStartEventSDTMoment.utc().format();

          const endEventSDT = `${moment(currentDate).format(this.momentDateFormat)} ${moment(endTime).format(this.timeFormat)}`;
          const combinedEndEventSDTMoment = moment(endEventSDT, `${this.momentDateFormat} ${this.timeFormat}`);
          const formattedEndEventSDTDateTime = combinedEndEventSDTMoment.utc().format();

          events.push({
            start: formattedStartEventSDTDateTime,
            end: formattedEndEventSDTDateTime,
          });
        }

        currentDate.add(1, 'day');
      }

      data = {
        formValues: {
          ...this.form.value,
          id: uuidv4(),
          eventList: events,
        },
      };
    } else if (recurrenceType === RecurrenceType.Custom) {
      const events: RecurrenceEvents[] = [];

      for (const sd of this.selectedDates) {
        const startEventSDT = `${moment(sd.date).format(this.momentDateFormat)} ${moment(startTime).format(this.timeFormat)}`;
        const combinedStartEventSDTMoment = moment(startEventSDT, `${this.momentDateFormat} ${this.timeFormat}`);
        const formattedStartEventSDTDateTime = combinedStartEventSDTMoment.utc().format();

        const endEventSDT = `${moment(sd.date).format(this.momentDateFormat)} ${moment(endTime).format(this.timeFormat)}`;
        const combinedEndEventSDTMoment = moment(endEventSDT, `${this.momentDateFormat} ${this.timeFormat}`);
        const formattedEndEventSDTDateTime = combinedEndEventSDTMoment.utc().format();

        events.push({
          start: formattedStartEventSDTDateTime,
          end: formattedEndEventSDTDateTime,
        });
      }

      data = {
        formValues: {
          ...this.form.value,
          id: uuidv4(),
          eventList: events,
        },
      };
    }

    if (data.formValues.eventList && data.formValues.eventList.length > 0) {
      this._store.dispatch(new SetRecurrenceData(data));
    }
  }

  private getSelectedWeeklyDays = (): DayList[] => this.daysList.filter(x => x.selected);
}
