import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { LabelModule } from "@progress/kendo-angular-label";
import { AddAreaInAssetDrawerComponent } from 'app/components/settings/add-area-in-asset-drawer/add-area-in-asset-drawer.component';
import { CreateContractorDrawerComponent } from 'app/components/settings/create-contractor-drawer/create-contractor-drawer.component';
import { SharedModule } from 'app/components/shared/shared.module';
import { ProjectStarterModule } from 'app/layout/common/project-starter/project-starter.module';
import { SettingsModule as DrawerSettingModule } from 'app/layout/common/settings/settings.module';
import { UserMenuModule } from 'app/layout/common/user-menu/user-menu.module';
import { DenseLayoutComponent } from 'app/layout/layouts/vertical/dense/dense.component';
import { SharedModule as SharedModule2 } from 'app/shared/shared.module';

@NgModule({
    declarations: [
        DenseLayoutComponent,
        CreateContractorDrawerComponent,
        AddAreaInAssetDrawerComponent,
    ],
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseLoadingBarModule,
        FuseNavigationModule,
        SharedModule,
        MatNativeDateModule,
        MatDatepickerModule,
        UserMenuModule,
        ProjectStarterModule,
        SharedModule2,
        MatTooltipModule,
        DrawerSettingModule,
        LabelModule,
        DropDownsModule,
        FuseDrawerModule
    ],
    exports: [
        DenseLayoutComponent
    ]
})
export class DenseLayoutModule {
}
