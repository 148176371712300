import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { FuseCardModule } from '@fuse/components/card';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormFieldModule, InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule, IntlService } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { CdTimerModule } from 'angular-cd-timer';
import { CustomDateAdapter } from 'app/core/adapters/custom-date-adapter';
import { CustomKendoDateAdapter } from 'app/core/adapters/custom-kendo-date-adapter';
import { CountdownModule } from 'ngx-countdown';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SwiperModule } from 'swiper/angular';
import { EventsListDialogComponent } from '../settings/timeoff-assets/events-list-dialog/events-list-dialog.component';
import { AssetMapComponent } from './asset-map/asset-map.component';
import { CheckInDetailComponent } from './check-in-detail/check-in-detail.component';
import { CheckInOutImageUploadDialogComponent } from './check-in-out-image-upload-dialog/check-in-out-image-upload-dialog.component';
import { EditJobComponent } from './edit-job/edit-job.component';
import { GroupedJobsComponent } from './grouped-jobs/grouped-jobs.component';
import { ImageListComponent } from './image-list/image-list.component';
import { JobCardComponent } from './job-card/job-card.component';
import { JobCommentsComponent } from './job-comments/job-comments.component';
import { JobConfirmDialogComponent } from './job-confirm-dialog/job-confirm-dialog.component';
import { JobDetailComponent } from './job-detail/job-detail.component';
import { JobHistoryComponent } from './job-history/job-history.component';
import { JobListComponent } from './job-list/job-list.component';
import { JobNotesComponent } from './job-notes/job-notes.component';
import { JobPackagesComponent } from './job-packages/job-packages.component';
import { JobSchedulerComponent } from './job-scheduler/job-scheduler.component';
import { JobSearchDialogComponent } from './job-search-dialog/job-search-dialog.component';
import { JobTimeLogsListComponent } from './job-time-logs-list/job-time-logs-list.component';
import { JobsImportComponent } from './jobs-import/jobs-import.component';
import { LowerToolbarComponent } from './lower-toolbar/lower-toolbar.component';
import { RecurrenceComponent } from './recurrence/recurrence.component';
import { RequestJobAssetDetailsComponent } from './request-job-assets-details/request-job-assets-details.component';
import { RequestJobComponent } from './request-job/request-job.component';
import { ShimmerLoaderComponent } from './shimmer-loader/shimmer-loader.component';

FullCalendarModule.registerPlugins([
    dayGridPlugin,
    timeGridPlugin,
    resourceTimeGridPlugin,
    interactionPlugin,
]);

@NgModule({
    declarations: [
        CheckInDetailComponent,
        EditJobComponent,
        JobCardComponent,
        JobDetailComponent,
        JobHistoryComponent,
        JobListComponent,
        JobSchedulerComponent,
        JobTimeLogsListComponent,
        LowerToolbarComponent,
        RequestJobComponent,
        RequestJobAssetDetailsComponent,
        JobSearchDialogComponent,
        JobConfirmDialogComponent,
        JobsImportComponent,
        AssetMapComponent,
        CheckInOutImageUploadDialogComponent,
        ImageListComponent,
        GroupedJobsComponent,
        JobNotesComponent,
        ShimmerLoaderComponent,
        JobCommentsComponent,
        JobPackagesComponent,
        RecurrenceComponent,
        EventsListDialogComponent,
    ],
    imports: [
        CdTimerModule,
        CommonModule,
        CountdownModule,
        DropDownsModule,
        FormFieldModule,
        FullCalendarModule,
        LabelModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatDialogModule,
        RouterModule,
        UploadsModule,
        ButtonsModule,
        SlickCarouselModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        FuseDrawerModule,
        MatExpansionModule,
        MatTooltipModule,
        SwiperModule,
        // MatButtonToggleModule,
        DateInputsModule,
        IntlModule,
        FlexLayoutModule,
        InfiniteScrollModule,
        NgxShimmerLoadingModule,
        InputsModule,
        FuseCardModule,
    ],
    exports: [
        CheckInDetailComponent,
        EditJobComponent,
        JobCardComponent,
        JobDetailComponent,
        JobHistoryComponent,
        JobNotesComponent,
        JobListComponent,
        JobSchedulerComponent,
        JobTimeLogsListComponent,
        LowerToolbarComponent,
        RequestJobComponent,
        JobsImportComponent,
        AssetMapComponent,
        ImageListComponent,
        JobCommentsComponent,
        JobPackagesComponent,
        RecurrenceComponent,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatMenuModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatTabsModule,
        MatChipsModule,
        InputsModule,
        ButtonsModule,
        MatExpansionModule
    ],
    providers: [
        MatDatepickerModule,
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: IntlService, useClass: CustomKendoDateAdapter },
    ],
    entryComponents: [JobSearchDialogComponent, JobConfirmDialogComponent],
})
export class SharedModule { }
