import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contractor } from 'app/core/interfaces';
import { BaseODataService } from './base-odata.service';

@Injectable({
  providedIn: 'root',
})
export class ContractorODataService extends BaseODataService<Contractor> {
  constructor(protected http: HttpClient) {
    super(http, 'contractors');
  }
}
