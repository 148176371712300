import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Zone } from '../../interfaces';
import { BaseODataService } from './base-odata.service';

@Injectable({
  providedIn: 'root',
})
export class ZoneODataService extends BaseODataService<Zone> {
  constructor(protected http: HttpClient) {
    super(http, 'zones');
  }
}
