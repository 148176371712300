import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  ClearSpinnerAction,
  StartSpinnerAction,
  StopSpinnerAction,
} from '../store/actions/common.actions';
import { AppState } from '../store/reducers/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  isPageLoadSpinner = false;

  constructor(private store: Store<AppState>) { }

  clear(): void {
    this.store.dispatch(new ClearSpinnerAction());
  }

  startForPageLoad(): void {
    this.store.dispatch(new StartSpinnerAction());
    this.isPageLoadSpinner = true;
  }

  start(): void {
    if (this.isPageLoadSpinner) {
      this.isPageLoadSpinner = false;
      return;
    }

    this.store.dispatch(new StartSpinnerAction());
  }

  stop(): void {
    this.isPageLoadSpinner = false;
    this.store.dispatch(new StopSpinnerAction());
  }
}
