import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Asset, Contractor } from 'app/core/interfaces';
import { Job } from 'app/core/interfaces/job';
import { JobDetailPanelService } from 'app/core/services/data-services/job-detail-panel.service';
import { getAssetTypeIcon } from 'app/core/utils/utils';

@Component({
    selector: 'app-grouped-jobs',
    templateUrl: './grouped-jobs.component.html',
    styleUrls: ['./grouped-jobs.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GroupedJobsComponent implements OnInit {
    @Input() jobs: Job[] = [];
    @Input() groupJobsBy: boolean = false;
    @Input() groupJobsByCategory: string = '';
    @Input() isManageCrane: boolean = false;
    @Input() isManageGate: boolean = false;

    groupedJobsModel: GroupedJobModel[] = [];

    constructor(
        private _jobDetailPanelService: JobDetailPanelService,
        private currentRouter: Router,
    ) { }

    ngOnInit(): void {
        if (this.groupJobsByCategory === 'asset') {
            this.groupJobsByAsset();
        }
        else {
            this.groupJobsByContractor();
        }
    }

    groupJobsByAsset = (): void => {
        for (const job of this.jobs) {
            const asset = this.groupedJobsModel.find(
                x => x.asset.id === job.assetId
            );
            if (asset) {
                const jobObj = asset.jobs.find(x => x.id === job.id);
                if (!jobObj) {
                    asset.jobs.push(job);
                }
            } else {
                const newAsset = {
                    id: job.assetId,
                    name: job.assetName,
                    type: job.assetType,
                } as Asset;
                this.groupedJobsModel.push({ asset: newAsset, jobs: [job] });
            }
        }
    };

    groupJobsByContractor = (): void => {
        for (const job of this.jobs) {
            const contractor = this.groupedJobsModel.find(
                x => x.contractor.id === job.contractorId
            );
            if (contractor) {
                const jobObj = contractor.jobs.find(x => x.id === job.id);
                if (!jobObj) {
                    contractor.jobs.push(job);
                }
            } else {
                const newContractor = {
                    id: job.contractorId,
                    name: job.contractorName,
                    color: job.contractorColor,
                } as Contractor;
                this.groupedJobsModel.push({ contractor: newContractor, jobs: [job] });
            }
        }
    };

    getAssetIcon = (assetType: string): string => {
        return getAssetTypeIcon(assetType);
    };

    toggleEditDrawer(job: Job): void {
        if (this.currentRouter.url === '/control/gates') {
            this._jobDetailPanelService.changeData({
                selectedGateJob: job,
            });
        }
    }
}

interface GroupedJobModel {
    asset?: Asset;
    contractor?: Contractor;
    jobs: Job[];
}
