import { Component, OnDestroy, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    SetIsMobileViewAction
} from './core/store/actions/common.actions';
import { AppState } from './core/store/reducers/app.reducer';
import { getSearchFormValuesFromSessionStorage } from './core/utils/utils';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<any>;

    constructor(
        private mediaObserver: MediaObserver,
        private store: Store<AppState>,
        private router: Router,
    ) {
        this.unsubscribe$ = new Subject();
    }

    ngOnInit(): void {
        this.mediaObserver
            .asObservable()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((events) => {
                const isMobileView =
                    events.filter((x) => x.mqAlias === 'lt-md').length > 0;
                this.store.dispatch(new SetIsMobileViewAction(isMobileView));
            });

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                filter((event: NavigationEnd) => !event.url.includes('/scan') && getSearchFormValuesFromSessionStorage()),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((event: NavigationEnd) => {
                sessionStorage.removeItem('searchValues');
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }
}
