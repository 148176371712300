import { MatSnackBar } from '@angular/material/snack-bar';
import * as ExcelJS from 'exceljs';
import FileSaver from "file-saver";
import * as moment from 'moment';
import { AppSettings, Asset, IODataParams, Job, JobHistory, NotificationSettings, OpeningTimes, ProjectSettings, TruckDetailsLogs, User } from '../interfaces';
import { BoqItem } from '../interfaces/boq';
import { AccessManagement, Setting } from '../interfaces/roles';
import { SNACKBAR_TIMEOUT } from './snack-bar.util';

export class Utils { }

export const isRolePermissionAllowed = (key: string, settings: AccessManagement): Setting => {
  return settings.settings.find(x => x.name === key && x.isChecked);
}

export const TOWER_CRANE_KEY = "TC";
export const MOBILE_CRANE_KEY = "MC";
export const BAY_KEY = "BAY";
export const HOIST_KEY = "HOIST";

export const DELIVERY_NOTE_QR_LABEL = "deliveryNoteQR";
export const BAY_QR_LABEL = "bayQR";
export const BOQ_QR_LABEL = "boqQR";
export const ROOM_QR_LABEL = "roomQR";

export const DEFAULT_USER_IDLE_TIME = 30;

export const DEFAULT_ROLES = ['Admin', 'Coordinator', 'Operator', 'Contractor', 'Commercial Manager', 'Global Admin', 'Storeman', 'Security'];

export const getAssetTypeIcon = (assetType: string): string => ({
    'crane': 'assets/images/crane_images/tower-crane.svg',
    'mcrane': 'assets/images/crane_images/crane-truck.svg',
    'bay': 'assets/images/crane_images/truck.svg',
    'hoist': 'assets/images/crane_images/hoist.png',
    'hot-seat': 'assets/images/crane_images/workplace.png',
    'mhandling': 'assets/images/crane_images/deliverytime.crane_icon.svg',
}[assetType] || '');

export const getAssetTypeName = (assetName: string): string => ({
    'crane': 'Tower Cranes',
    'mcrane': 'Mobile Cranes',
    'bay': 'Offloading Areas',
    'hoist': 'Material Lifts',
    'hot-seat': 'Hot Seats',
    'mhandling': 'Material Handling',
}[assetName] || '');

export const getColorClassByJobStatus = (status: string): string => {
    let colorClass = '';
    switch (status) {
        case 'pending':
        case 'requested':
        case 'Requested':
            colorClass = 'request';
            break;

        case 'booked':
        case 'approved':
        case 'Approved':
            colorClass = 'approve';
            break;

        case 'completed':
        case 'Completed':
            colorClass = 'completed';
            break;

        case 'cancelled':
        case 'Cancelled':
            colorClass = 'rejected';
            break;
    }
    return colorClass;
};

export const getHistory = (actionName: string, user: User, userComments?: string, truckDetail?: TruckDetailsLogs): JobHistory => {
    let description = '';
    switch (actionName) {
        case 'Job Created':
            description = `${user.name} (${user.roleName}) has created a job`;
            break;
        case 'Job Updated':
            description = `${user.name} (${user.roleName}) has updated a job`;
            break;
        case 'Job Deleted':
            description = `${user.name} (${user.roleName}) has deleted a job`;
            break;

        case 'Job Cancelled':
            description = `${user.name} (${user.roleName}) has cancelled a job`;
            break;

        case 'Job Scheduled':
            description = `${user.name} (${user.roleName}) has scheduled a job`;
            break;

        case 'Job Approved':
            description = `${user.name} (${user.roleName}) has approved a job`;
            break;

        case 'Job Completed':
            description = `${user.name} (${user.roleName}) has marked a job completed`;
            break;

        case 'Job Started':
            description = `${user.name} (${user.roleName}) has started a job`;
            break;

        case 'Job Ended':
            description = `${user.name} (${user.roleName}) has ended a job`;
            break;

        case 'Job Time Log Completed':
            description = `${user.name} (${user.roleName}) has completed his job`;
            break;

        case 'Check-in':
            description = `${user.name} (${user.roleName}) has checked-in the Truck ${truckDetail.truckNumber}/${truckDetail.totalTrucks}`;
            break;

        case 'Check-out':
            description = `${user.name} (${user.roleName}) has checked-out the Truck ${truckDetail.truckNumber}/${truckDetail.totalTrucks}`;
            break;

        case 'Job Restored':
            description = `${user.name} (${user.roleName}) has restored a job`;
            break;

        case 'Boq Scanned':
            description = `${user.name} (${user.roleName}) has scanned a boq`;
            break;

        case 'DeliveryNote Scanned':
            description = `${user.name} (${user.roleName}) has scanned a delivery note`;
            break;

        case 'Room Scanned':
            description = `${user.name} (${user.roleName}) has scanned a room`;
            break;

        case 'Boq Approved':
            description = `${user.name} (${user.roleName}) has approved a boq`;
            break;

        case 'Boq Rejected':
            description = `${user.name} (${user.roleName}) has rejected a boq`;
            break;

        case 'Boq Submitted':
            description = `${user.name} (${user.roleName}) has submitted a boq`;
            break;

        case 'Boq Uploaded':
            description = `${user.name} (${user.roleName}) has uploaded a boq`;
            break;

        case 'Movement Request Created':
            description = `${user.name} (${user.roleName}) has created a movement request`;
            break;

        case 'Movement Request Approved':
            description = `${user.name} (${user.roleName}) has approved a movement request`;
            break;

        case 'Movement Request Rejected':
            description = `${user.name} (${user.roleName}) has rejected a movement request`;
            break;

        case 'Movement Request Completed':
            description = `${user.name} (${user.roleName}) has completed a movement request`;
            break;

        case 'Package Requested':
            description = `${user.name} (${user.roleName}) has requested a package`;
            break;

        default:
            break;
    }
    return {
        date: moment.utc().format(),
        actionById: 0,
        actionByName: user.name,
        action: actionName,
        description: description,
        comments: userComments,
        id: null,
    };
};

export const getAssetTypes = (assetType: string): string => {
    let type = '';
    switch (assetType) {
        case 'Crane':
        case 'Tower Crane':
            type = 'crane';
            break;
        case 'Mobile Crane':
            type = 'mcrane';
            break;
        case 'Bay':
            type = 'bay';
            break;
        case 'Hoist':
            type = 'hoist';
            break;
    }
    return type;
};

export const appSettingsUtils: AppSettings[] = [
    {
        "id": "",
        "name": "Double Booking",
        "description": "Allows operators to create jobs of assets which are already booked",
        "isChecked": true,
        "imagePath": ""
    },
    {
        "id": "",
        "name": "Operator Extensions",
        "description": "Allows operators to request time extensions.",
        "isChecked": false,
        "imagePath": ""
    },
    {
        "id": "",
        "name": "Realtime Data",
        "description": "Shows reports & liveboard with realtime information.",
        "isChecked": false,
        "imagePath": ""
    },
    {
        "id": "",
        "name": "Predictive Durations",
        "description": "Suggests duration on request form based off past bookings.",
        "isChecked": false,
        "imagePath": null
    },
    {
        "id": "",
        "name": "Breaktime System",
        "description": "Block out specific times for breaks & displays on liveboards.",
        "isChecked": false,
        "imagePath": ""
    },
    {
        "id": "",
        "name": "Calendar Opening Times",
        "description": "Calendar Opening Times",
        "isChecked": false,
        "imagePath": ""
    },
    {
        "id": "",
        "name": "Lift Monitoring",
        "description": "Lift Monitoring",
        "isChecked": false,
        "imagePath": ""
    }
];

export const notificationSettingsUtils: NotificationSettings[] = [
    {
        "name": "Job Request",
        "description": "When job is requested send email to requester and admins",
        "isEnabled": false,
        "configData": null
    },
    {
        "name": "Job Approved",
        "description": "When job is approved send email to requester",
        "isEnabled": false,
        "configData": null
    },
    {
        "name": "Job Rejected",
        "description": "When job is rejected send email to requester and admins (if job is approved)",
        "isEnabled": false,
        "configData": null
    },
    {
        "name": "Job Updated",
        "description": "When job is updated send email to requester",
        "isEnabled": false,
        "configData": null
    },
    {
        "name": "Job Completed",
        "description": "When job is completed send email to requester",
        "isEnabled": false,
        "configData": null
    },
    {
        "name": "Before Job Start",
        "description": "When job is about to start send email to requester",
        "isEnabled": false,
        "configData": [
            {
                "name": "Minutes Before Job Start",
                "description": "Notification should be send how many minutes before job start",
                "value": "15"
            }
        ]
    }
];

export const openingTimesUtils: OpeningTimes[] = [
    {
        "id": "",
        "day": "Monday",
        "startTime": "9:00",
        "endTime": null,
        "isAllDay": true
    },
    {
        "id": "",
        "day": "Tuesday",
        "startTime": "9:00",
        "endTime": null,
        "isAllDay": true
    },
    {
        "id": "",
        "day": "Wednesday",
        "startTime": "9:00",
        "endTime": null,
        "isAllDay": true
    },
    {
        "id": "",
        "day": "Thursday",
        "startTime": "9:00",
        "endTime": null,
        "isAllDay": true
    },
    {
        "id": "",
        "day": "Friday",
        "startTime": "9:00",
        "endTime": null,
        "isAllDay": true
    },
    {
        "id": "",
        "day": "Saturday",
        "startTime": "9:00",
        "endTime": null,
        "isAllDay": true
    },
    {
        "id": "",
        "day": "Sunday",
        "startTime": "9:00",
        "endTime": null,
        "isAllDay": true
    }
];

export const projectSettingsUtils: ProjectSettings = {
    "jobDisplayView": "Grid View",
};

export const getPackagesNames = (boqs: string[]) => {
    return boqs.map(b => { return { name: b } });
}

export const scanQRCodeDynamicColumns = (type: string): any[] => {
    let columnNames = [];
    switch (type) {
        case BOQ_QR_LABEL:
            columnNames = [
                {
                    field: "packageTitle",
                    title: "Package Title"
                },
                {
                    field: "materialDescription",
                    title: "Description"
                },
                {
                    field: "materialQuantity",
                    title: "Allowable Quantity"
                },
                {
                    field: "deliveredQuantity",
                    title: "Delivered Quantity"
                },
                {
                    field: "storageDuration",
                    title: "Duration (days)"
                },
                {
                    field: "createdBy",
                    title: "Created By"
                },
                {
                    field: "modifiedBy",
                    title: "Modified By"
                },
                {
                    field: "modifiedDate",
                    title: "Modified On"
                },
            ];
            break;

        case DELIVERY_NOTE_QR_LABEL:
            columnNames = [
                {
                    "field": "refNo",
                    "title": "Ref No.",
                },
                {
                    "field": "requesterName",
                    "title": "Requester Name",
                },
                {
                    "field": "contractorName",
                    "title": "Contractor",
                },
                {
                    "field": "milestoneName",
                    "title": "Area",
                },
                {
                    "field": "levelName",
                    "title": "Level",
                },
                {
                    "field": "assetName",
                    "title": "Asset Name",
                },
                {
                    "field": "requestedStartTime",
                    "title": "Requested Time",
                },
                {
                    "field": "status",
                    "title": "Status",
                },
            ];
            break;

        case BAY_QR_LABEL:
            columnNames = [
                {
                    "field": "name",
                    "title": "Name",
                },
                {
                    "field": "maximumLength",
                    "title": "Maximum Length (meter)",
                },
                {
                    "field": "isActive",
                    "title": "Active Status",
                },
            ];
            break;
    }

    return columnNames;
};

export const defaultQRCodesParameters = (data: any[], projectName: string, codeType: string) => {
    return data.map(obj => ({
        qrBase64: obj.qrCodeImage,
        projectName: projectName,
        packageTitle: obj.packageTitle,
        materialDescription: obj.materialDescription,
        codeType: codeType,
    })).filter(item => item.qrBase64 !== null);
}

export const checkUserSettingsPermission = (key: string, settings: Setting[]): Setting[] =>
    settings?.filter((x) => x.name === key && x.isChecked) || [];

export const SNACKBAR_ACTION_SUCCESSFUL = "Successful";
export const SNACKBAR_ACTION_ERROR = "Error";
export const SNACKBAR_ACTION_WARNING = "Warning";
export const SNACKBAR_ACTION_INPROGRESS = "In Progress";
export const SNACKBAR_ACTION_DENIED = "Denied";

export const showSnackbar = (_snackbar: MatSnackBar, message: string, action: string) => {
    return _snackbar.open(message, action, { duration: SNACKBAR_TIMEOUT });
}

export const showExistRecordSnackbar = (_snackbar: MatSnackBar) => {
    return showSnackbar(_snackbar, 'This record already exists in the system', SNACKBAR_ACTION_WARNING);
}

export const showUnableJobsRemovalSnackbar = (_snackbar: MatSnackBar, message: string) => {
    return showSnackbar(_snackbar, `Unable to remove this record, ${message} are associated with this record`, SNACKBAR_ACTION_ERROR);
}

export const showActivatedAssetSnackbar = (_snackbar: MatSnackBar, asset: Asset, activeString: string) => {
    return showSnackbar(_snackbar, `${asset.name} has been ${activeString}`, SNACKBAR_ACTION_SUCCESSFUL);
}

export const showJobStatusSnackbar = (_snackbar: MatSnackBar, message: string) => {
    return showSnackbar(_snackbar, `Job ${message} successfully`, SNACKBAR_ACTION_SUCCESSFUL);
}

export const showContactAdministratorSnackbar = (_snackbar: MatSnackBar) => {
    return showSnackbar(_snackbar, 'We have encountered an error while generating the PDF. Please contact system administrator.', SNACKBAR_ACTION_ERROR);
}

export const getJobsParamsRemoveHandler = (keyId: string, dataItemId: string) => ({
    filter: `isDeleted ne true and isBatchJob ne true and ${keyId} eq '${dataItemId}'`,
    selects: ['id'],
});

export const getParamsRemoveHandler = (keyId: string, dataItemId: string) => ({
    filter: `isDeleted ne true and ${keyId} eq '${dataItemId}'`,
    selects: ['id'],
});

export const downloadBOQPackagesOrTemplate = (data: BoqItem[], _snackBar: MatSnackBar) => {
    showSnackbar(_snackBar, `We are downloading the file. Please Wait...`, SNACKBAR_ACTION_INPROGRESS);
    const numRows = 5000;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    const label = data.length === 0 ? "template" : "list";

    worksheet.columns = [
        { header: 'Version', key: 'version', width: 1, hidden: true },
        { header: 'Id', key: 'id', width: 1, hidden: true },
        { header: 'Package Title', key: 'packageTitle', width: 25 },
        { header: 'Material Description', key: 'materialDescription', width: 50 },
        { header: 'Material Quantity', key: 'materialQuantity', width: 25 },
        { header: 'Measured Unit', key: 'measuredUnit', width: 25 },
        { header: 'Storage Duration (days)', key: 'storageDuration', width: 30 }
    ];

    worksheet.getRow(1).font = {
        bold: true,
        size: 12,
    };

    data.forEach(({ id, packageTitle, materialDescription, materialQuantity, measuredUnit, storageDuration }) => {
        const row = worksheet.addRow({
            id,
            packageTitle: packageTitle || "",
            materialDescription: materialDescription || "",
            materialQuantity: materialQuantity || 0,
            measuredUnit: measuredUnit || "",
            storageDuration: storageDuration || 0,
        });

        row.eachCell((cell, colNumber) => {
            if (colNumber !== 1 && colNumber !== 2) {
                cell.protection = { locked: false };
            }

            if (colNumber === 5 || colNumber === 7) {
                getCustomDataValidation(cell);
            }
        });
    });

    for (let i = 0; i < numRows; i++) {
        const row = worksheet.addRow({ id: '', packageTitle: '', materialDescription: '', materialQuantity: '', measuredUnit: '', storageDuration: '' });

        row.eachCell((cell, colNumber) => {
            if (colNumber !== 1 && colNumber !== 2) {
                cell.protection = { locked: false };
            }

            if (colNumber === 5 || colNumber === 7) {
                getCustomDataValidation(cell);
            }
        });
    }

    worksheet.protect('74125', {
        insertRows: true,
    });

    workbook.xlsx.writeBuffer().then((csvData) => {
        const blob: Blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        const currentDateTime = moment().format();
        const fileName = `boq-${label}-${currentDateTime}.csv`;
        FileSaver.saveAs(blob, fileName);
        showSnackbar(_snackBar, `Boq ${label} downloaded successfully`, SNACKBAR_ACTION_SUCCESSFUL);
    });
}

const getCustomDataValidation = (cell: ExcelJS.Cell) => {
    cell.dataValidation = {
        type: 'whole',
        operator: 'between',
        allowBlank: true,
        formulae: [0, 9999999999],
        showErrorMessage: true,
        errorStyle: 'error',
        errorTitle: 'Input Error',
        error: 'Value should be a Number',
    };
};

export const getJobMilestoneName = ({ milestoneName, levelName, zoneName, roomName }) => {
    let name = milestoneName || '';

    if (levelName) {
        const location = [levelName, zoneName, roomName].filter(Boolean).join(' - ');
        const hasLocationInfo = zoneName || roomName;
        name += hasLocationInfo ? ` (${location})` : ` - ${location}`;
    }

    return name;
}

export const getJobTitle = (job: Job) => {
    const refNoPrefix = job.refNo ? `#${job.refNo} | ` : '';
    const jobMilestoneName = getJobMilestoneName(job);
    const taskSuffix = job.tasks && job.tasks.length > 0 ? ` - (${job.tasks.map(a => a.name).join(', ')})` : '';
    return `${refNoPrefix}${jobMilestoneName}${taskSuffix}`;
}

export const getCalendarsJobTitle = (job: Job) => {
    const { refNo, milestoneName, tasks } = job;
    const refNoPrefix = refNo ? `#${refNo} | ` : '';
    const jobMilestoneName = milestoneName || '';
    const taskSuffix = tasks && tasks.length > 0 ? ` - (${tasks.map(a => a.name).join(', ')})` : '';
    return `${refNoPrefix}${jobMilestoneName}${taskSuffix}`;
}

export const getLiveDashboardJobTitle = (job: Job) => {
  const { milestoneName, tasks } = job;
  const jobMilestoneName = milestoneName || '';
  const taskSuffix = tasks && tasks.length > 0 ? ` - (${tasks.map(a => a.name).join(', ')})` : '';
  return `${jobMilestoneName}${taskSuffix}`;
}

export const getSearchFormValuesFromSessionStorage = () => {
    return JSON.parse(sessionStorage.getItem('searchValues'));
}

export const baseIODataParams = (
    projectId: string,
    isSelects: boolean = false,
): IODataParams => {
    const baseParams: IODataParams = {
        filter: `(projectId eq '${projectId}' and isDeleted ne true)`,
        orderBy: ['name asc'],
    };

    if (isSelects) {
        baseParams.selects = ['id', 'name'];
    }

    return baseParams;
}
