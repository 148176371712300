import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Operation } from 'fast-json-patch';
import { Observable, shareReplay } from 'rxjs';
import { IODataParams, IODataResult } from '../../interfaces';

@Injectable({
    providedIn: 'root',
})
export class BaseODataService<T> {

    constructor(protected http: HttpClient, protected controller: string) { }

    protected get baseUrl(): string {
        return `${environment.apiBaseUrl}/odata/${this.controller}`;
    }

    get(params?: IODataParams, showSpinner = true): Observable<IODataResult<T>> {
        const queryStringParts = [];
        if (params?.selects && params?.selects.length > 0) {
            queryStringParts.push(`$select=${params.selects.join(',')}`);
        }

        if (params?.filter) {
            queryStringParts.push(`$filter=${params.filter}`);
        }

        if (params?.orderBy) {
            queryStringParts.push(`$orderby=${params.orderBy.join(',')}`);
        }

        if (params?.top) {
            queryStringParts.push(`$top=${params.top}`);
        }

        if (params?.skip >= 0) {
            queryStringParts.push(`$skip=${params.skip}`);
        }

        if (!showSpinner) {
            queryStringParts.push('hideSpinner=1');
        }

        const queryString = queryStringParts.length > 0 ? queryStringParts.join('&') : '';
        return this.http.get<IODataResult<T>>(`${this.baseUrl}?${queryString}`).pipe(shareReplay());
    }

    getById(id: string): Observable<T> {
        return this.http.get<T>(`${this.baseUrl}/${id}`).pipe(shareReplay());
    }

    post(body: T): Observable<T> {
        if (this.controller === 'jobs') {
            const apiUrl = `${this.baseUrl}/batch`;
            return this.http.post<T>(apiUrl, body);
        }
        else {
            const apiUrl = `${this.baseUrl}`;
            return this.http.post<T>(apiUrl, body);
        }
    }

    put(id: string, body: T): Observable<any> {
        const apiUrl = `${this.baseUrl}/${id}`;
        return this.http.put(apiUrl, body);
    }

    patch(id: string, body: Operation[]): Observable<any> {
        const apiUrl = `${this.baseUrl}/${id}`;
        return this.http.patch(apiUrl, body);
    }

    delete(id: string): Observable<any> {
        const apiUrl = `${this.baseUrl}/${id}`;
        return this.http.delete(apiUrl);
    }
}
