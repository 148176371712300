import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/components/shared/shared.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ProjectStarterComponent } from './project-starter.component';

@NgModule({
    declarations: [ProjectStarterComponent],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatMenuModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatRippleModule,
        MatButtonModule,

        // Search filter
        Ng2SearchPipeModule,

        SharedModule,
    ],
    exports: [ProjectStarterComponent],
})
export class ProjectStarterModule { }
