import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Project } from 'app/core/interfaces';
import { QRCodeDetails } from 'app/core/interfaces/boq';
import { IQrCodeItem } from 'app/core/interfaces/qrcode-item';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectProject } from 'app/core/store/selectors';
import { getMultipleDocsDefinition } from 'app/core/utils/multipleDocsDefinition';
import { BAY_QR_LABEL, BOQ_QR_LABEL, DELIVERY_NOTE_QR_LABEL, ROOM_QR_LABEL, SNACKBAR_ACTION_SUCCESSFUL, showContactAdministratorSnackbar, showSnackbar } from 'app/core/utils/utils';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import { TCreatedPdf, createPdf } from "pdfmake/build/pdfmake";
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-qrcodedialog',
  templateUrl: './qrcodedialog.component.html',
  styleUrls: ['./qrcodedialog.component.scss']
})

export class QrcodedialogComponent implements OnDestroy {
  @ViewChild('qrCodePrint') qrCodePrint: ElementRef;
  dataString: any;
  title: string;
  project: Project;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<AppState>,
    private _snackbar: MatSnackBar,
    public dialog: MatDialogRef<QrcodedialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store
      .select(selectProject)
      .pipe(filter((project) => !!project && !!project.id), takeUntil(this._unsubscribeAll))
      .subscribe((project) => {
        this.project = project;
      });

    let reducedQRData = new QRCodeDetails();
    _.assign(reducedQRData, _.pick(this.data, _.keys(reducedQRData)));
    this.dataString = JSON.stringify(reducedQRData);
    this.fetchTitle(data);
  }

  fetchTitle(data): void {
    switch (data.codeType) {
      case ROOM_QR_LABEL:
        this.title = this.data.name;
        return;

      case BAY_QR_LABEL:
        this.title = this.data.name;
        return;

      case BOQ_QR_LABEL:
        this.title = this.data.packageTitle + ' | ' + this.data.materialDescription;
        return;

      case DELIVERY_NOTE_QR_LABEL:
        this.title = this.data.contractorName;
        return;

      default:
        this.title = '';
    }
  }

  printQRImage(): void {
    window.print();
  }

  saveAsImage(): void {
    const itemProps = {
      [DELIVERY_NOTE_QR_LABEL]: {
        requesterName: this.data.requesterName,
        areaLevel: `${this.data.milestoneName} | ${this.data.levelName}`,
        contractorName: this.data.contractorName,
        gateName: this.data.gateName,
        assetName: this.data.assetName,
      },
      [ROOM_QR_LABEL]: {
        levelName: this.data.levelName,
        roomName: this.data.name,
      },
      [BOQ_QR_LABEL]: {
        packageTitle: this.data.packageTitle,
        materialDescription: this.data.materialDescription,
      },
      [BAY_QR_LABEL]: {
        bayName: this.data.name,
      },
    };

    html2canvas(this.qrCodePrint.nativeElement).then(canvas => {
      const base64Image = canvas.toDataURL();

      try {
        const item: IQrCodeItem = {
          qrBase64: base64Image,
          projectName: this.project.name,
          codeType: this.data.codeType,
          ...itemProps[this.data.codeType],
        };

        const pdf: TCreatedPdf = createPdf(getMultipleDocsDefinition([item]));
        pdf.download(
          `${this.data.codeType}_qrcode_${Date.now()}.pdf`,
          () => {
            showSnackbar(this._snackbar, `QRCode downloaded successfully`, SNACKBAR_ACTION_SUCCESSFUL);
          }
        );
      } catch (err) {
        showContactAdministratorSnackbar(this._snackbar);
        console.error(err);
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
