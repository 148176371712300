import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseDrawerService } from '@fuse/components/drawer';
import { Store } from '@ngrx/store';
import { IODataParams, User } from 'app/core/interfaces';
import { AccessManagement } from 'app/core/interfaces/roles';
import { ExcelExportGenericService } from 'app/core/services/custom-services/excel-export-generic.service';
import { JobDetailPanelService } from 'app/core/services/data-services/job-detail-panel.service';
import { JobService } from 'app/core/services/job.service';
import { JobODataService } from 'app/core/services/odata/job-odata.service';
import { ProjectODataService } from 'app/core/services/odata/project-odata.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import {
  selectUser,
  selectUserSettings
} from 'app/core/store/selectors/auth.selectors';
import {
  selectIsMobileView,
  selectProject,
  selectSpinner
} from 'app/core/store/selectors/common.selectors';
import { calculateDuration } from 'app/core/utils/date-time.utils';
import { EXPORT_COLUMNS_JOBS } from 'app/core/utils/exportdata-columns';
import { SNACKBAR_ACTION_DENIED, SNACKBAR_ACTION_ERROR, SNACKBAR_ACTION_SUCCESSFUL, isRolePermissionAllowed, showSnackbar } from 'app/core/utils/utils';
import moment from 'moment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Base } from '../../../core/interfaces/base';
import { Job } from '../../../core/interfaces/job';
import { JobSearchDialogComponent } from '../job-search-dialog/job-search-dialog.component';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class JobListComponent implements OnInit, OnDestroy {
  showClearSearch: boolean = false;

  @Input() jobs: Job[] = [];
  @Input() noOfRecords: number = 9;
  @Input() skipRecords: number = 0;
  @Input() assetFilter: string = '';
  @Input() isManageCrane = false;
  @Input() isManageGate = false;
  @Input() groupJobsBy: boolean = false;
  @Input() groupJobsByCategory: string = '';

  @Input() config: any = {
    // title: 'All Jobs',
    view: 'full-view',
    edit: true,
    filter: {
      myJobsOnly: false,
      activeJobs: false,
      completedJobs: false,
      sorting: false,
    },
    // filterDataBy: 'all',
    sortDataBy: 'recent',
    parameters: null,
    showTodayJobs: false,
    showPreviousJobs: false,
  };

  @Output() toHideScheduler = new EventEmitter<boolean>();

  isScheduler: boolean = true;
  jobCategories: string[] = ['All', 'Recent Jobs', 'Requested Jobs'];
  currentODataParams: IODataParams;
  projectId: string;
  projectName: string;
  totalRecords: number = 0;
  searchByUser = false;
  user: User;
  task: Base;
  zone: Base;
  area: Base;
  assetType: Base;
  contractor: Base;
  level: Base;
  room: Base;
  dialogRef: any;
  isMobileView: boolean = false;
  selectedJob: Job;
  showLoader: boolean = false;
  jobDisplay: string = 'grid';
  isShowMoreJobs: boolean = false;
  showJobView: string = '';
  packageItemsCssClass: string = 'min-w-975';
  userAccess: AccessManagement;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _fuseDrawerService: FuseDrawerService,
    private _jobDetailPanelService: JobDetailPanelService,
    private jobODataService: JobODataService,
    private projectODataService: ProjectODataService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private jobService: JobService,
    private _snackbar: MatSnackBar,
    private _excelExportService: ExcelExportGenericService,
    private currentRouter: Router
  ) {
    this.store
      .select(selectSpinner)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(inProgress => {
        this.showLoader = inProgress;
      });

    this.store
      .select(selectUserSettings)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: AccessManagement) => {
        if (response) {
          this.userAccess = response;
        }
      });

    this.store.select(selectUser).pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
      this.user = user;
    });

    const emailViewjob = this.route.snapshot.queryParams.i;
    if (emailViewjob) {
      this.jobODataService.getById(emailViewjob).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
        if (res) {
          this.projectODataService.getById(res.projectId).pipe(takeUntil(this._unsubscribeAll)).subscribe(project => {
            if (project.isDeleted) {
              showSnackbar(this._snackbar, "Project Access Denied! Please Contact Administrator", SNACKBAR_ACTION_DENIED);
            }
            else {
              this.toggleEditDrawer(res);
            }
          });
        }
      });
    }

    this.store
      .select(selectIsMobileView)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(isMobileView => (this.isMobileView = isMobileView));
  }

  ngOnInit(): void {
    this.store
      .select(selectProject)
      .pipe(filter(project => !!project && !!project.id), takeUntil(this._unsubscribeAll))
      .subscribe(project => {
        this.projectId = project.id;
        this.projectName = project.name;
        if (project.projectSettings) {
          this.jobDisplay =
            project.projectSettings.jobDisplayView === 'Grid View'
              ? 'grid'
              : 'list';
        }
        else {
          this.jobDisplay = 'Grid View';
        }

        const filters = this.getConfigFilters();
        filters['orderBy'] = ['createdDate desc'];
        this.filterData(filters);
      });

    if (this.route.snapshot.params.id) {
      this.config.parameters = this.route.snapshot.params.id;
    }

    this.showJobView = this.route.snapshot.queryParams.jobShow;

    if (this.showJobView) {
      this.hideScheduler(false, this.showJobView);
    }

    this.jobService.jobsRefreshed.pipe(takeUntil(this._unsubscribeAll)).subscribe(() =>
      this.showJobs(this.currentODataParams)
    );
  }

  formatDuration(date): string {
    return moment(date).fromNow();
  }

  formatStartDate(date, showDuration = true): string {
    return showDuration
      ? moment(date).format('DD/MM/YY') + ' - ' + moment(date).fromNow()
      : moment(date).format('DD/MM/YY');
  }

  getDuration(job: Job): string {
    return calculateDuration(job.requestedStartTime, job.requestedEndTime);
  }

  onScroll() {
    if (this.jobs.length !== 0 && this.totalRecords > this.jobs.length) {
      this.showMore();
    }
  }

  showJobs(params: IODataParams): void {
    this.jobODataService.get(params).pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.jobs = this.isShowMoreJobs
        ? [...this.jobs, ...data.value]
        : data.value;
      this.totalRecords = data.count;
    });
  }

  changeJobsView(viewType: string): void {
    this.jobDisplay = viewType;
  }

  hideScheduler(status: boolean, type: string): void {
    this.toHideScheduler.emit(status);
    this.isScheduler = status;
    if (!status) {
      this.showMore();
      if (type === 'list') {
        this.changeJobsView('list');
      } else if (type === 'grid') {
        this.changeJobsView('grid');
      }
    }
  }

  toggleEditCraneDrawer(job: Job): void {
    this._jobDetailPanelService.changeData({
      selectedCraneJob: job,
    });
  }

  toggleEditDrawer(job: Job): void {
    if (this.currentRouter.url === '/control/gates') {
      this._jobDetailPanelService.changeData({
        selectedGateJob: job,
      });
    } else {
      this.selectedJob = job;
      const drawer = this._fuseDrawerService.getComponent('editJobDrawer');
      drawer.toggle();
    }
  }

  updateEditJobDrawer(value: boolean): void {
    if (!value) {
      const drawer = this._fuseDrawerService.getComponent('editJobDrawer');
      drawer.toggle();
      this.selectedJob = null;
    }
  }

  removeEditJobValue(value: boolean): void {
    if (!value) {
      this.selectedJob = null;
      this.packageItemsCssClass = 'min-w-975';
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  sortJobs(value): void {
    const filters = this.getConfigFilters();

    if (value === '1') {
      filters['orderBy'] = ['createdDate desc'];
    } else if (value === '2') {
      filters['orderBy'] = ['requestedStartTime desc'];
    }

    this.filterData(filters);
  }

  showMore(): void {
    this.isShowMoreJobs = true;
    const filters = this.getConfigFilters();
    filters['orderBy'] = ['createdDate desc'];
    this.skipRecords += 9;
    this.filterData(filters);
  }

  openSearchDialog = (): void => {
    this.dialogRef = this.dialog.open(JobSearchDialogComponent, {
      data: {
        task: this.task,
        area: this.area,
        assetType: this.assetType,
        level: this.level,
        zone: this.zone,
        room: this.room,
        contractor: this.contractor,
        // status : this.status
      },
      width: '400px',
    });

    this.dialogRef.afterClosed().subscribe(response => {
      if (!response) {
        return;
      }

      const actionType: string = response[0];
      const jobSearchForm: FormGroup = response[1];

      if (actionType === 'search') {
        this.showClearSearch = true;
        this.task = jobSearchForm.value.task;
        this.area = jobSearchForm.value.area;
        this.assetType = jobSearchForm.value.assetType;
        this.level = jobSearchForm.value.level;
        this.zone = jobSearchForm.value.zone;
        this.room = jobSearchForm.value.room;
        this.contractor = jobSearchForm.value.contractor;
        // this.status = jobSearchForm.value.status;

        const filters = this.getConfigFilters();
        filters['orderBy'] = ['createdDate desc'];

        this.filterData(filters);
      }
    });
  };

  changeConfigDetails($event: any): void {
    switch ($event.index) {
      case 0:
        this.config = {
          title: 'All Jobs',
          view: 'full-view',
          edit: true,
          filter: {
            myJobsOnly: false,
            activeJobs: false,
            completedJobs: false,
            sorting: false,
          },
          filterDataBy: 'all',
          sortDataBy: 'recent',
          parameters: null,
          showTodayJobs: false,
        };

        const filtersAll = this.getConfigFilters();
        filtersAll['orderBy'] = ['createdDate desc'];
        this.filterData(filtersAll);
        break;

      case 1:
        this.config = {
          title: 'Recent Jobs',
          view: 'full-view',
          edit: true,
          filterDataBy: 'approved,completed',
          sortDataBy: 'recent',
          showTodayJobs: false,
        };
        const filtersRecent = this.getConfigFilters();
        filtersRecent['orderBy'] = ['createdDate desc'];
        this.filterData(filtersRecent);
        break;

      case 2:
        this.config = {
          title: 'Requested Jobs',
          view: 'full-view',
          edit: true,
          filter: {
            sorting: true,
          },
          filterDataBy: 'requested',
          sortDataBy: 'recent',
          showTodayJobs: false,
        };
        const filtersRequested = this.getConfigFilters();
        filtersRequested['orderBy'] = ['createdDate desc'];
        this.filterData(filtersRequested);
        break;
    }
  }

  isFilterExists(array, fieldName): boolean {
    const arr = array.filter(item => item.field === fieldName);
    return arr.length > 0 ? true : false;
  }

  filterData(filters): void {
    let odataFilter = `(projectId eq '${this.projectId}') and isBatchJob ne true and isDeleted ne true`;

    if (isRolePermissionAllowed('manage-job-access', this.userAccess)) {
      odataFilter += ` and (requesterEmail eq '${this.user.principalName}') `;
    }

    if (this.isFilterExists(filters, 'status')) {
      odataFilter +=
        ' and (' +
        filters
          .filter(item => item.field === 'status')
          .map(x => `${x.field} eq '${x.value}'`)
          .join(' or ') +
        ')';
    }

    if (this.isFilterExists(filters, 'assetId')) {
      odataFilter +=
        ' and (' +
        filters
          .filter(item => item.field === 'assetId')
          .map(x => `${x.field} eq '${x.value}'`)
          .join(' ') +
        ')';
    }

    if (this.isFilterExists(filters, 'requesterId')) {
      odataFilter +=
        ' and (' +
        filters
          .filter(item => item.field === 'requesterId')
          .map(x => `${x.field} eq '${x.value}'`)
          .join(' ') +
        ')';
    }


    if (this.isFilterExists(filters, 'milestoneId')) {
      odataFilter +=
        ' and (' +
        filters
          .filter(item => item.field === 'milestoneId')
          .map(x => `${x.field} eq '${x.value}'`)
          .join(' ') +
        ')';
    }

    if (this.isFilterExists(filters, 'levelId')) {
      odataFilter +=
        ' and (' +
        filters
          .filter(item => item.field === 'levelId')
          .map(x => `${x.field} eq '${x.value}'`)
          .join(' ') +
        ')';
    }

    if (this.isFilterExists(filters, 'zoneId')) {
      odataFilter +=
        ' and (' +
        filters
          .filter(item => item.field === 'zoneId')
          .map(x => `${x.field} eq '${x.value}'`)
          .join(' ') +
        ')';
    }

    if (this.isFilterExists(filters, 'roomId')) {
      odataFilter +=
        ' and (' +
        filters
          .filter(item => item.field === 'roomId')
          .map(x => `${x.field} eq '${x.value}'`)
          .join(' ') +
        ')';
    }

    if (this.isFilterExists(filters, 'assetType')) {
      odataFilter +=
        ' and (' +
        filters
          .filter(item => item.field === 'assetType')
          .map(x => `${x.field} eq '${x.value}'`)
          .join(' ') +
        ')';
    }

    if (this.isFilterExists(filters, 'assetFilter')) {
      const assetType = filters.filter(x => x.field === 'assetFilter');
      if (assetType[0].value === 'bay') {
        odataFilter += " and (assetType eq 'bay') and (trucks gt 0)";
      } else {
        odataFilter +=
          ' and (' +
          filters
            .filter(item => item.field === 'assetFilter')
            .map(x => `assetType eq '${x.value}'`)
            .join(' ') +
          ')';
      }
    }

    if (this.isFilterExists(filters, 'requestedStartTime')) {
      this.route.url.pipe(takeUntil(this._unsubscribeAll)).subscribe(url => {
        if (url.length > 0) {
          const customUrl = url[0];
          if (customUrl.path !== 'crane') {
            odataFilter +=
              ' and (' +
              filters
                .filter(item => item.field === 'requestedStartTime')
                // .map((x) => `${x.field} eq '${x.value}'`)
                .map(x => `startswith(${x.field},'${x.value}')`)
                .join(' ') +
              ')';
          }
        }
      });
    }

    if (this.config.showPreviousJobs) {
      const today = new Date();
      today.setHours(0, 0, 0);
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      odataFilter +=
        ` and ( requestedStartTime ge '${yesterday.toISOString()}')` +
        ` and (requestedStartTime lt '${tomorrow.toISOString()}')`;
    }

    if (this.config.showTodayJobs) {
      const today = new Date();
      today.setHours(0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      odataFilter +=
        ` and ( requestedStartTime ge '${today.toISOString()}')` +
        ` and (requestedStartTime lt '${tomorrow.toISOString()}')`;
    }

    if (this.isFilterExists(filters, 'contractorId')) {
      odataFilter +=
        ' and (' +
        filters
          .filter(item => item.field === 'contractorId')
          .map(x => `${x.field} eq '${x.value}'`)
          .join(' or ') +
        ')';
    }

    if (this.config.title === 'Active Jobs') {
      odataFilter = odataFilter + ' and (timeLogs ne null and timeLogs ne [])';
    } else if (this.config.title === 'Scheduled Jobs') {
      odataFilter = odataFilter + ' and (timeLogs eq null or timeLogs eq [])';
    }

    if (this.config.filterByFiles === 'yes') {
      odataFilter = odataFilter + ' and (files ne null and files ne [])';
    } else if (this.config.filterByFiles === 'no') {
      odataFilter = odataFilter + ' and (files eq null or files eq [])';
    }

    this.currentODataParams = {
      filter: odataFilter,
      top: this.noOfRecords,
      skip: this.skipRecords,
      orderBy: filters['orderBy'],
    };

    this.showJobs(this.currentODataParams);
  }

  getConfigFilters(): any[] {
    const filters = [];

    if (this.config.filterDataBy && this.config.filterDataBy !== 'all') {
      if (this.config.filterDataBy.includes(',')) {
        const statuses = this.config.filterDataBy
          .split(',')
          .map(x => ({ field: 'status', value: x }));
        filters.push(statuses);
      } else {
        filters.push({
          field: 'status',
          value: this.config.filterDataBy,
        });
      }

      if (this.config.parameters) {
        if (this.config.parameters.assetId) {
          filters.push({
            field: 'assetId',
            value: this.config.parameters.assetId,
          });
        }
      }

      if (this.searchByUser) {
        filters.push({
          field: 'requesterId',
          value: this.user.id,
        });
      }

      if (this.assetFilter) {
        filters.push({
          field: 'assetFilter',
          value: this.assetFilter,
        });
      }
    }

    if (this.area?.id) {
      filters.push({
        field: 'milestoneId',
        value: this.area.id,
      });
    }

    if (this.level?.id) {
      filters.push({
        field: 'levelId',
        value: this.level.id,
      });
    }

    if (this.zone?.id) {
      filters.push({
        field: 'zoneId',
        value: this.zone.id,
      });
    }

    if (this.room?.id) {
      filters.push({
        field: 'roomId',
        value: this.room.id,
      });
    }

    if (this.assetType?.id) {
      filters.push({
        field: 'assetType',
        value: this.assetType.id,
      });
    }

    if (this.contractor?.id) {
      filters.push({
        field: 'contractorId',
        value: this.contractor.id,
      });
    }

    return filters;
  }

  relatedJobExists(batchId: string): Job[] {
    return this.jobs.filter(
      x => x.batchId === batchId && x.isBatchJob === false && x.batchId !== null
    );
  }

  updateJobDrawerForPackageItems(value: boolean): void {
    this.packageItemsCssClass = value ? 'min-w-1300' : 'min-w-975';
  }

  clearSearch = (): void => {
    this.showClearSearch = false;
    this.task = null;
    this.area = null;
    this.assetType = null;
    this.level = null;
    this.zone = null;
    this.room = null;
    this.contractor = null;

    this.skipRecords = 0;

    const filters = this.getConfigFilters();
    filters['orderBy'] = ['createdDate desc'];
    this.filterData(filters);
  };

  exportJobsToExcel(): void {
    showSnackbar(this._snackbar, `Download will start shortly!`, SNACKBAR_ACTION_SUCCESSFUL);
    const params: IODataParams = {
      filter: `(projectId eq '${this.projectId}') and isBatchJob ne true and isDeleted ne true`,
      orderBy: ['createdDate desc'],
    };

    this.jobODataService.get(params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        if (data.count === 0) {
          showSnackbar(this._snackbar, `No Jobs Found`, SNACKBAR_ACTION_ERROR);
          return;
        }

        this._excelExportService.exportToExcel(data.value, EXPORT_COLUMNS_JOBS, "Jobs", this.projectName);
      });
  }
}
