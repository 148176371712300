import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JobsImportViewModel } from '../../interfaces/jobs-import-view-model';

@Injectable({
  providedIn: 'root',
})
export class JobApiService {
  private get baseUrl(): string {
    return `${environment.apiBaseUrl}/api/jobs`;
  }

  constructor(protected http: HttpClient) {}

  importJobs(body: JobsImportViewModel): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/bulk-import`, body);
  }
}
