import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/store/reducers/app.reducer';
import {
  selectPageTitle,
  selectToolBar,
} from 'app/core/store/selectors/common.selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-lower-toolbar',
  templateUrl: './lower-toolbar.component.html',
  styleUrls: ['./lower-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LowerToolbarComponent implements OnInit, OnDestroy {
  pageHeading: string = '';
  showPageHeading: boolean;
  showBackButton: boolean;
  showScanBackButton: boolean;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<AppState>,
    private _location: Location,
    private _router: Router,
    private cd: ChangeDetectorRef
  ) {
    this.store
      .select(selectPageTitle)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(pageTitle => {
        this.pageHeading = pageTitle;
      });
  }

  ngOnInit(): void {
    this.store
      .select(selectToolBar)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(toolbar => {
        if (toolbar) {
          this.showPageHeading = toolbar.pageHeading;
          this.showBackButton = toolbar.showBackButton;
          this.showScanBackButton = toolbar.showScanBackButton;
          this.cd.detectChanges();
        }
      });
  }

  back(): void {
    this._location.back();
  }

  scanBack(): void {
    const currentUrl = this._router.url;
    this._router.navigateByUrl('/control', { skipLocationChange: true }).then(() => {
      this._router.navigateByUrl(currentUrl);
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
