import { Action } from '@ngrx/store';
import { RecurrenceStoreData } from 'app/core/interfaces';
import { BaseIdName } from 'app/core/interfaces/base';
import { ToolbarSettings } from 'app/core/interfaces/toolbar-settings';
import { Project } from '../../interfaces/project';

export const CLEAR_SPINNER = 'common/clear-spinner';
export const FILTER_SCHEDULER = 'common/filter-scheduler';
export const HIDE_TOOLBAR = 'common/hide-toolbar';
export const SET_IS_MOBILE_VIEW = 'common/set-is-mobile-view';
export const SET_PAGE_TITLE = 'common/set-page-title';
export const SET_PREV_PROJECT_ID = 'common/set-prev-project-id';
export const SET_PROJECT = 'common/set-project';
export const SHOW_TOOLBAR = 'common/show-toolbar';
export const START_SPINNER = 'common/start-spinner';
export const STOP_SPINNER = 'common/stop-spinner';
export const SET_IS_NIGHT_SHIFT = 'common/set-is-night-shift';
export const SET_CUSTOMIZED_UNLOADING_METHODS = 'common/set-customized-unloading-methods';
export const SET_RECURRENCE_DATA = 'common/set-recurrence-data';

export class ClearSpinnerAction implements Action {
  readonly type = CLEAR_SPINNER;
}

export class SelectPageTitleAction implements Action {
  readonly type = SET_PAGE_TITLE;
  constructor(public payload: string) { }
}

export class SelectProjectAction implements Action {
  readonly type = SET_PROJECT;
  constructor(public payload: Project) { }
}

export class SetPrevProjectId implements Action {
  readonly type = SET_PREV_PROJECT_ID;
  constructor(public payload: string) { }
}

export class SetIsMobileViewAction implements Action {
  readonly type = SET_IS_MOBILE_VIEW;
  constructor(public payload: boolean) { }
}

export class StartSpinnerAction implements Action {
  readonly type = START_SPINNER;
}

export class StopSpinnerAction implements Action {
  readonly type = STOP_SPINNER;
}

export class ShowToolbarAction implements Action {
  readonly type = SHOW_TOOLBAR;
  constructor(public payload: ToolbarSettings) { }
}

export class HideToolbarAction implements Action {
  readonly type = HIDE_TOOLBAR;
  constructor(public payload: ToolbarSettings) { }
}
export class FilterScheduler implements Action {
  readonly type = FILTER_SCHEDULER;
  constructor(public payload: any) { }
}

export class SetIsNightShiftAction implements Action {
  readonly type = SET_IS_NIGHT_SHIFT;
  constructor(public payload: boolean) { }
}

export class SetCustomizedUnloadingMethods implements Action {
  readonly type = SET_CUSTOMIZED_UNLOADING_METHODS;
  constructor(public payload: BaseIdName[]) { }
}

export class SetRecurrenceData implements Action {
  readonly type = SET_RECURRENCE_DATA;
  constructor(public payload: RecurrenceStoreData) { }
}

export type CommonActions =
  | ClearSpinnerAction
  | FilterScheduler
  | HideToolbarAction
  | SelectPageTitleAction
  | SelectProjectAction
  | SetPrevProjectId
  | SetIsMobileViewAction
  | SetIsNightShiftAction
  | SetCustomizedUnloadingMethods
  | SetRecurrenceData
  | ShowToolbarAction
  | StartSpinnerAction
  | StopSpinnerAction;
