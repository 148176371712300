import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectIsMobileView } from 'app/core/store/selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-job-confirm-dialog',
  templateUrl: './job-confirm-dialog.component.html',
  styleUrls: ['./job-confirm-dialog.component.scss'],
})

export class JobConfirmDialogComponent implements OnDestroy {
  isMobileView: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public dialog: MatDialogRef<JobConfirmDialogComponent>,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store
      .select(selectIsMobileView)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isMobileView) => {
        this.isMobileView = isMobileView;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
