import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AssignedAreaAsset, IODataParams, MilestonesViewModel } from 'app/core/interfaces';
import { MilestoneODataService } from 'app/core/services/odata/milestone-odata.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectProject } from 'app/core/store/selectors/common.selectors';
import { Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-add-area-in-asset-drawer',
  templateUrl: './add-area-in-asset-drawer.component.html',
  styleUrls: ['./add-area-in-asset-drawer.component.scss'],
})

export class AddAreaInAssetDrawerComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Output() isAreaAssetDrawerOpened = new EventEmitter<boolean>();

  projectId: string;
  areas: AssignedAreaAsset[] = [];
  oldAreas: AssignedAreaAsset[] = [];
  public editForm: FormGroup;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _store: Store<AppState>,
    private _milestoneService: MilestoneODataService,
  ) {
    this._store
      .select(selectProject)
      .pipe(filter((project) => !!project && !!project.id), takeUntil(this._unsubscribeAll))
      .subscribe((project) => {
        this.projectId = project.id;
        this.getAreas();
      });
  }

  ngOnInit(): void {
    this.oldAreas = this.data.areas;
    this.editForm = this.createFormGroup(this.data);
  }

  createFormGroup(item: any): FormGroup {
    return new FormGroup({
      assetName: new FormControl(item?.name || ''),
      areas: new FormControl(item?.areas || []),
    });
  }

  getAreas(): void {
    const params: IODataParams = {
      filter: `(projectId eq '${this.projectId}' and isDeleted ne true)`,
      selects: ['id', 'name'],
      orderBy: ['name asc'],
    };

    this._milestoneService.get(params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.areas = res.value.map(x => ({
          id: x.id,
          name: x.name,
          type: "",
        }));
      });
  }

  submit(): void {
    const removedAreas = this.oldAreas.filter(item => !this.editForm.value.areas.includes(item));
    const body: MilestonesViewModel = {
      projectId: this.projectId,
      areas: this.editForm.value.areas,
      removeAreas: removedAreas,
      asset: this.data.asset,
    };

    this._milestoneService.addAssetsWithArea(body).subscribe((response) => {
      this.closeDrawer();
      window.location.reload();
    });
  }

  closeDrawer(): void {
    this.isAreaAssetDrawerOpened.emit(false);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
