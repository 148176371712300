import { Component, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'app/core/services/custom-services/auth.service';
import { SNACKBAR_ACTION_SUCCESSFUL, showSnackbar } from 'app/core/utils/utils';
import { MustMatch } from 'app/core/validator/must-match-validator';
@Component({
    selector: 'app-update-password-dialog',
    templateUrl: './update-password-dialog.component.html',
    styleUrls: ['./update-password-dialog.component.scss'],
})
export class UpdatePasswordDialogComponent implements OnInit {
    resetPasswordForm: FormGroup;
    constructor(
        public matDialogRef: MatDialogRef<UpdatePasswordDialogComponent>,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private snackbar: MatSnackBar
    ) {
        this.resetPasswordForm = this.createResetPasswordForm();
    }

    ngOnInit(): void { }
    createResetPasswordForm(): FormGroup {
        return this.formBuilder.group(
            {
                password: new FormControl('', Validators.required),
                newPassword: new FormControl('', Validators.required),
                confirmNewPassword: new FormControl('', Validators.required),
            },
            {
                validator: MustMatch('newPassword', 'confirmNewPassword'),
            }
        );
    }
    changePassword(): void {
        const changePassword = {
            password: this.resetPasswordForm.get('password').value,
            newPassword: this.resetPasswordForm.get('newPassword').value,
        };

        this.authService.changePassword(changePassword).subscribe((response) => {
            if (response) {
                this.matDialogRef.close();
                showSnackbar(this.snackbar, 'Password has been reset successfully', SNACKBAR_ACTION_SUCCESSFUL);
            } else {
                this.resetPasswordForm.get('password').setErrors(['invalid-password']);
            }
        });
    }

    closeDialog(): void {
        this.matDialogRef.close();
    }
}
