import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BoqItem } from 'app/core/interfaces/boq';
import { BoqsImportViewModel, BoqsItemsViewModel } from 'app/core/interfaces/boqs-import-view-model';
import { Observable } from 'rxjs';
import { BaseODataService } from './base-odata.service';

@Injectable({
  providedIn: 'root',
})

export class BoqItemODataService extends BaseODataService<BoqItem> {
  constructor(protected http: HttpClient) {
    super(http, 'boqItems');
  }

  importBoqs(body: BoqsImportViewModel): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/bulk-import-boqs`, body);
  }

  getAllBoqs(projectId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/get-boq-packages/${projectId}`);
  }

  statusBoqs(body: BoqsImportViewModel): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/bulk-status-boqs`, body);
  }

  bulkQuantityUpdate(body: BoqsItemsViewModel): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/bulk-quantity`, body);
  }
}
