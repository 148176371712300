import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { JobHistory } from 'app/core/interfaces';
import * as moment from 'moment';

@Component({
    selector: 'app-job-history',
    templateUrl: './job-history.component.html',
    styleUrls: ['./job-history.component.scss'],
})

export class JobHistoryComponent implements OnInit, AfterViewInit {
    @Input() jobHistory: JobHistory[];
    @Input() showByColumn: boolean;
    @Input() showCommentColumn: boolean;
    @Input() isLinkedJob: boolean;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    dataSource: MatTableDataSource<JobHistory>;
    displayedColumns: string[] = ['when', 'event', 'description'];

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<JobHistory>(this.jobHistory || []);
        if (this.showByColumn) {
            this.displayedColumns.push('by');
        }

        if (this.showCommentColumn) {
            this.displayedColumns.push('comments');
        }

        if (this.isLinkedJob) {
            const descriptionIndex = this.displayedColumns.indexOf('description');

            if (descriptionIndex !== -1) {
                this.displayedColumns.splice(descriptionIndex, 0, 'assetName');
            }
        }
    }

    formatDate = (date): string => moment(date).format('lll');
}
