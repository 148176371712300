import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    changes$ = new Subject();

    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }

    public set(key: string, data: any): void {
        const hasData = this.storage.get(key);
        this.changes$.next(false);

        if (hasData) {
            this.storage.remove(key);
        }

        this.storage.set(key, data);
    }

    get<T>(STORAGE_KEY: string): T {
        const value = this.storage.get(STORAGE_KEY);

        if (typeof value === 'undefined') {
            return null;
        }

        return value;
    }

    remove(key: string): void {
        this.storage.remove(key);

        this.changes$.next(true);
    }

    clear(): void {
        this.storage.clear();
        sessionStorage.clear();
        localStorage.clear();

        this.changes$.next(true);
    }
}
