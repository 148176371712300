import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AssetDetailPanelService } from 'app/core/services/data-services/asset-detail-panel.service';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-asset-map',
  templateUrl: './asset-map.component.html',
  styleUrls: ['./asset-map.component.scss'],
})

export class AssetMapComponent implements OnInit, OnDestroy {
  @Input() type: string = '';
  @Input() image: string = '';
  @Input() sectors: number = 0;
  @Output() getSelectedSection = new EventEmitter<number>();
  @Input() pickDropSlice: number;
  rotateValue: number = 0;
  skewY: number = 0;
  sectorsArray: any[] = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _assetDetailPanelService: AssetDetailPanelService,
  ) {
    this._assetDetailPanelService.data
      .pipe(filter(x => !!x), takeUntil(this._unsubscribeAll))
      .subscribe(x => {
        this.sectors = x;
        this.sectorsArray = [];
        this.rotateValue = 0;
        this.skewY = 0;
        this.ngOnInit();
      });
  }

  ngOnInit(): void {
    this.skewY = (360 / this.sectors) + 90;

    this.getSectorsCount().forEach(s => {
      this.getRotate(s);
      this.sectorsArray.push({
        sector: s,
        rotate: this.rotateValue,
      });
    });
  }

  getSectorsCount(): any {
    return Array.from({ length: this.sectors }, (_, i) => i + 1);
  }

  getAssetSlice(sliceNumber: number): void {
    this.pickDropSlice = sliceNumber;
    this.getSelectedSection.emit(sliceNumber);
  }

  getImageUrl(): string {
    return `url(;${this.image})`;
  }

  getRotate(index: number): number {
    const val = 360 / this.sectors;
    if (index !== 1) {
      this.rotateValue += val;
    }
    return this.rotateValue;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
