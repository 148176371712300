import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  SuccessEvent,
  UploadComponent,
  UploadEvent,
} from '@progress/kendo-angular-upload';
import { Job, User } from 'app/core/interfaces';
import { UploadFile } from 'app/core/interfaces/upload-file';
import { JobODataService } from 'app/core/services/odata/job-odata.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectProject } from 'app/core/store/selectors/common.selectors';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-check-in-out-image-upload-dialog',
  templateUrl: './check-in-out-image-upload-dialog.component.html',
  styleUrls: ['./check-in-out-image-upload-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CheckInOutImageUploadDialogComponent implements OnInit, OnDestroy {
  @Input() job: Job;
  @Input() truckNumber: number;
  @Input() showActions: boolean;
  @Input() user: User;
  @Input() isCheckedIn: boolean;
  fileExtensions: string[] = ['png', 'jpg', 'jpeg'];
  response: any = [];
  projectId: string;
  get uploadSaveUrl() {
    return `${environment.apiBaseUrl}/api/uploads/save-images`;
  }
  uploadRemoveUrl = 'removeUrl';
  enabled = false;
  fileCount: number = 0;
  sliderImages: UploadFile[] = [];
  sliderFlexSize: string = '45';
  showLoading = 1;
  noRecordsFound = false;
  @ViewChild('upload') upload: UploadComponent;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public dialog: MatDialogRef<CheckInOutImageUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    private _jobODataService: JobODataService
  ) {
    this.store
      .select(selectProject)
      .pipe(filter((project) => !!project && !!project.id && !!project.companyName), takeUntil(this._unsubscribeAll))
      .subscribe((project) => {
        this.projectId = project.id;
      });
  }

  ngOnInit(): void {
    this.getImages();
  }

  getImages = (): void => {
    this._jobODataService.getById(this.data.jobId).pipe(takeUntil(this._unsubscribeAll)).subscribe((response) => {
      this.sliderImages = response.files?.filter(
        (x) => x.truckNumber === this.data.truckNumber
      );
      if (this.sliderImages?.length === 0 || !this.sliderImages) {
        this.noRecordsFound = true;
      }
      this.showLoading = 0;
      this.sliderFlexSize =
        this.filteredImages('check-in')?.length != 0 &&
          this.filteredImages('check-out')?.length != 0
          ? '45'
          : '80';
    });
  };

  filteredImages = (uploadType: string): UploadFile[] => {
    return this.sliderImages?.filter((x) => x.type === uploadType) || [];
  };

  public toggle(enabled: boolean) {
    this.enabled = enabled;
  }

  public onUploadButtonClick() {
    this.upload.uploadFiles();
  }

  public onClearButtonClick() {
    this.fileCount = 0;
    this.upload.clearFiles();
  }

  public onSelectEvent(e) {
    console.log(e);
    this.fileCount += 1;
  }

  uploadEventHandler(e: UploadEvent) {
    e.data = {
      type: this.data.type,
      projectId: this.projectId,
      jobId: this.data.jobId,
      truckNumber: this.data.truckNumber,
    };
    this.fileCount = 0;
  }

  onRemoveEvent(e, upload) {
    console.log(e, upload);
    if (this.fileCount > 0) {
      this.fileCount -= 1;
    }
  }

  successEventHandler(e: SuccessEvent) {
    console.log(e);
    console.log('The ' + e.operation + ' was successful!');
    this.response = e.response.body;
    this.dialog.close({
      data: this.response,
    });
  }

  close = (actionType: string): void => {
    let data = _.cloneDeep(this.response);

    if (!actionType) {
      data = null;
    }

    this.dialog.close({
      data: data,
    });
  };

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
