import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BaseIdName } from 'app/core/interfaces/base';
import { SelectedAssetBookJob } from 'app/core/interfaces/selected-asset';
import { SetCustomizedUnloadingMethods } from 'app/core/store/actions/common.actions';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectCustomizedUnloadingMethods } from 'app/core/store/selectors';
import { getAssetTypeName } from 'app/core/utils/utils';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-request-job-assets-details',
  templateUrl: './request-job-assets-details.component.html',
  styleUrls: ['./request-job-assets-details.component.scss'],
})

export class RequestJobAssetDetailsComponent implements OnInit, OnDestroy {
  @Input() assetForm: FormGroup;
  @Input() asset: SelectedAssetBookJob;
  @Input() allAssets: SelectedAssetBookJob[];
  @Input() unloadingMethods: BaseIdName[];
  @Input() truckTypes: BaseIdName[];

  tonnageUnitList = ['Kg', 'Tons'];
  isUnloadingMethodUpdated: boolean = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<AppState>,
    private _cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.store
      .select(selectCustomizedUnloadingMethods)
      .pipe(
        filter(unloadingMethod => !!unloadingMethod && unloadingMethod.length > 0 && this.asset.type === 'bay'),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe((unloadingMethod) => {
        this.unloadingMethods = unloadingMethod;
        const bayFCName = this.asset.fields['unloadingMethod'].toString();
        if (getAssetTypeName(unloadingMethod[0].id)) {
          this.isUnloadingMethodUpdated = true;
          this.assetForm.get(bayFCName).disable();
          this.asset.values['unloadingMethod'] = this.unloadingMethods;
          this.assetForm.patchValue({
            [bayFCName]: this.unloadingMethods,
          });
        } else {
          this.isUnloadingMethodUpdated = false;
          this.assetForm.get(bayFCName).enable();
          this.asset.values['unloadingMethod'] = null;
          this.assetForm.patchValue({
            [bayFCName]: null,
          });
        }
        this._cdr.detectChanges();
      });

    const hasBay = this.allAssets.some(item => item.type === 'bay');
    const hasOtherAssets = this.allAssets.some(item => item.type !== 'bay');

    if (hasBay && hasOtherAssets) {
      this.updateUnloadingMethods();
    }
  }

  setAssetValues(event, type: string): void {
    switch (type) {
      case 'pickUp':
      case 'dropOff':
        this.asset.values[type] = event;
        const fieldName = this.asset.fields[type].toString();
        this.assetForm.patchValue({
          [fieldName]: event,
        });
        break;

      case 'tonnage':
      case 'tonnageUnit':
      case 'truck':
      case 'maxWeight':
      case 'verticalTrip':
      case 'sizeDimension':
        this.asset.values[type] = event;
        break;

      case 'tclift':
      case 'mclift':
        this.asset.values['lift'] = event;
        break;

      case 'unloadingMethod':
        this.asset.values['unloadingMethod'] = event ? [{ id: event.id, name: event.name }] : [];
        break;

      case 'truckType':
        this.asset.values['truckType'] = event ? { id: event.id, name: event.name } : null;
        break;
    }
  }

  private updateUnloadingMethods(): void {
    if (this.allAssets.length === 0) return;

    const assetTypes = ['crane', 'mcrane', 'hoist', 'hot-seat', 'mhandling'];

    const updatedUnloadingMethods: BaseIdName[] = assetTypes
      .filter(type => this.allAssets.find(asset => asset.type === type))
      .map(type => ({
        id: type,
        name: getAssetTypeName(type),
      }));

    if (updatedUnloadingMethods.length > 0) {
      this.store.dispatch(new SetCustomizedUnloadingMethods(updatedUnloadingMethods));
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
