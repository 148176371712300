import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FuseModule } from '@fuse';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { UploadModule } from '@progress/kendo-angular-upload';
import { UserIdleModule } from 'angular-user-idle';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { environment } from 'environments/environment';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { MarkdownModule } from 'ngx-markdown';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { SpinnerInterceptor } from './core/interceptors/spinner.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { DrawerShowHideService } from './core/services/drawer-showhide-service';
import * as fromApp from './core/store/reducers/app.reducer';
import { LoadingLottieComponent } from './loading-lottie/loading-lottie.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent, LoadingSpinnerComponent, LoadingLottieComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),

    // store
    StoreModule.forRoot(fromApp.reducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),

    // Core module of your application
    CoreModule,
    MatSnackBarModule,

    // Layout module of your application
    LayoutModule,

    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),
    MatProgressSpinnerModule,
    DropDownsModule,
    LabelModule,
    ButtonsModule,
    UploadModule,
    HttpClientModule,
    DateInputsModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register(`${environment.appUrl}/sw.js`, {
      enabled: true,
      registrationStrategy: 'registerWhenStable',
    }),
    LottieModule.forRoot({ player: playerFactory }),
    UserIdleModule.forRoot({ idle: 20, timeout: 0, ping: 0 }),
  ],
  providers: [
    DrawerShowHideService,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent, LoadingLottieComponent],
})
export class AppModule { }
