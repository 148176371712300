import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { GridOperationsService } from '../grid-operations.service';
import { BaseGridEditService } from './base-grid-edit.service';

@Injectable({
  providedIn: 'root',
})
export class ContractorsGridEditService extends BaseGridEditService {
  constructor(
    protected http: HttpClient,
    protected store: Store<AppState>,
    protected gridOperationsService: GridOperationsService
  ) {
    super(http, 'contractors', store, gridOperationsService);
  }
}
