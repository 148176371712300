import { DialogInfo } from '../interfaces/dialog-info';

export const getDialogInfo = (action: string): DialogInfo => {
  let dialog: DialogInfo;
  switch (action) {
    case 'approve':
      dialog = {
        title: 'Approve',
        message: 'Are you sure you want to approve this job?',
        actionType: 'YesAndNo',
      };
      break;

    case 'cancel':
      dialog = {
        title: 'Cancel',
        message: 'Are you sure you want to cancel this job?',
        actionType: 'YesAndNo',
      };
      break;

    case 'complete':
      dialog = {
        title: 'Complete',
        message: 'Do you want to mark this job Completed ?',
        actionType: 'YesAndNo',
      };
      break;

    case 'error-duplicate-jobs':
      dialog = {
        title: 'Error',
        message:
          'Sorry, There are already jobs approved against the specific asset in same time slot,Please update the job time slot in order to perform this action',
        actionType: 'Ok',
      };
      break;

    case 'delete':
      dialog = {
        title: 'Delete',
        message: 'Are you sure to delete this job?',
        actionType: 'YesAndNo',
      };
      break;

    case 'submit':
      dialog = {
        title: 'Submit',
        message: 'Are you sure you want to submit for Approval?',
        actionType: 'YesAndNo',
      };
      break;

    case 'Boq Approved':
      dialog = {
        title: 'Approve',
        message: 'Are you sure you want to Approve?',
        actionType: 'YesAndNo',
      };
      break;

    case 'Boq Rejected':
      dialog = {
        title: 'Reject',
        message: 'Are you sure you want to Reject?',
        actionType: 'YesAndNo',
      };
      break;

    case 'updateTimeSlotCalendar':
      dialog = {
        title: 'Edit Time Slot',
        message: 'Are you sure you want to update start and end time for this job?',
        actionType: 'YesAndNo',
      };
      break;
  }
  return dialog;
};
