import { BooleanInput } from '@angular/cdk/coercion';
import {
    Component,
    Input,
    OnDestroy, ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectUser } from 'app/core/store/selectors';
import { Subject, takeUntil } from 'rxjs';
import { UpdatePasswordDialogComponent } from './update-password-dialog/update-password-dialog.component';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'userMenu',
})
export class UserMenuComponent implements OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    dialogRef: any;
    userName: string;
    userRole: string;
    accountName = '';
    isLoggedIn = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private store: Store<AppState>,
        private _router: Router,
        private _localStorageService: LocalStorageService,
        public _matDialog: MatDialog,
    ) {
        this.checkAccount();
    }

    checkAccount(): void {
        const token = this._localStorageService.get<string>('token');
        this.isLoggedIn = !!token && token !== '';

        if (this.isLoggedIn) {
            const user = JSON.parse(atob(token.split('.')[1]));
            this.getUserDetail();
        } else {
            this.userName = '';
        }
    }

    getUserDetail(): void {
        this.store.select(selectUser)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user) => {
                this.userName = user.name;
                this.userRole = user.roleName;
            });
    }

    onChangePassword(): void {
        this.dialogRef = this._matDialog.open(UpdatePasswordDialogComponent);

        this.dialogRef.afterClosed().subscribe((response) => {
            if (!response) {
                return;
            }
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        // if (!this.user) {
        //     return;
        // }
        // Update the user
        // this._userService
        //     .update({
        //         ...this.user,
        //         status,
        //     })
        //     .subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._localStorageService.remove('token');
        this._localStorageService.remove('refreshToken');
        this._localStorageService.remove('userRememberMe');

        this._router.navigate(['/sign-in'], { replaceUrl: true });
    }
}
