import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { QrcodedialogComponent } from 'app/components/settings/qrcodedialog/qrcodedialog.component';
import {
  Asset,
  Contractor,
  Gate,
  IODataParams,
  Job,
  LinkedJobHistory,
  LinkedJobsId,
  MileStone,
  PackageLocation,
  Project,
  TimeLog,
  User
} from 'app/core/interfaces';
import { BaseIdName } from 'app/core/interfaces/base';
import { AccessManagement, Setting } from 'app/core/interfaces/roles';
import { TruckType } from 'app/core/interfaces/truck-type';
import { UploadFile } from 'app/core/interfaces/upload-file';
import { JobDetailPanelService } from 'app/core/services/data-services/job-detail-panel.service';
import { JobService } from 'app/core/services/job.service';
import { AssetODataService } from 'app/core/services/odata/asset-odata.service';
import { ContractorODataService } from 'app/core/services/odata/contractor-odata.service';
import { GateODataService } from 'app/core/services/odata/gate-odata.service';
import { JobODataService } from 'app/core/services/odata/job-odata.service';
import { MilestoneODataService } from 'app/core/services/odata/milestone-odata.service';
import { TruckTypeODataService } from 'app/core/services/odata/truck-type-odata.service';
import { UnloadingMethodsODataService } from 'app/core/services/odata/unloading-method-odata.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import {
  selectUser,
  selectUserSettings
} from 'app/core/store/selectors/auth.selectors';
import {
  selectIsMobileView,
  selectProject
} from 'app/core/store/selectors/common.selectors';
import {
  computeEndDateTime,
  formatDate
} from 'app/core/utils/date-time.utils';
import { getDialogInfo } from 'app/core/utils/dialog.utils';
import { DELIVERY_NOTE_QR_LABEL, SNACKBAR_ACTION_ERROR, getAssetTypeIcon, getAssetTypeName, getHistory, isRolePermissionAllowed, showJobStatusSnackbar, showSnackbar } from 'app/core/utils/utils';
import { compare } from 'fast-json-patch';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';
import { dateTimeFormat } from '../../../core/constants/date-time-formats';
import { JobConfirmDialogComponent } from '../job-confirm-dialog/job-confirm-dialog.component';

@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.scss'],
})

export class EditJobComponent implements OnInit, OnDestroy {
  @Input() jobId: string;
  @Input() assetType: string;
  @Output() isDrawerOpened = new EventEmitter<boolean>();
  @Output() isPackageItemsToShow = new EventEmitter<boolean>();

  assetTypeDisplayName: string = '';
  job: Job;
  assets: Asset[];
  filteredAssets: Asset[];
  unloadingMethods: BaseIdName[] = [];
  gates: Gate[];
  statusClass: string = '';
  jobForm: FormGroup;
  user: User;
  settings: AccessManagement;
  projectId: string;
  dialogRef: any;
  fromAreaAndBay: string;
  areaAndActivity: string;
  levelAndZone: string;
  levelAndRoom: string;
  truckTypes: TruckType[] = [];
  showSlider = false;
  showCheckInSlider: boolean = false;
  showCheckOutSlider: boolean = false;
  showTimeLogs: boolean = false;
  project: Project;
  isUpdate: boolean = false;
  minDate = new Date();
  maxDate = new Date();
  isDateUpdate: boolean = false;
  isDateError: boolean = false;
  isEmailViewJobLink: boolean = false;
  showPackageItems: boolean = false;
  contractor: Contractor;
  area: MileStone;
  isBayRelatedJobs: boolean = false;
  showRelatedJobs: Job[] = [];
  isMobileView: boolean = false;
  combinedHistoryLogs: LinkedJobHistory[] = [];
  isContractorRole: boolean = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private jobODataSvc: JobODataService,
    private _snackBar: MatSnackBar,
    private _jobDetailPanelService: JobDetailPanelService,
    private store: Store<AppState>,
    private jobService: JobService,
    private _gatesService: GateODataService,
    private _matDialog: MatDialog,
    private route: ActivatedRoute,
    private _truckTypeODataService: TruckTypeODataService,
    private assetsService: AssetODataService,
    private _contractorODataService: ContractorODataService,
    private _mileStoneODataService: MilestoneODataService,
    private _unloadingMethodsODataService: UnloadingMethodsODataService,
  ) {
    this.store.select(selectUser).pipe(takeUntil(this._unsubscribeAll)).subscribe((user) => {
      this.user = user;
    });

    this.store
      .select(selectIsMobileView)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isMobileView) => {
        this.isMobileView = isMobileView;
      });

    this.selectProject();

    this.store
      .select(selectUserSettings)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: AccessManagement) => {
        if (response) {
          this.settings = response;

          if (isRolePermissionAllowed('manage-job-access', response)) {
            this.isContractorRole = true;
          }
        }
      });
  }

  addFormControls(assetType: string): void {
    if (assetType === 'hoist') {
      this.jobForm.get('maxWeight').setValidators([Validators.required]);
      this.jobForm.get('sizeDimensions').setValidators([Validators.required]);
      this.jobForm.get('verticalTrips').setValidators([Validators.required]);
    } else if (assetType === 'bay') {
      this.jobForm
        .get('trucks')
        .setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);

      if (this.unloadingMethods && this.unloadingMethods.length > 0) {
        this.jobForm
          .get('unloadingMethods')
          .setValidators([Validators.required]);
      }

      this.jobForm.get('truckTypeId').setValidators([Validators.required]);
    }

    this.jobForm.get('maxWeight').updateValueAndValidity();
    this.jobForm.get('sizeDimensions').updateValueAndValidity();
    this.jobForm.get('verticalTrips').updateValueAndValidity();

    this.jobForm.get('trucks').updateValueAndValidity();
    this.jobForm.get('unloadingMethods').updateValueAndValidity();
    this.jobForm.get('truckTypeId').updateValueAndValidity();
  }

  ngOnInit(): void {
    this.jobForm = new FormGroup({
      assetId: new FormControl(''),
      assetName: new FormControl(''),
      gateId: new FormControl(''),
      gateName: new FormControl(''),
      unloadingMethods: new FormControl(null),
      requestedStartTime: new FormControl(''),
      requestedEndTime: new FormControl(''),
      startTime: new FormControl(''),
      endTimeInHours: new FormControl(0),
      endTimeInMinutes: new FormControl(0),
      trucks: new FormControl(0),
      notes: new FormControl(''),
      lifts: new FormControl(0),
      maxWeight: new FormControl(''),
      sizeDimensions: new FormControl(''),
      verticalTrips: new FormControl(0),
      truckTypeId: new FormControl(''),
      truckTypeName: new FormControl(''),
    });

    this.initializeJobDetails();
  }

  initializeJobDetails(): void {
    if (this.jobId) {
      this.setAssets();
      this.setUnloadingMethods();
      this.setGates();
      this.setTruckTypes();
      this.getJobDetail();
    }
  }

  changeUpdate(): void {
    this.isUpdate = true;
  }

  changeDateUpdate(): void {
    const newSelectedDate = formatDate(this.jobForm.value.requestedStartTime);
    const oldSelectedDate = formatDate(this.job.requestedStartTime);

    const compareDates: boolean = moment(oldSelectedDate).isSame(newSelectedDate);

    if (compareDates) {
      this.isDateUpdate = false;
    }

    else {
      this.isDateUpdate = true;
      if (moment(formatDate(this.minDate)).isAfter(newSelectedDate) || moment(formatDate(this.maxDate)).isBefore(newSelectedDate)) {
        this.isDateError = true;
      }
      else {
        this.isDateError = false;
        this.isUpdate = true;
      }
    }
  }

  getPackagesForm(): void {
    if (this.job.package) {
      this.job.package.items.map((v, i) => {
        const controlName = `item${i}`;
        this.jobForm.addControl(controlName, new FormControl(v.quantity, [Validators.required, Validators.min(1)]));
      });
    }
  }

  updatePackage(event, id: string): void {
    const packageItem = this.job.package.items.find(x => x.id === id);
    packageItem.quantity = event.target.value;
  }

  getJobDetail = (): void => {
    this.jobODataSvc.getById(this.jobId).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.job = res;
      this.getPackagesForm();
      const emailViewjob = this.route.snapshot.queryParams.i;
      if (emailViewjob && this.job.id === emailViewjob) {
        this.isEmailViewJobLink = true;
      }
      this.job.comments = this.job.comments ? this.job.comments : [];
      this.job.jobsHistory = this.job.jobsHistory ? this.job.jobsHistory : [];
      this.job.timeLogs = this.job.timeLogs ? this.job.timeLogs : [];
      this.jobForm.patchValue(this.job);

      this.addFormControls(this.job.assetType);

      if (this.job.fromMilestoneName) {
        this.fromAreaAndBay = this.job.fromMilestoneName;
      }

      if (this.job.fromBayName) {
        this.fromAreaAndBay = this.fromAreaAndBay + ' - ' + this.job.fromBayName;
      }

      if (this.job.milestoneName) {
        this.areaAndActivity = this.job.milestoneName;
      }

      if (this.job.tasks && this.job.tasks.length > 0) {
        this.areaAndActivity = this.areaAndActivity + ' - (' + this.job.tasks.map(a => a.name).join(', ') + ')';
      }

      if (this.job.levelName) {
        this.levelAndZone = this.job.levelName;
      }

      if (this.job.zoneName) {
        this.levelAndZone = this.levelAndZone + ' - ' + this.job.zoneName;
      }

      if (this.job.roomName) {
        this.levelAndRoom = this.job.levelName + ' - ' + this.job.roomName;
      }

      this.jobForm.patchValue({
        startTime: moment(this.job.requestedStartTime).format('HH:mm:ss'),
      });

      const difference = moment.utc(
        moment(this.job.requestedEndTime, dateTimeFormat).diff(
          moment(this.job.requestedStartTime, dateTimeFormat)
        )
      );

      const endTimeInHours = difference.hours();
      const endTimeInMinutes = difference.minutes();
      this.jobForm.patchValue({
        endTimeInHours: endTimeInHours,
        endTimeInMinutes: endTimeInMinutes,
      });

      this.assetTypeDisplayName = getAssetTypeName(this.job.assetType);

      switch (this.job.status) {
        case 'pending':
        case 'requested':
          this.statusClass = 'requested';
          break;

        case 'completed':
          this.statusClass = 'completed';
          break;

        case 'booked':
        case 'approved':
          this.statusClass = 'approved';
          break;

        case 'cancelled':
          this.statusClass = 'rejected';
          break;

        default:
          break;
      }

      if (this.job.batchId) {
        const relatedJobsParams: IODataParams = {
          filter: `projectId eq '${this.projectId}' and isDeleted ne true and batchId eq '${this.job.batchId}' and isBatchJob eq false`,
          selects: ['id', 'assetType', 'assetName', 'jobsHistory'],
        };

        this.jobODataSvc.get(relatedJobsParams).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
          const data = res.value;
          this.showRelatedJobs = data.filter(x => x.id !== this.job.id);
          this.isBayRelatedJobs = data.some(x => x.assetType === "bay");
          if (this.showRelatedJobs.length > 0) {
            const historyLogs = this.job.jobsHistory.map((historyItem) => ({
              ...historyItem,
              assetName: historyItem.action !== 'Job Created' ? this.job.assetName : 'All',
            }));

            const relatedHistoryLogs = this.showRelatedJobs
              .flatMap((job) =>
                job.jobsHistory
                  .filter((historyItem) => historyItem.action !== 'Job Created')
                  .map((historyItem) => ({
                    ...historyItem,
                    assetName: job.assetName,
                  }))
              );

            this.combinedHistoryLogs = historyLogs.concat(relatedHistoryLogs);
            this.combinedHistoryLogs.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
          }
        });
      }
    });
  };

  openLinkedJob(linkedJob: Job): void {
    this.jobId = linkedJob.id;
    this.assetType = linkedJob.assetType;
    this.initializeJobDetails();
  }

  relatedAssetImage(relatedJob: Job): string {
    return getAssetTypeIcon(relatedJob.assetType);
  }

  filteredImages = (uploadType: string): UploadFile[] => this.job?.files?.filter(x => x.type === uploadType);

  viewQRCode(): void {
    this.dialogRef = this._matDialog.open(QrcodedialogComponent, {
      data: Object.assign(this.job, { codeType: DELIVERY_NOTE_QR_LABEL }),
      width: '400px'
    });
  }

  setAssets = (): void => {
    const params: IODataParams = {
      filter: `(projectId eq '${this.projectId}' and isDeleted ne true and isActive eq true and type eq '${this.assetType}')`,
      selects: ['id', 'name'],
      orderBy: ['name asc'],
    };

    this.assetsService.get(params).pipe(takeUntil(this._unsubscribeAll)).subscribe(assetResponse => {
      this.assets = assetResponse.value;

      this._contractorODataService.getById(this.job.contractorId).pipe(takeUntil(this._unsubscribeAll)).subscribe(contractorResponse => {
        this.contractor = contractorResponse;

        this._mileStoneODataService.getById(this.job.milestoneId).pipe(takeUntil(this._unsubscribeAll)).subscribe(milestoneResponse => {
          this.area = milestoneResponse;

          const contractorAssets = this.contractor.assets && this.contractor.assets?.length > 0
            ? this.assets.filter(a => this.contractor.assets.some(c => c.id === a.id))
            : this.assets;

          this.filteredAssets = this.area.assets && this.area.assets?.length > 0
            ? contractorAssets.filter(a => this.area.assets.some(c => c.id === a.id))
            : contractorAssets;

          if (!this.filteredAssets.find(x => x.id === this.job.assetId)) {
            const fetchAsset = this.assets.find(a => a.id === this.job.assetId);
            if (fetchAsset) {
              this.filteredAssets.push(fetchAsset);
            }
          }
        });
      });
    });
  };

  setUnloadingMethods = (): void => {
    this._unloadingMethodsODataService
      .get({
        filter: `(projectId eq '${this.projectId}' and isDeleted ne true)`,
        orderBy: ['name asc'],
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.unloadingMethods = [];
        const assetTypes = ['crane', 'mcrane', 'hoist', 'hot-seat', 'mhandling'];
        this.unloadingMethods = [
          ...res.value,
          ...assetTypes.map(type => ({
            id: type,
            name: getAssetTypeName(type),
          }))
        ];

      });
  };

  setGates = (): void => {
    this._gatesService
      .get({
        filter: `(projectId eq '${this.projectId}' and isDeleted ne true)`,
        orderBy: ['name asc'],
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.gates = res.value;
      });
  };

  setTruckTypes = (): void => {
    const params: IODataParams = {
      filter: `(projectId eq '${this.projectId}' and isDeleted ne true)`,
      orderBy: ['name asc'],
    };

    this._truckTypeODataService.get(params).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => this.truckTypes = res.value);
  };

  changeSelection(event, type): void {
    this.isUpdate = true;
    if (type === 'asset') {
      this.jobForm.patchValue({ assetName: event.source.triggerValue });
    } else if (type === 'gate') {
      this.jobForm.patchValue({ gateName: event.source.triggerValue });
    } else if (type === 'truck-types') {
      this.jobForm.patchValue({
        truckTypeName: event.source.triggerValue,
      });
    }
  }

  updateJob = (): void => {
    if (this.jobForm.valid) {
      const startDateTime =
        formatDate(this.jobForm.value.requestedStartTime) +
        'T' +
        this.jobForm.value.startTime;

      this.jobForm.value.requestedEndTime = new Date(
        computeEndDateTime(
          startDateTime,
          this.jobForm.value.endTimeInHours,
          this.jobForm.value.endTimeInMinutes
        )
      );

      this.jobForm.value.requestedStartTime = new Date(startDateTime);
      const jobObj = {
        assetId: this.job.assetId,
        assetName: this.job.assetName,
        gateId: this.job.gateId,
        gateName: this.job.gateName,
        requestedStartTime: this.job.requestedStartTime,
        requestedEndTime: this.job.requestedEndTime,
        trucks: this.job.trucks,
        lifts: parseInt(this.jobForm.value.lifts),
        maxWeight: this.job.maxWeight,
        sizeDimensions: this.job.sizeDimensions,
        verticalTrips: this.job.verticalTrips ? this.job.verticalTrips : 0,
        truckTypeId: this.job.truckTypeId ? this.job.truckTypeId : '',
        truckTypeName: this.job.truckTypeName ? this.job.truckTypeName : '',
      };

      const commentValue = {
        email: this.user.principalName,
        name: this.user.name,
        text: this.jobForm.value.notes,
        timestamp: new Date()
      };

      const patch = this.jobForm.value.notes ? compare(jobObj, {
        ..._.pick(this.jobForm.value, _.keys(jobObj)),
        jobsHistory: [
          ...this.job.jobsHistory,
          getHistory('Job Updated', this.user),
        ],
        comments: this.job.comments ? [...this.job.comments, commentValue] : [commentValue],
        package: this.job.package,
      }) : compare(jobObj, {
        ..._.pick(this.jobForm.value, _.keys(jobObj)),
        jobsHistory: [
          ...this.job.jobsHistory,
          getHistory('Job Updated', this.user),
        ],
        package: this.job.package,
      });

      this.jobODataSvc.patch(this.job.id, patch).subscribe(() => {
        if (this.isBayRelatedJobs) {
          const linkedJobId: LinkedJobsId = {
            id: this.job.id
          };

          this.jobODataSvc.bulkUpdateGateInfolinkedJobs(linkedJobId).subscribe(() => {
            this.jobService.refreshJobs();
            this.jobService.refreshSchedulerJobs();
          });
        }
        else {
          this.jobService.refreshJobs();
          this.jobService.refreshSchedulerJobs();
        }
      });

      this._jobDetailPanelService.changeData({ jobId: '' });
      showJobStatusSnackbar(this._snackBar, 'Updated');
      this.closeEditDrawer();
    }
  };

  cancelJob = (): void => {
    this.job.status = 'cancelled';
    this.job.jobsHistory.push(getHistory('Job Cancelled', this.user));
    this.jobODataSvc
      .put(this.job.id, this.job)
      .subscribe(() => {
        if (this.isUpdate) {
          this.updateJob();
        }

        this.jobService.refreshJobs();
        this.jobService.refreshSchedulerJobs();
      });

    this._jobDetailPanelService.changeData({ jobId: '' });
    showJobStatusSnackbar(this._snackBar, 'Cancelled');
    this.closeEditDrawer();
  };

  deleteJob = (): void => {
    const updatedJob = { ...this.job };
    updatedJob.isDeleted = true;
    updatedJob.jobsHistory = [...this.job.jobsHistory];
    updatedJob.jobsHistory.push(getHistory('Job Deleted', this.user));

    const body = compare(this.job, updatedJob);
    this.jobODataSvc.patch(this.job.id, body).subscribe(() => {
      this.jobService.refreshJobs();
      this.jobService.refreshSchedulerJobs();

      this._jobDetailPanelService.changeData({ jobId: '' });
      showJobStatusSnackbar(this._snackBar, 'Deleted');
      this.closeEditDrawer();
    });
  };

  approveJob = (): void => {
    if ((this.job.gateId === "" || this.job.gateId === null) && this.job.assetType === 'bay') {
      showSnackbar(this._snackBar, `Unable to approve this job, kindly provide Gate Information`, SNACKBAR_ACTION_ERROR);
    }
    else {
      this.job.status = 'approved';
      this.job.jobsHistory.push(getHistory('Job Approved', this.user));
      this.jobODataSvc
        .put(this.job.id, this.job)
        .subscribe(() => {
          if (this.isUpdate) {
            this.updateJob();
          }

          this.jobService.refreshJobs();
          this.jobService.refreshSchedulerJobs();
        });

      this._jobDetailPanelService.changeData({ jobId: '' });
      showJobStatusSnackbar(this._snackBar, 'Approved');
      this.closeEditDrawer();
    }
  };

  completeJob = (): void => {
    let packageLocation: PackageLocation[] = null;
    const timeLogs = _.cloneDeep(this.job.timeLogs);
    const existingTimeLog = this.getExistingOpenTimeLog(timeLogs);

    if (existingTimeLog) {
      existingTimeLog.endTime = moment().utc().format();
    }

    if (this.job.package !== null) {
      if (this.job.package?.items?.length > 0 &&
        this.job.package?.items !== null &&
        this.job.assetType === 'bay') {
        const packageLocationData: PackageLocation = {
          id: this.job.assetId,
          name: this.job.assetName,
          type: "Job Completed",
        }

        if (!this.job.packageLocation) {
          packageLocation = [packageLocationData];
        }
        else {
          packageLocation = [...this.job.packageLocation, packageLocationData];
        }

        this.job.packageLocation = packageLocation;
      }
    }

    this.job.status = 'completed';
    this.job.timeLogs = timeLogs;
    this.job.jobsHistory = [
      ...this.job.jobsHistory,
      getHistory('Job Time Log Completed', this.user),
      getHistory('Job Completed', this.user),
    ];

    this.jobODataSvc
      .put(this.job.id, this.job)
      .subscribe(() => {
        if (this.isUpdate) {
          this.updateJob();
        }

        this.jobService.refreshJobs();
        this.jobService.refreshSchedulerJobs();
      });

    this._jobDetailPanelService.changeData({ jobId: '' });
    showJobStatusSnackbar(this._snackBar, 'Completed');
    this.closeEditDrawer();
  };

  checkUserSettings(key: string): Setting[] {
    return this.settings.settings.filter(x => x.name === key && x.isChecked);
  }

  openConfirmDialog(action: string): void {
    if (action === 'approve') {
      this.jobODataSvc
        .checkApprovedJobsExists(this.job, this.projectId)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response) => {
          if (response.count > 0) {
            const jobs = response.value.filter(
              x =>
                x.requestedStartTime <= this.job.requestedEndTime &&
                x.requestedEndTime >= this.job.requestedStartTime
            );
            if (jobs.length === 0) {
              this.openDialogWithCallback(action);
            } else {
              const isDoubleBookingEnabled = this.project.appSettings.find(
                x => x.name === 'Double Booking' && x.isChecked
              );
              if (!isDoubleBookingEnabled) {
                this.showDuplicateJobError();
              } else {
                this.openDialogWithCallback(action);
              }
            }
          } else {
            this.openDialogWithCallback(action);
          }
        });
    } else {
      this.openDialogWithCallback(action);
    }
  }

  openDialogWithCallback(action: string): void {
    this.dialogRef = this._matDialog.open(JobConfirmDialogComponent, {
      data: getDialogInfo(action),
      width: '500px',
    });

    this.dialogRef.afterClosed().subscribe((response: string) => {
      if (!response) {
        return;
      }

      switch (action) {
        case 'approve':
          this.approveJob();
          break;

        case 'cancel':
          this.cancelJob();
          break;

        case 'complete':
          this.completeJob();
          break;

        case 'delete':
          this.deleteJob();
          break;
      }
    });
  }

  showDuplicateJobError = (): void => {
    this.dialogRef = this._matDialog.open(JobConfirmDialogComponent, {
      data: getDialogInfo('error-duplicate-jobs'),
      width: '500px',
    });
  };

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.viewPackageItems(false);
    if (tabChangeEvent.index === 4) {
      if (this.job?.files?.length > 0 && this.job.files != null) {
        setTimeout(() => {
          this.showSlider = true;
          const checkInData = this.job.files.find(x => x.type === 'check-in');
          const checkOutData = this.job.files.find(x => x.type === 'check-out');
          if (checkInData) {
            this.showCheckInSlider = true;
          }
          else {
            this.showCheckInSlider = false;
          }

          if (checkOutData) {
            this.showCheckOutSlider = true;
          }
          else {
            this.showCheckOutSlider = false;
          }
        }, 0);
      } else {
        this.job.files = [];
      }
    }
    else if (tabChangeEvent.index === 3) {
      if (this.job?.timeLogs?.length > 0 && this.job.timeLogs != null) {
        this.showTimeLogs = true;
      } else {
        this.job.timeLogs = [];
        this.showTimeLogs = false;
      }
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  selectProject = (): void => {
    this.store
      .select(selectProject)
      .pipe(filter(project => !!project && !!project.id), takeUntil(this._unsubscribeAll))
      .subscribe((project) => {
        this.projectId = project.id;
        this.project = project;

        if (project.maxDate && project.maxDate !== 0) {
          this.maxDate.setDate(this.maxDate.getDate() + project.maxDate);
        }
        else {
          this.maxDate.setDate(this.maxDate.getDate() + 1000);
        }
      });
  };

  closeEditDrawer(): void {
    this.isDrawerOpened.emit(false);
  }

  viewPackageItems(value: boolean): void {
    this.showPackageItems = value;
    this.isPackageItemsToShow.emit(value);
  }

  private getExistingOpenTimeLog(timeLogs: TimeLog[]): TimeLog {
    return timeLogs.find(x => !x.endTime && x.isActive);
  }
}
