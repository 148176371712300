import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Level } from '../../interfaces';
import { BaseODataService } from './base-odata.service';

@Injectable({
  providedIn: 'root',
})
export class LevelODataService extends BaseODataService<Level> {
  constructor(protected http: HttpClient) {
    super(http, 'levels');
  }
}
