import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectSpinner } from 'app/core/store/selectors/common.selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnDestroy {
    showLoader = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private store: Store<AppState>) {
        this.subscribeSpinner();
    }

    private subscribeSpinner(): void {
        this.store.select(selectSpinner)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((inProgress) => {
                this.showLoader = inProgress;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
