import { ContentTable, TableCell, TDocumentDefinitions } from 'pdfmake/interfaces';
import { IQrCodeItem } from '../interfaces/qrcode-item';
import { BAY_QR_LABEL, BOQ_QR_LABEL, DELIVERY_NOTE_QR_LABEL, ROOM_QR_LABEL } from './utils';

let FONT_SIZE = 8;
let QR_CODE_SIZE = 90;
let QR_CODES_PER_ROW = 2;
let QR_CODES_ROWS = 7;
let VERTICAL_MARGIN = 7;
let QR_CODES_PER_PAGE = QR_CODES_PER_ROW * QR_CODES_ROWS;

export const getMultipleDocsDefinition = (items: IQrCodeItem[]): TDocumentDefinitions => {
  const content: ContentTable[] = [];
  let contentIdx = 0;
  let startIdx = 0;

  const itemsInLastRow = items.length % QR_CODES_PER_ROW;
  if (itemsInLastRow !== 0) {
    Array(QR_CODES_PER_ROW - itemsInLastRow).fill(null).forEach(item => items.push(item));
  }

  items.forEach((item, index) => {
    if (index % QR_CODES_PER_PAGE === 0) {
      startIdx = index;
      contentIdx = index / QR_CODES_PER_PAGE;
      content[contentIdx] = getTableContent();
    }

    const body = content[contentIdx].table.body;

    if (index % QR_CODES_PER_ROW === 0) {
      body[(index - startIdx) / QR_CODES_PER_ROW] = [];
    }

    body[Math.floor((index - startIdx) / QR_CODES_PER_ROW)][index % QR_CODES_PER_ROW] = getTableCell(item);
  });

  if (content.length > 0) {
    content[content.length - 1].pageBreak = undefined;
  }

  return {
    content,
    styles: {
      stack: {
        margin: [0, VERTICAL_MARGIN]
      },
      labelStyle: {
        bold: true,
        fontSize: FONT_SIZE,
        alignment: 'left',
        margin: [0, 0, 2, 2] // [left, top, right, bottom]
      },
      valueStyle: {
        alignment: 'left',
        fontSize: FONT_SIZE,
      },
    },
    pageMargins: [60, 60, 60, 20]
  };
};

function getTableContent(): ContentTable {
  return {
    table: {
      widths: Array(QR_CODES_PER_ROW).fill('*'),
      body: []
    },
    layout: {
      hLineStyle: () => ({ dash: { length: 4 } }),
      vLineStyle: () => ({ dash: { length: 4 } })
    },
    pageBreak: 'after'
  };
}

function getTableCell(item: IQrCodeItem): TableCell | ContentTable {
  let stackData: any[] = [];
  const qrItem = item !== null ? {
    image: item.qrBase64,
    fit: [QR_CODE_SIZE, QR_CODE_SIZE],
    alignment: 'left',
  } : '';

  if (item !== null) {
    const { codeType, ...data } = item;

    switch (codeType) {
      case DELIVERY_NOTE_QR_LABEL:
        stackData = [
          createLabelValueColumn('Project:', data.projectName),
          createLabelValueColumn('Requester:', data.requesterName),
          createLabelValueColumn('Area | Level:', data.areaLevel),
          createLabelValueColumn('Contractor:', data.contractorName),
          createLabelValueColumn('Gate:', data.gateName),
          createLabelValueColumn('Bay:', data.assetName),
        ];
        break;

      case ROOM_QR_LABEL:
        stackData = [
          createLabelValueColumn('Project:', data.projectName),
          createLabelValueColumn('Level:', data.levelName),
          createLabelValueColumn('Room:', data.roomName),
        ];
        break;

      case BOQ_QR_LABEL:
        stackData = [
          createLabelValueColumn('Project:', data.projectName),
          createLabelValueColumn('Package:', data.packageTitle),
          createLabelValueColumn('Description:', data.materialDescription),
        ];
        break;

      case BAY_QR_LABEL:
        stackData = [
          createLabelValueColumn('Project:', data.projectName),
          createLabelValueColumn('Bay:', data.bayName),
        ];
        break;

      default:
        console.error(`Unknown code type: ${codeType}`);
        break;
    }
  }

  return {
    table: {
      width: ['auto', '*'],
      body: [
        [qrItem, {
          style: 'stack',
          stack: stackData,
          alignment: 'left',
          marginTop: 15,
        }]
      ]
    },
    layout: 'noBorders'
  };
}

const createLabelValueColumn = (label: string, value: string): any => {
  return {
    columns: [
      {
        text: label,
        style: 'labelStyle',
        width: 'auto',
      },
      {
        text: value,
        style: 'valueStyle'
      }
    ],
  };
}
