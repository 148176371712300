import { Action } from '@ngrx/store';
import { UserProject } from 'app/core/interfaces/user-project';
import { User } from '../../interfaces/user';

export const LOAD_USER = '[Auth] Load User';
export const USER_LOGIN = 'USER_LOGIN';
export const SET_USER = '[Auth] Set User';
export const SET_USER_PROJECTS = '[Auth] Set User Projects';

export class LoadUserAction implements Action {
    readonly type = LOAD_USER;

    constructor(public payload: boolean) { }
}

export class UserLoginAction implements Action {
    readonly type = USER_LOGIN;

    constructor(public payload: any) { }
}

export class SetUserAction implements Action {
    readonly type = SET_USER;

    constructor(public payload: User) { }
}

export class SetUserProjectsAction implements Action {
    readonly type = SET_USER_PROJECTS;

    constructor(public payload: UserProject[]) { }
}

export type AuthActions =
    | LoadUserAction
    | SetUserAction
    | UserLoginAction
    | SetUserProjectsAction;
