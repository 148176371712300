import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Export_Columns } from 'app/core/utils/exportdata-columns';
import { SNACKBAR_ACTION_SUCCESSFUL, showSnackbar } from 'app/core/utils/utils';
import * as ExcelJS from 'exceljs';
import FileSaver from "file-saver";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})

export class ExcelExportGenericService {
  constructor(
    private _snackBar: MatSnackBar,
    private _router: Router,
  ) { }

  exportToExcel(data: any[], columns: Export_Columns[], fileName: string, projectName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const hasProjectNameColumn = columns.some(column => column.key === 'projectName');
    if (!hasProjectNameColumn && this._router.url !== "/project-list") {
      columns.unshift({ header: 'Project Name', key: 'projectName', width: 50 });
    }

    const hasSerialNumberColumn = columns.some(column => column.key === 'serialNumber');
    if (!hasSerialNumberColumn) {
      columns.unshift({ header: 'S/N', key: 'serialNumber', width: 9 });
    }

    // Set column headers and widths
    worksheet.columns = columns;
    const keyString = `{${columns.map(column => column.key).join(',')}}`;
    let serialNumber = 1; // Initialize the serial number counter

    if (this._router.url === "/settings/general/contractors") {
      const usersColumn = worksheet.getColumn('users');
      usersColumn.alignment = { wrapText: true };

      const areasColumn = worksheet.getColumn('areas');
      areasColumn.alignment = { wrapText: true };

      const assetsColumn = worksheet.getColumn('assets');
      assetsColumn.alignment = { wrapText: true };

      const packagesColumn = worksheet.getColumn('packages');
      packagesColumn.alignment = { wrapText: true };

      const activitiesColumn = worksheet.getColumn('activities');
      activitiesColumn.alignment = { wrapText: true };
    }

    if (this._router.url === "/settings/general/milestones" && fileName === "Areas") {
      const assetsColumn = worksheet.getColumn('assets');
      assetsColumn.alignment = { wrapText: true };
    }

    data.forEach((item) => {
      const rowValues = {};

      keyString
        .substring(1, keyString.length - 1)
        .split(',')
        .forEach((key) => {
          if (key === "serialNumber") {
            rowValues[key] = serialNumber++;
          }
          else if (key === "projectName" && this._router.url !== "/project-list") {
            rowValues[key] = projectName;
          }
          else if (
            (key === "users" || key === "areas" || key === "assets" || key === "packages" || key === "activities") &&
            (this._router.url === "/settings/general/contractors" || this._router.url === "/settings/general/milestones")
          ) {
            const arrayData = item[key.trim()];
            if (arrayData !== null) {
              rowValues[key] = arrayData.map(v => v.name).join(', ');
            }
          }
          else if (key === "package" && this._router.url === "/movement-request") {
            const arrayData = item[key.trim()];
            if (arrayData !== null) {
              rowValues[key] = arrayData.name;
            }
          }
          else {
            rowValues[key] = item[key.trim()];
          }
        });

      worksheet.addRow(rowValues);
    });

    // Apply filters to the header row
    worksheet.autoFilter = {
      from: { row: 1, column: 1 },
      to: { row: data.length + 1, column: columns.length }
    };

    worksheet.views = [
      { state: 'frozen', xSplit: 0, ySplit: 1, topLeftCell: 'A2', activeCell: 'A1' }
    ];

    const firstRow = worksheet.getRow(1);
    firstRow.height = 30;

    firstRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '808080' },
    };

    firstRow.font = {
      color: { argb: 'ffffff' },
      size: 12,
      bold: true
    };

    workbook.xlsx.writeBuffer().then((xlsxData) => {
      const blob: Blob = new Blob([xlsxData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const currentDateTime = moment().format();
      FileSaver.saveAs(blob, fileName + `-${currentDateTime}.xlsx`);
      showSnackbar(this._snackBar, 'Data Exported Successfully', SNACKBAR_ACTION_SUCCESSFUL);
    });
  }
}
