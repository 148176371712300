import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { BoqItem } from 'app/core/interfaces/boq';

@Component({
  selector: 'app-packages-list-dialog',
  templateUrl: './packages-list-dialog.component.html',
  styleUrls: ['./packages-list-dialog.component.scss']
})

export class PackagesListDialogComponent {
  boq: BoqItem[] = [];
  selectedBoqs: any[] = [];
  showPager: boolean = true;

  public gridView: GridDataResult;
  public pageSize = 10;
  public skip = 0;

  constructor(
    public dialog: MatDialogRef<PackagesListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.boq = data;
    this.loadPackageItems();
  }

  changeChecked($event, item): void {
    if ($event.target.checked) {
      this.selectedBoqs.push({
        id: item.id,
        name: item.materialDescription,
        allowableQuantity: item.materialQuantity - item.deliveredQuantity,
        measuredUnit: item.measuredUnit,
      });
    }
    else {
      this.selectedBoqs = this.selectedBoqs.filter(x => x.id !== item.id);
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadPackageItems();
  }

  public onFilter(inputValue: string): void {
    this.gridView.data = process(this.boq, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'materialDescription',
            operator: 'contains',
            value: inputValue,
          }
        ],
      },
    }).data;
    this.gridView.total = this.gridView.data.length;
    this.skip = 0;
  }

  private loadPackageItems(): void {
    this.gridView = {
      data: this.data.slice(this.skip, this.skip + this.pageSize),
      total: this.data.length,
    };

    this.showPager = this.data.length > this.pageSize;

    if (this.selectedBoqs.length > 0) {
      this.selectedBoqs.forEach((sb) => {
        const index = this.gridView.data.findIndex(x => x.id === sb.id);
        if (index !== -1) {
          this.gridView.data.find(x => x.id === sb.id).isSelected = true;
        }
      });
    }
  }
}
