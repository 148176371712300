import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from 'app/core/interfaces';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { Operation } from 'fast-json-patch';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Role } from '../../interfaces/roles';
import { CreateRole } from '../../interfaces/create-role';
import { tap } from 'rxjs/operators';
import { LoadUserAction } from 'app/core/store/actions/auth.actions';
import { LoginResponse } from 'app/core/interfaces/login-response';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { Login } from 'app/core/interfaces/login';
import { ChangePassword } from 'app/core/interfaces/change-password';
import { ForgotPassword } from 'app/core/interfaces/forgot-password';
import { ResetPassword } from 'app/core/interfaces/reset-password';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  controller: string = 'auth';

  constructor(
    protected http: HttpClient,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService
  ) { }

  protected get baseUrl(): string {
    return `${environment.apiBaseUrl}/api/${this.controller}`;
  }

  getSettingsByRole = (roleId: string): Observable<Role> =>
    this.http.get<Role>(`${this.baseUrl}/role-settings/${roleId}`);

  getRoles = (projectId): Observable<Role[]> => this.http.get<Role[]>(`${this.baseUrl}/roles/${projectId}`);

  getUsers = (projectId: string): Observable<User[]> =>
    this.http.get<User[]>(`${this.baseUrl}/users?projectId=${projectId}`)
      .pipe(tap({
        next: users => users.sort((a, b) => a.name > b.name ? 1 : -1)
      }));

  getUnlinkedUsers = (projectId: string): Observable<User[]> =>
    this.http.get<User[]>(`${this.baseUrl}/unlinked-users?projectId=${projectId}`)
      .pipe(tap({
        next: users => users.sort((a, b) => a.name > b.name ? 1 : -1)
      }));

  getUserById = (id: string): Observable<User> => {
    this.store.dispatch(new LoadUserAction(true));
    return this.http.get<User>(`${this.baseUrl}/users/${id}`).pipe(
      tap((user) => {
        this.store.dispatch(new LoadUserAction(false));
        return user;
      })
    );
  };

  addUser = (body: User): Observable<User> => {
    return this.http.post<User>(`${this.baseUrl}/users`, body);
  };

  updateUser = (id: string, body: User): Observable<User> => this.http.put<User>(`${this.baseUrl}/users/${id}`, body);

  updateUserBulk = (body: User[]): Observable<User> => this.http.put<User>(`${this.baseUrl}/users`, body);

  patchUser = (id: string, body: Operation[]): Observable<User> => this.http.patch<User>(`${this.baseUrl}/users/${id}`, body);

  patchRole = (id: string, body: Operation[]): Observable<Role> => this.http.patch<Role>(`${this.baseUrl}/roles/${id}`, body);

  createRole = (body: CreateRole): Observable<Role> => this.http.post<Role>(`${this.baseUrl}/roles`, body);

  getRoleUserCount = (roleId: string): Observable<number> => this.http.get<number>(`${this.baseUrl}/roles/${roleId}/usercount`);

  deleteRole = (id: string): Observable<Role> => this.http.delete<Role>(`${this.baseUrl}/roles/${id}`);

  deleteUser = (id: string): Observable<User> => this.http.delete<User>(`${this.baseUrl}/users/${id}`);

  login = (login: Login): Observable<LoginResponse> => this.http
    .post<LoginResponse>(`${this.baseUrl}/login`, login)
    .pipe(tap(loginResponse => this.setAuthToken(loginResponse)));

  private setAuthToken(loginResponse: LoginResponse): LoginResponse {
    if (loginResponse.token != '') {
      this.localStorageService.set('token', loginResponse.token);
      this.localStorageService.set('refreshToken', loginResponse.refreshToken);
    }

    return loginResponse;
  }

  refreshToken = (
    token: string,
    refreshToken: string
  ): Observable<LoginResponse> => this.http.post<LoginResponse>(`${this.baseUrl}/refresh`, {
    token: token,
    refreshToken: refreshToken,
  });

  changePassword(changePassword: ChangePassword): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.baseUrl}/changePassword`,
      changePassword
    );
  }

  forgotPassword(forgotPassword: ForgotPassword): Observable<boolean> {
    var email = { email: forgotPassword };
    return this.http.post<boolean>(
      `${this.baseUrl}/forgot-password`,
      email
    );
  }

  isValidResetPasswordToken(token: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.baseUrl}/validate-reset-password-token/${token}`,
      null
    );
  }

  resetPassword(resetPassword: ResetPassword): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(`${this.baseUrl}/reset-password`, resetPassword)
      .pipe(tap(response => this.setAuthToken(response)));
  }
}
