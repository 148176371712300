import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class DrawerShowHideService {
    public isDrawerShow: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(null);
    cast = this.isDrawerShow.asObservable();

    constructor() {}
    showDrawer = (isShow: boolean) => this.isDrawerShow.next(isShow);
}
