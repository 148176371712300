import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FuseDrawerService } from '@fuse/components/drawer';

@Component({
    selector: 'alec-settings',
    templateUrl: './settings.component.html',
})

export class SettingsComponent implements OnInit {
    constructor(
        private _router: Router,
        private _fuseDrawerService: FuseDrawerService
    ) { }

    ngOnInit(): void { }

    redirectSettings(url: string): void {
        this._router.navigate([url]);
    }

    openSettingsDrawer = (): void => {
        const drawer = this._fuseDrawerService.getComponent('projectInfoDrawer');
        drawer.toggle();
    };
}
