import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CustomFuseDrawerPanelService {
  private dataSource = new BehaviorSubject(null);
  data = this.dataSource.asObservable();

  constructor() { }

  changeData(data: CustomFuseDrawerData): void {
    this.dataSource.next(data);
  }
}

export interface CustomFuseDrawerData {
  type: string;
  title: string;
  data: any;
}
