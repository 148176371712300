import { Component } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading-lottie',
  templateUrl: './loading-lottie.component.html',
  styleUrls: ['./loading-lottie.component.scss']
})
export class LoadingLottieComponent {

  options: AnimationOptions = {
    path: '/assets/img/splashlogo.json',
  };

  animationCreated(animationItem: AnimationItem): void { }

  constructor() { }
}
