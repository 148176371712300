export const environment = {
  production: false,
  pwa: true,
  hmr: false,
  apiBaseUrl: 'https://api-dev.loadgate.com',
  // apiBaseUrl: 'https://localhost:5001',
  powerBi: {
    reportBaseUrl: 'https://app.powerbi.com/reportEmbed',
    reportId: '3507fbe8-7c97-4466-a551-bb0e635097e8',
  },
  tahadyRequirementsMovementRequest: true,
};
