import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ItemDetails } from 'app/core/interfaces';

@Component({
  selector: 'app-job-packages',
  templateUrl: './job-packages.component.html',
  styleUrls: ['./job-packages.component.scss']
})

export class JobPackagesComponent implements OnInit, AfterViewInit {
  @Input() packageItems: ItemDetails[];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<ItemDetails>;
  displayedColumns: string[] = ['itemName', 'quantity'];

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<ItemDetails>(this.packageItems || []);
  }
}

