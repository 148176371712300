import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Room } from 'app/core/interfaces/room';
import { BaseODataService } from './base-odata.service';

@Injectable({
  providedIn: 'root',
})

export class RoomODataService extends BaseODataService<Room> {
  constructor(protected http: HttpClient) {
    super(http, 'rooms');
  }
}
