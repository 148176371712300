import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { AuthService } from '../services/custom-services/auth.service';
import { map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _localStorageService: LocalStorageService,
    private _router: Router
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = String(this._localStorageService.get('token'));

    if (!!token && token != 'null') {
      const isTokenExpired = this.getIsTokenExpired(token);

      if (isTokenExpired) {
        if (
          request.url.indexOf('api/auth/refresh') === -1 &&
          request.url.indexOf('api/auth/login') === -1
        ) {
          return this.refreshToken(token).pipe(
            switchMap((token) => {
              if (token) {
                request = request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${token}`,
                  },
                });

                return next.handle(request);
              }
            })
          );
        } else {
          return next.handle(request);
        }
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });

        return next.handle(request);
      }
    } else {
      return next.handle(request);
    }
  }

  refreshToken(token: string): Observable<string> {
    const refreshToken = String(this._localStorageService.get('refreshToken'));

    return this._authService.refreshToken(token, refreshToken).pipe(
      map((response) => {
        if (response.token && response.refreshToken) {
          this._localStorageService.set('token', response.token);
          this._localStorageService.set('refreshToken', response.refreshToken);
        } else {
          this._localStorageService.remove('token');
          this._localStorageService.remove('refreshToken');

          this._router.navigateByUrl('/auth/login');
          return '';
        }

        return response.token;
      })
    );

    //return respo
  }

  getIsTokenExpired(token: string): boolean {
    let user = JSON.parse(atob(token.split('.')[1]));

    let expiry = user.exp;
    let now = Math.floor(Date.now() / 1000);
    return expiry <= now;
  }
}
