import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { JobComment } from 'app/core/interfaces';
import * as moment from 'moment';

@Component({
  selector: 'app-job-comments',
  templateUrl: './job-comments.component.html',
  styleUrls: ['./job-comments.component.scss']
})

export class JobCommentsComponent implements OnInit, AfterViewInit {
  @Input() comments: JobComment[];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<JobComment>;
  displayedColumns: string[] = ['when', 'comment', 'by'];

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<JobComment>(this.comments || []);
  }

  formatDate = (date): string => moment(date).format('lll');
}
