import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { CdTimerComponent } from 'angular-cd-timer';
import { QrcodedialogComponent } from 'app/components/settings/qrcodedialog/qrcodedialog.component';
import { dateTimeFormat } from 'app/core/constants/date-time-formats';
import { ExtensionTimeData, TimeLog, User } from 'app/core/interfaces';
import { JobService } from 'app/core/services/job.service';
import { JobODataService } from 'app/core/services/odata/job-odata.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectUser } from 'app/core/store/selectors/auth.selectors';
import { DELIVERY_NOTE_QR_LABEL, SNACKBAR_ACTION_SUCCESSFUL, getAssetTypeIcon, getHistory, getJobMilestoneName, showJobStatusSnackbar, showSnackbar } from 'app/core/utils/utils';
import { compare } from 'fast-json-patch';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { take } from 'rxjs/operators';
import { Job, PackageLocation } from '../../../core/interfaces/job';
import {
  calculateDuration,
  formatDateTime,
} from '../../../core/utils/date-time.utils';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss'],
})

export class JobDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cdTimer') private cdTimer: CdTimerComponent;
  @Input() job: Job;
  @Output() timeDetailsEvent = new EventEmitter<boolean>();
  @Output() isDrawerOpened = new EventEmitter<boolean>();
  isTimeDetails: boolean = false;

  actualStartTime: string;
  currentDuration: string;
  extension: ExtensionTimeData;
  extensionForm: FormGroup;
  timeExtension: { hours: string; mins: string; reason: string };
  selectedPickUpSection: number;
  selectedDropOffSection: number;

  area: string = '';
  taskNames: string = '';
  autoStart = false;
  cdStartTime = 0;
  closedDurationInSeconds = 0;

  config: any = {
    leftTime: this.getJobDuration(),
  };

  dialogRef: any;
  durationInSeconds: any;
  endTime: string;
  fetchTime = '00:00:00';
  isJobStarted = false;
  notes = '';
  showJobDetails: boolean;
  startTime: string;
  timeExtensions: ExtensionTimeData;
  user: User;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private jobODataService: JobODataService,
    private store: Store<AppState>,
    private _snackBar: MatSnackBar,
    private _matDialog: MatDialog,
    private jobService: JobService
  ) {
    this.timeExtensions = { mins: '', hours: '', reason: '' };

    this.store.select(selectUser).pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
      this.user = user;
    });

    this.extension = this.timeExtensions;

    this.extensionForm = this.createExtensionForm();
  }

  get duration(): string {
    return calculateDuration(
      this.job.requestedStartTime,
      this.job.requestedEndTime
    );
  }

  get startDateTime(): string {
    return formatDateTime(this.job.requestedStartTime);
  }

  get startJobLabel(): string {
    return this.job?.timeLogs?.length === 0 || !this.job?.timeLogs
      ? 'Start Job'
      : 'Resume Job';
  }

  get startJobLabelCrane(): string {
    return this.job?.timeLogs?.length === 0 || !this.job?.timeLogs
      ? 'Rigging'
      : 'Holding';
  }

  getSelectedCoordinates = (data: number, selectedType: string): void => {
    if (selectedType === 'Pickup') {
      this.selectedPickUpSection = data;
    } else {
      this.selectedDropOffSection = data;
    }
  };

  async ngOnInit(): Promise<void> {
    if (this.job.timeLogs) {
      this.calculateClosedDuration();
      this.cdStartTime = this.closedDurationInSeconds;

      const timeLog = this.getExistingOpenTimeLog(this.job.timeLogs);
      if (timeLog) {
        const currentTime = moment(
          moment().utc().format(dateTimeFormat),
          dateTimeFormat
        );

        const startTime = moment(timeLog.startTime, dateTimeFormat);
        this.cdStartTime += currentTime.diff(startTime, 'seconds');
        this.autoStart = true;
        this.isJobStarted = true;
        this.actualStartTime = moment(this.job.timeLogs[0].startTime).format(
          'HH:mm:ss'
        );
      }
    }

    this.area = getJobMilestoneName(this.job);
    this.taskNames = this.job.tasks.map(a => a.name).join(', ');
    this.currentDuration = this.duration;
    this.startTime = moment(this.job.requestedStartTime).format('lll');
  }

  ngAfterViewInit(): void {
    this.cdTimer.start();

    if (!this.autoStart) {
      this.cdTimer.onTick.pipe(take(1)).subscribe(() => this.cdTimer.stop());
    }
  }

  close(): void {
    this.isDrawerOpened.emit(false);
  }

  setTimeDetails(): void {
    this.isTimeDetails = true;
    this.timeDetailsEvent.emit(this.isTimeDetails);
  }

  startStopJob = (): void => {
    let packageLocation: PackageLocation[] = null;
    let patch = null;

    this.isJobStarted = !this.isJobStarted;
    const timeLogs =
      this.job.timeLogs && this.job.timeLogs?.length > 0
        ? _.cloneDeep(this.job.timeLogs)
        : [];

    const jobTimeLog = {
      userId: this.user.id,
      userName: this.user.name,
      startTime: null,
      assetId: '',
      assetType: '',
      isActive: true,
    };

    let operatorObj = {};

    let type = '';
    if (this.isJobStarted) {
      type = 'Job Started';
      this.cdTimer.resume();
      jobTimeLog.startTime = moment().utc().format();
      timeLogs.push(jobTimeLog);

      operatorObj = {
        operatorId: this.user.id,
        operatorName: this.user.name,
        operatorEmail: this.user.principalName,
      };
    } else {
      type = 'Job Ended';
      this.cdTimer.stop();
      const existingTimeLog = this.getExistingOpenTimeLog(timeLogs);
      existingTimeLog.endTime = moment().utc().format();

      operatorObj = {
        operatorId: '',
        operatorName: '',
        operatorEmail: '',
      };
    }

    const jobHistory = [...this.job.jobsHistory, getHistory(type, this.user)];

    if (this.job.package !== null) {
      if (this.job.package?.items?.length > 0 &&
        this.job.package?.items !== null &&
        this.isJobStarted &&
        this.job.assetType === 'bay') {
        const packageLocationData: PackageLocation = {
          id: this.job.gateId,
          name: this.job.gateName,
          type: type,
        }

        if (!this.job.packageLocation) {
          packageLocation = [packageLocationData];
        }
        else {
          packageLocation = [...this.job.packageLocation, packageLocationData];
        }

        patch = compare(
          {
            operatorId: this.job.operatorId,
            operatorName: this.job.operatorName,
            operatorEmail: this.job.operatorEmail,
            timeLogs: this.job.timeLogs,
            jobsHistory: this.job.jobsHistory,
            packageLocation: this.job.packageLocation,
          },
          { ...operatorObj, timeLogs, jobsHistory: jobHistory, packageLocation }
        );
      }
      else {
        patch = compare(
          {
            operatorId: this.job.operatorId,
            operatorName: this.job.operatorName,
            operatorEmail: this.job.operatorEmail,
            timeLogs: this.job.timeLogs,
            jobsHistory: this.job.jobsHistory,
          },
          { ...operatorObj, timeLogs, jobsHistory: jobHistory }
        );
      }
    }
    else {
      patch = compare(
        {
          operatorId: this.job.operatorId,
          operatorName: this.job.operatorName,
          operatorEmail: this.job.operatorEmail,
          timeLogs: this.job.timeLogs,
          jobsHistory: this.job.jobsHistory,
        },
        { ...operatorObj, timeLogs, jobsHistory: jobHistory }
      );
    }

    this.jobODataService.patch(this.job.id, patch).subscribe(() => {
      this.getJobTimeLogs(this.job.id);
      this.jobService.refreshJobs();
      this.close();
    });
  };

  showAssetType(assetType: string): boolean {
    return this.job.assetType === assetType;
  }

  completeJob(jobId: string): void {
    let packageLocation: PackageLocation[] = null;
    let patch = null;

    const timeLogs = _.cloneDeep(this.job.timeLogs);
    const existingTimeLog = this.getExistingOpenTimeLog(timeLogs);

    if (existingTimeLog) {
      this.cdTimer.stop();
      existingTimeLog.endTime = moment().utc().format();
    }

    const jobHistory = [
      ...this.job.jobsHistory,
      getHistory('Job Time Log Completed', this.user),
    ];

    if (this.job.package !== null) {
      if (this.job.package?.items?.length > 0 &&
        this.job.package?.items !== null &&
        this.job.assetType === 'bay') {
        const packageLocationData: PackageLocation = {
          id: this.job.assetId,
          name: this.job.assetName,
          type: "Job Completed",
        }

        if (!this.job.packageLocation) {
          packageLocation = [packageLocationData];
        }
        else {
          packageLocation = [...this.job.packageLocation, packageLocationData];
        }

        patch = compare(
          {
            timeLogs: this.job.timeLogs,
            status: this.job.status,
            jobsHistory: this.job.jobsHistory,
            packageLocation: this.job.packageLocation,
          },
          { timeLogs, status: 'completed', jobsHistory: jobHistory, packageLocation }
        );
      }
      else {
        patch = compare(
          {
            timeLogs: this.job.timeLogs,
            status: this.job.status,
            jobsHistory: this.job.jobsHistory,
          },
          { timeLogs, status: 'completed', jobsHistory: jobHistory }
        );
      }
    }
    else {
      patch = compare(
        {
          timeLogs: this.job.timeLogs,
          status: this.job.status,
          jobsHistory: this.job.jobsHistory,
        },
        { timeLogs, status: 'completed', jobsHistory: jobHistory }
      );
    }

    this.jobODataService.patch(jobId, patch).subscribe(() => {
      showJobStatusSnackbar(this._snackBar, 'Completed');
      this.jobService.jobCompleted.next(true);
      this.jobService.refreshJobs();
      this.close();
    });
  }

  getJobTimeLogs(jobId: string): Promise<void> {
    return new Promise<void>(resolve => {
      this.jobODataService.getById(jobId).pipe(takeUntil(this._unsubscribeAll)).subscribe(job => {
        this.job.timeLogs = job.timeLogs;
        resolve();
      });
    });
  }

  updateNotes(): void {
    const commentValue = {
      email: this.user.principalName,
      name: this.user.name,
      text: this.notes,
      timestamp: new Date(),
    };

    const updatedComments = [...this.job.comments, commentValue];
    const patch = compare({ comments: this.job.comments }, { comments: updatedComments });

    if (patch.length !== 0) {
      this.jobODataService.patch(this.job.id, patch).subscribe(() => {
        showSnackbar(this._snackBar, 'Notes updated successfully', SNACKBAR_ACTION_SUCCESSFUL);
        this.job.comments = updatedComments;
      });
    }
  }

  submit(): void {
    this.updateTime(
      this.extensionForm.value.mins,
      this.extensionForm.value.hours,
      this.extensionForm.value.reason
    );
  }

  updateTime(mins: string, hours: string, reason: string): void {
    const extendedTime = this.computeEndTime(
      this.job.requestedEndTime,
      hours,
      mins
    );
    const patch = compare(
      {},
      { requestedEndTime: extendedTime, extensionReason: reason }
    );

    this.jobODataService
      .patch(this.job.id, patch)
      .subscribe(() => {
        this.jobService.refreshJobs();
        this.close();
      });
  }

  formatStartDate(date: Date, showDuration = true): string {
    return showDuration
      ? moment(date).format('DD/MM/YYYY') + ' - ' + moment(date).fromNow()
      : moment(date).format('DD/MM/YYYY');
  }

  startDate(requestedStartTime): string {
    return moment(requestedStartTime).format('DD-MM-YY');
  }

  getJobDuration(): void {
    this.durationInSeconds = moment.duration(5).asMinutes();
  }
  timeFormatting = time => {
    return moment(time, 'HH:mm:ss').format('HH:mm:ss');
  };

  computeEndTime(startTime, hours, minutes): Date {
    return moment(startTime)
      .add(hours, 'hours')
      .add(minutes, 'minutes')
      .utc()
      .toDate();
  }

  getAssetIcon() {
    return getAssetTypeIcon(this.job.assetType);
  }

  getDuration(startTime: any, endTime: any): string {
    if (!endTime) {
      return '-';
    }

    var startMoment = moment(startTime);
    var endMoment = moment(endTime);

    var duration = moment.duration(endMoment.diff(startMoment));
    return (
      duration.get('hours').toString().padStart(2, '0') +
      ':' +
      duration.get('minutes').toString().padStart(2, '0') +
      ':' +
      duration.get('seconds').toString().padStart(2, '0')
    );
  }

  formatTime(time) {
    return moment(time).format('HH:mm:ss');
  }

  createExtensionForm(): FormGroup {
    return new FormGroup({
      hours: new FormControl(this.extension.hours, [Validators.required]),
      mins: new FormControl(this.extension.mins, [Validators.required]),
      reason: new FormControl(this.extension.reason, [Validators.required]),
    });
  }

  viewQRCode(): void {
    this.dialogRef = this._matDialog.open(QrcodedialogComponent, {
      data: Object.assign(this.job, { codeType: DELIVERY_NOTE_QR_LABEL }),
      width: '400px'
    });
  }

  private calculateClosedDuration(): void {
    this.closedDurationInSeconds = 0;

    const closedLogs = this.job.timeLogs.filter(x => x.endTime);

    closedLogs.forEach(log => {
      const startDate = moment(log.startTime, dateTimeFormat);
      const endDate = moment(log.endTime, dateTimeFormat);
      this.closedDurationInSeconds += endDate.diff(startDate, 'seconds');
    });
  }

  private getExistingOpenTimeLog(timeLogs: TimeLog[]): TimeLog {
    return timeLogs.find(x => !x.endTime && x.isActive);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
