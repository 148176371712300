import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from './../services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class SpinnerInterceptor implements HttpInterceptor {
  private exceptions: string[] = ['/sub-folders', '/notifications'];

  constructor(private spinnerSvc: SpinnerService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const search = new URLSearchParams(req.urlWithParams);
    if (parseInt(search.get('hideSpinner'), 10) === 1) {
      return next.handle(req);
    }

    const exceptionalRequest = this.exceptions.find(x => req.url.indexOf(x) !== -1);

    if (exceptionalRequest) {
      return next.handle(req);
    }

    this.spinnerSvc.start();
    return next.handle(req).pipe(finalize(() => this.spinnerSvc.stop()));
  }
}
